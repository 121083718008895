import {flexRender} from '@tanstack/react-table';
import {FC} from 'react';

import {StyledTD, StyledTR} from '../styles';
import {ITableBody} from '../types';

export const TableBody: FC<ITableBody> = ({table, isHorizontalScrolled, handleOnRowClick, isError, isPointer, height, minHeight}) => {
  return (
    <tbody
      style={{
        display: 'grid',
      }}>
      {table.getRowModel().rows.map((row) => {
        const cells = row.getVisibleCells();
        const lastCellIndex = cells.length - 1;
        const rowIsError = isError ? isError(row.original) : false;

        return (
          <StyledTR
            key={row.id}
            onClick={(event) => handleOnRowClick(event, row.original)}
            isError={rowIsError}
            isPointer={isPointer}
            height={height}
            minHeight={minHeight}
          >
            {row.getVisibleCells().map((cell, cellIndex) => {
              const isFirstColumn = cellIndex === 0;
              const isLastColumn = cellIndex !== lastCellIndex;
              return (
                <StyledTD
                  key={cellIndex}
                  isFirstColumn={isFirstColumn}
                  isLastColumn={isLastColumn}
                  isHorizontalScrolled={isHorizontalScrolled}
                  height={height}
                  isError={rowIsError}
                  sx={{
                    minWidth: cell.column.columnDef.minSize,
                    maxWidth: cell.column.columnDef.maxSize,
                  }}
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </StyledTD>
              );
            })}
          </StyledTR>
        );
      })}
    </tbody>
  );
};
