/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  storageApi,
  StorageFile,
  StorageFileDetails,
  StorageFileThumbnail,
  StorageThumbnailType,
} from '@api';
import {Dialog, DialogTitle, InfiniteScrollContainer, RowInformation} from '@components';
import {Box, Checkbox, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {PulseLoader} from '@src/components/PulseLoader';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {DEFAULT_PER_PAGE} from '@src/pages/Storage/constants';
import {getBase64DisplayLink} from '@src/pages/StorageDocument/helpers';
import {ReactComponent as MessageIcon} from '@src/shared/assets/icons/24x24/message.svg';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/share.svg';
import {DateFormat, dateFormatted, formatSize} from '@src/shared/utils';
import {fetchStorageFiles} from '@src/store/storage/slice';
import {useAppDispatch, useAppSelector} from '@store';
import {isEmpty} from 'lodash-es';
import {FC, useEffect, useRef, useState} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {ShareDocumentsDialogProps} from './types';

export const ShareDocumentsDialog: FC<ShareDocumentsDialogProps> = ({
  isOpen,
  onClose,
  documentId,
}) => {
  const {t} = useTranslate('storage');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {files} = useAppSelector((state) => state.storage);
  const [documentDetails, setDocumentDetails] = useState<StorageFileDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [fileThumbnails, setFileThumbnails] = useState<
  Record<string, StorageFileThumbnail['data']>
  >({});
  const [selectedIds, setSelectedIds] = useState<Array<StorageFile['id']>>([]);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const isNotOpen = !!documentId && isNotDesktop;

  const prevFilters = useRef(files.filters);

  const handleFetchThumbnails = async (fileIds: Array<StorageFile['id']>) => {
    try {
      setIsLoading(true);
      const response = await storageApi.getStorageFileThumbnails({
        fileIds,
        types: [StorageThumbnailType.SMALL],
      });

      const recordFileThumbnails = response.reduce<Record<string, StorageFileThumbnail['data']>>(
        (acc, thumbnail) => {
          acc[thumbnail.id] = thumbnail.data;
          return acc;
        },
        fileThumbnails,
      );
      setFileThumbnails(recordFileThumbnails);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const handleGetShareLink = async () => {
    try {
      const shareLink = await storageApi.shareStorageFiles(documentId ? [documentId] : selectedIds);
      return shareLink.url;
    } catch (e) {
      console.error(e);
    }
  };

  const handleCopyLink = async () => {
    if (isLinkCopied) {
      return;
    }
    const shareLink = await handleGetShareLink();
    await navigator.clipboard.writeText(shareLink || '');
    setIsLinkCopied(!!shareLink);
  };

  const handleSendEmail = async () => {
    const shareLink = await handleGetShareLink();
    const a = document.createElement('a');
    a.href = `mailto:?body=${shareLink || ''}`;
    a.click();
    a.remove();
  };

  const handleShare = async () => {
    const shareLink = await handleGetShareLink();

    try {
      await navigator.share({
        url: shareLink || '',
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFetchDocumentDetails = async () => {
    if (!documentId || documentDetails) {
      return;
    }
    try {
      const response = await storageApi.getStorageFileDetails(documentId);
      setDocumentDetails(response);
      await handleFetchThumbnails([response.id]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFetchFilesList = (isNext: boolean) => {
    if (isNext && !files.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = files.filters;
    void dispatch(
      fetchStorageFiles({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? files.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    )
      .unwrap()
      .then((newFiles) => {
        const fileIds = newFiles.data.items.map((item) => item.id);
        void handleFetchThumbnails(fileIds);
      });
  };

  const handleFilesListFirstFetchThumbnails = async () => {
    if (
      (JSON.stringify(prevFilters.current) === JSON.stringify(files.filters) &&
        !isEmpty(fileThumbnails)) ||
      documentId
    ) {
      return;
    }
    const fileIds = files.items.map((item) => item.id);
    await handleFetchThumbnails(fileIds);
  };

  const handleSelectFile = (fileId: StorageFile['id']) => {
    setIsLinkCopied(false);
    if (selectedIds.includes(fileId)) {
      const filteredIds = selectedIds.filter(id => id !== fileId);
      setSelectedIds(filteredIds);
      return;
    }
    setSelectedIds([...selectedIds, fileId]);
  };

  useEffect(() => {
    if (isOpen) {
      if (isNotOpen) {
        void handleShare();
        onClose();
      } else {
        void handleFetchDocumentDetails();
        void handleFilesListFirstFetchThumbnails();
      }
    }
    setIsLinkCopied(false);
    setSelectedIds([]);
    prevFilters.current = files.filters;
  }, [isOpen, isNotOpen]);

  const isShareButtonsDisabled = documentId ? false : !selectedIds.length;

  const renderFilesList = () => {
    return (
      <InfiniteScrollContainer
        threshold={500}
        onLoad={() => handleFetchFilesList(true)}
        sx={sx.documentsList}
      >
        {files.items.map((file) => {
          const fileThumbnail = fileThumbnails[file.id];
          return (
            <Stack key={file.id} sx={sx.filesListCard} onClick={() => handleSelectFile(file.id)}>
              <Checkbox sx={sx.checkBox} checked={selectedIds.includes(file.id)} />
              <Stack gap={12}>
                {!fileThumbnail && isLoading
                  ? (
                    <Box sx={sx.listImage}>
                      <PulseLoader loading />
                    </Box>
                  )
                  : (
                    <Box
                      sx={sx.listImage}
                      component={'img'}
                      src={
                        fileThumbnail
                          ? getBase64DisplayLink(fileThumbnail.contentType, fileThumbnail.data)
                          : ''
                      }
                      alt={file.name}
                    />
                  )}
                <EllipsisTextTooltip
                  ellipsisText={file.name}
                  variant={'14_18_500'}
                  sx={sx.fileName}
                  heightCheck
                />
              </Stack>
            </Stack>
          );
        })}
      </InfiniteScrollContainer>
    );
  };

  if (isNotOpen) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileInteractionView
        isOpen={isOpen}
        title={t('SHARE_DOCUMENTS')}
        onBack={onClose}
        onClose={onClose}
      >
        <Typography sx={sx.tipContainer} variant={'16_20_700'}>
          {t('SELECT_DOCUMENT_TO_SHARE')}
        </Typography>
        <Box sx={sx.mobileMainWrapper}>{renderFilesList()}</Box>
        <Stack sx={sx.actionButtons}>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button color={'secondary'} disabled={isShareButtonsDisabled} onClick={handleShare}>
            {t('SHARE')}
          </Button>
        </Stack>
      </MobileInteractionView>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      size={!!documentId || isSm ? 'md' : 'xl'}
      border={'unset'}
    >
      <DialogTitle onClose={onClose}>
        {documentId ? t('SHARE_DOCUMENT') : t('SHARE_DOCUMENTS')}
      </DialogTitle>
      {!documentId && (
        <Typography sx={sx.tipContainer} variant={'16_20_700'}>
          {t('SELECT_DOCUMENT_TO_SHARE')}
        </Typography>
      )}
      <Stack sx={sx.mainContainer} gap={documentId ? 48 : 36}>
        {!!documentDetails && isLoading && (
          <Box sx={sx.loaderContainer}>
            <PulseLoader loading />
          </Box>
        )}
        {!!documentDetails && !isLoading && (
          <Stack flexDirection={'row'} gap={24}>
            <Box
              sx={sx.image}
              component={'img'}
              src={getBase64DisplayLink(fileThumbnails[documentDetails.id].contentType, fileThumbnails[documentDetails.id].data)}
              alt={documentDetails.name}
            />
            <Stack overflow={'hidden'} gap={13}>
              <RowInformation
                variant={'16_20_700'}
                valueTypographyVariant={'16_20_500'}
                tooltip
                noMargin
                value={documentDetails.name}
                name={t('NAME')}
                heightCheck
                valueSx={sx.soloFileName}
              />
              <RowInformation
                variant={'16_20_700'}
                valueTypographyVariant={'16_20_500'}
                tooltip
                noMargin
                value={t(documentDetails.category as TKeys<'storage'>)}
                name={t('CATEGORY')}
              />
              <RowInformation
                variant={'16_20_700'}
                valueTypographyVariant={'16_20_500'}
                tooltip
                noMargin
                value={dateFormatted(documentDetails.date, DateFormat.DATE_SHORT)}
                name={t('DATE')}
              />
              <RowInformation
                variant={'16_20_700'}
                valueTypographyVariant={'16_20_500'}
                tooltip
                noMargin
                value={documentDetails.type}
                name={t('DOCUMENT_TYPE')}
              />
              <RowInformation
                variant={'16_20_700'}
                valueTypographyVariant={'16_20_500'}
                tooltip
                noMargin
                value={formatSize(documentDetails.size || 0)}
                name={t('DOCUMENT_SIZE')}
              />
            </Stack>
          </Stack>
        )}
        {!documentId && renderFilesList()}
        {!isNotDesktop && (
          <Stack flexDirection={'row'} gap={12}>
            <Button
              startIcon={<ShareIcon />}
              fullWidth
              sx={{backgroundColor: isLinkCopied ? (t) => t.palette.secondary.dark : ''}}
              disabled={isShareButtonsDisabled}
              color={'secondary'}
              onClick={handleCopyLink}
            >
              {isLinkCopied ? t('LINK_COPIED') : t('COPY_LINK')}
            </Button>
            <Button
              startIcon={<MessageIcon />}
              fullWidth
              disabled={isShareButtonsDisabled}
              color={'secondary'}
              onClick={handleSendEmail}
            >
              {t('SEND_EMAIL')}
            </Button>
          </Stack>
        )}
      </Stack>
      {isNotDesktop && (
        <Stack sx={sx.actionButtons}>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button color={'secondary'} disabled={isShareButtonsDisabled} onClick={handleShare}>
            {t('SHARE')}
          </Button>
        </Stack>
      )}
    </Dialog>
  );
};
