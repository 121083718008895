import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  container: {
    padding: 12,
    gap: 24,
    flexDirection: {sm: 'row'},
    alignItems: {sm: 'center'},
    background: theme => theme.colors.all.grey7,
    border: '1px solid',
    borderColor: theme => theme.colors.all.grey11,
    '--icon-fill': theme => theme.colors.all.grey10,
  },
  biomarkerSelect: {
    width: 170,
  },
  searchInputContainer: {
    position: 'relative',
    p: 0,
    height: 42,
  },
  searchInput: {
    height: 42,
    svg: {
      color: theme => theme.colors.all.grey8,
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      borderBottom: '1px solid !important',
      borderColor: theme => theme.colors.secondary400 + '!important',
    },
  },
  noResultContainer: {
    pt: 6,
    width: 240,
    color: theme => theme.colors.defaultTextColor,
  },
  optionText: {
    whiteSpace: 'wrap',
  },
  valueInput: {
    width: 126,
    '*': {
      minWidth: 'unset',
    },
    '.MuiInputBase-root:before': {
      borderRight: 'unset',
    },
  },
  unitOption: {
    width: {sm: 110, xs: 126},
  },
  unitSelect: {
    width: {sm: 110, xs: 126},
    maxWidth: {sm: 110, xs: 126},
  },
  wrapperForm: {
    flexDirection: 'row',
    width: {
      xs: 'calc(100% - 54px)',
      sm: 'auto',
    }
  }
};
