import {TableHeaderItem} from '@src/components/Table/types';
import {useMQuery} from '@src/shared/hooks';
import {useTranslate} from 'i18n/useTranslate';

export const useHeader = (filterResultByDate: (key: string) => () => void, reportedPosition?: boolean, collectedPosition?: boolean) => {
  const {t} = useTranslate('results');
  const {mobile} = useMQuery();
  const HEADER_LIST: TableHeaderItem[] = [
    {
      name: t('REPORTED'),
      action: {action: filterResultByDate('reported'), position: 'end', sorted: reportedPosition},
    },
    ...(mobile
      ? [
        {
          name: t('PHYSICIAN'),
        },
      ] as TableHeaderItem[]
      : [
        {
          name: t('COLLECTED'),
          action: {action: filterResultByDate('collected'), position: 'end', sorted: collectedPosition},
        },
        {
          name: t('PHYSICIAN'),
        },
        {
          name: t('FLAG'),
        },
      ] as TableHeaderItem[]),
  ];
  return {HEADER_LIST};
};

export enum EFlagColor {
  'CRITICAL' = '#EA3D5C',
  'NORMAL' = '#05B7AC',
  'ABNORMAL' = '#FAB41C',
}
export enum EBackgroundFlagColor {
  'CRITICAL' = '#FDECEF',
  'NORMAL' = '#E6F8F7',
  'ABNORMAL' = '#FEF7E8',
}
export enum EFlagName {
  'CRITICAL' = 'Critical',
  'NORMAL' = 'Normal',
  'ABNORMAL' = 'Abnormal',
}

export interface IFilterResults {
  collectionDateFrom: Date | null
  collectionDateTo: Date | null
  physician: {value: string, name: string} | null
  reportDateFrom: Date | null
  reportDateTo: Date | null
}

export const initialFilter: IFilterResults = {
  collectionDateFrom: null,
  collectionDateTo: null,
  physician: null,
  reportDateFrom: null,
  reportDateTo: null,
};

interface ISortResult {
  sortBy: string
  sortDesc: boolean
}

export const initiialSort: ISortResult = {
  sortBy: '',
  sortDesc: true,
};
