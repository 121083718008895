import {Stack, Box, Typography, Divider} from '@mui/material';
import {State} from '@src/api';
import {RelationProfile} from '@src/api/relations';
import {GoogleAutocomplete} from '@src/components/Google/Autocomplete/GoogleAutocomplete';
import {useTranslate} from '@src/i18n/useTranslate';
import {MAX_ADDRESS_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {useAddressForm, useContactRelations, useRelation} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Nullable} from '@src/types/NullableModel';
import {Formik} from 'formik';
import {isEqual} from 'lodash-es';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useStreetNumber} from './useStreetNumber';
import {Button, InputControl} from 'ui-kit';

import {EAddressComponentsTypes, getAddressComponentByType} from '../../../helpers/functionHelpers';

import {addressOnClear} from './helpers';
import {useValidationSchema} from './hooks';
import {StyledForm, sx} from './styles';
import {SaveDataContacts} from './types';

export const RelationContactsEdit = ({handleCancelClick}: { handleCancelClick?: VoidFunction }) => {
  const {t, ready} = useTranslate('connections');
  const dispatch = useDispatch();
  const {id} = useParams();
  const {edit} = useAddressForm();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const validationSchema = useValidationSchema();

  const saveData = ({
    state,
    city,
    postalCode,
    address1,
    address2,
    location,
  }: Nullable<SaveDataContacts>) => {
    const data = {
      state: state as State || null,
      city: city || '',
      postalCode: postalCode || '',
      address1: address1 || '',
      address2: address2 || '',
      location: {
        googlePlaceId: location?.googlePlaceId,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    };

    edit({relationId, data});
    if (relation && handleCancelClick) {
      const newRelation: RelationProfile = {
        ...relation,
        actualAddresses: {
          ...relation.actualAddresses,
          state,
          city,
          postalCode,
          main: address1,
          additional: address2,
        },
      };
      dispatch(relationsActions.setRelation({relation: newRelation}));
      handleCancelClick();
    }
  };

  const initialValue = {
    state: relation?.actualAddresses?.state || '',
    city: relation?.actualAddresses?.city || '',
    postalCode: relation?.actualAddresses?.postalCode || '',
    address1: relation?.actualAddresses?.main || '',
    address2: relation?.actualAddresses?.additional || '',
    location: {
      googlePlaceId: relation?.actualAddresses?.id || '',
      latitude: 0,
      longitude: 0,
    },
    streetNumber: '',
  };

  if (!ready) return null;

  return (
    <Stack sx={sx.container}>
      <Formik<typeof initialValue>
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={(values) => {
          if (!isEqual(initialValue, values)) {
            const normalizedValues = {
              ...values,
              address1: normalizeString(values.address1),
              address2: normalizeString(values.address2),
              city: normalizeString(values.city),
              state: values.state as State || '',
            };

            saveData(normalizedValues);
          }
        }}
        initialValues={initialValue}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          setValues,
        }) => {
          const addressChangeHandler: ((placeResult: google.maps.places.PlaceResult) => void) = (placeResult) => {
            const addressComponents = placeResult.address_components || [];

            const streetNumber = getAddressComponentByType([EAddressComponentsTypes.STREET_NUMBER], addressComponents);
            const locality = getAddressComponentByType([EAddressComponentsTypes.CITY], addressComponents);
            const sublocality = getAddressComponentByType([EAddressComponentsTypes.SUBLOCALITY], addressComponents);
            const neighborhood = getAddressComponentByType([EAddressComponentsTypes.NEIGHBORHOOD], addressComponents);
            const state = getAddressComponentByType([EAddressComponentsTypes.STATE], addressComponents, true) || '';
            let currentCity = locality || sublocality || neighborhood;

            const administrativeAreas = ['administrative_area_level_1', 'administrative_area_level_2'];
            const hasAdministrativeArea = addressComponents.some((component) =>
              administrativeAreas.some((type) => component.types.includes(type)),
            );

            if (!locality && hasAdministrativeArea) {
              currentCity = state;
            } else if (!currentCity) {
              currentCity = state;
            }
            setValues({
              ...values,
              address1: placeResult.formatted_address || placeResult.name || '',
              state: getAddressComponentByType([EAddressComponentsTypes.STATE], placeResult.address_components, true) || '',
              postalCode: getAddressComponentByType([EAddressComponentsTypes.POSTAL_CODE], placeResult.address_components),
              city: currentCity,
              location: {
                googlePlaceId: placeResult.place_id || '',
                latitude: placeResult.geometry?.location?.lat() || 0,
                longitude: placeResult.geometry?.location?.lng() || 0,
              },
              streetNumber: streetNumber,
            });
          };

          const showAddressError = () => {
            if (!values.address1.length) return errors.address1;
            if (values.streetNumber !== null) return errors.streetNumber;
            return undefined;
          };

          const streetNumbers = useStreetNumber(values.address1) || '';

          useEffect(() => {
            if (streetNumbers !== '') {
              setValues({
                ...values,
                streetNumber: streetNumbers,
              });
            }
          }, [streetNumbers]);

          return (
            <StyledForm>
              <Stack sx={sx.content}>
                {!isDesktop && <Divider sx={sx.divider} />}
                <Typography
                  component='h4'
                  sx={sx.title}>
                  {t('ADDRESS')}
                </Typography>
                <GoogleAutocomplete
                  placeholder={t('ENTER_PATIENT_ADDRESS')}
                  isRequiredField
                  value={values.address1}
                  label={t('ADDRESS')}
                  onChange={addressChangeHandler}
                  onClear={(e) => addressOnClear(e, setFieldValue)}
                  error={showAddressError()}
                />
                <InputControl
                  sx={sx.inputControl}
                  maxlength={MAX_ADDRESS_LENGTH}
                  disabled={!values.address1}
                  name="address2"
                  label={t('APARTMENT_SUIT_OR_UNIT')}
                  value={values.address2 || ''}
                  error={touched.address2 && errors.address2 ? errors.address2 : ''}
                  onChange={handleChange}
                />
              </Stack>
              <Box sx={sx.buttonsContainer}>
                <Button
                  fullWidth={isMobile}
                  variant="outlined"
                  onClick={handleCancelClick}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  fullWidth={isMobile}
                  disabled={isEqual(initialValue, values) || values.address1.length === 0}
                  type="submit"
                  variant="contained"
                >
                  {t('SAVE')}
                </Button>
              </Box>
            </StyledForm>
          );
        }}
      </Formik>
    </Stack>
  );
};
