import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary._14,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
      p: 0,
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
      backgroundColor: t.palette.common.white,
      height: 'calc(100vh - 224px)',
    },
  }),
  text: (t) => ({
    typography: '14_18_600',
    color: t.palette.grey[400],
  }),
  link: {
    typography: '14_18_600',
  },
  wBoxContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    p: 18,
  },
  requestsContainer: {
    p: 18,
    gap: 24,
    flexDirection: 'row',
  },
  requestsContainerEmpty: (t) => ({
    p: 18,
    gap: 24,
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderColor: t.palette.secondary._14,
  }),
};
