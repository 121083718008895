import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  newStackWrapper: {
    color: 'grey.400',
    flexGrow: 1,
    flexDirection: 'row',
    padding: {
      xs: '0',
      sm: '0 24px 24px',
      md: '0 14px 14px',
      xl: '0 24px 24px',
    },
    alignItems: 'center',
    gap: {
      xs: '8px',
      sm: '4px'
    }
  },
  stackWrapper: {
    flexDirection: 'row',
    color: 'grey.400',
    padding: {
      xs: '18px 24px 8px',
      sm: '42px 0'
    },
    justifyContent: 'center',
    alignItems: 'center',
    gap: {
      xs: '8px',
      sm: '4px'
    }
  }
};
