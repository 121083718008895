import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: (t) => ({
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: 48,
    mt: {lg: 4},
    mb: {lg: 4},
    [t.breakpoints.only('xs')]: {
      p: 18,
    },
    [t.breakpoints.between('sm', 'lg')]: {
      p: 24,
    },
  }),
  primaryInsuranceContainer: {
    gap: {sm: 24, lg: 36},
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 12,
  },
  button: (t) => ({
    [t.breakpoints.down('sm')]: {
      flex: 1,
    },
  }),
  contacts: {
    mt: {xs: 24, lg: 12},
  },
  content: {
    gap: {xs: 24, sm: 36},
  },
  insuranceWrapper: {
    gap: 24,
  },
  guarantorWrapper: {
    gap: 18,
  },
  guarantorContentWrapper: (t) => ({
    flexDirection: {lg: 'row', sm: 'row', md: 'row', xs: 'column'},
    gap: 36,
    [t.breakpoints.down('sm')]: {
      gap: 12,
    },
  }),
  guarantorListContent: {
    flexGrow: 1,
    maxWidth: {lg: 335, xl: 404},
  },
  textColor: (t) => ({
    color: t.palette.grey[400],
    fontWeight: 500,
  }),
  optionalText: (t: Theme) => ({
    typography: {
      lg: '12_16_500',
    },
    fontStyle: 'italic',
    pt: {lg: 3},
    color: t.palette.grey[500],
    [t.breakpoints.down('lg')]: {
      mt: 1,
    },
  }),
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    mb: {sm: 24},
    mt: {lg: 12},
  },
  editButton: {
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },
  primatyInsuranceContainer: {
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
    mt: {xs: 24, lg: 12},
  },
  listItemText: {
    typography: '14_18_500',
  },
  guarantorContainer: {
    gap: {xs: 24},
  },
  guarantorTitleContainer: {
    display: 'flex',
    gap: 24,
  },
  flexContainer: {
    flexGrow: 1,
    gap: {xs: 12},
  },
  contentContainer: {
    gap: {xs: 24, lg: 36},
  },
  title: {
    mt: {xs: 12, sm: 24, lg: 12},
  },
  contactsContainer: (t) => ({
    gap: {xs: 16, sm: 12},
    [t.breakpoints.between('sm', 'lg')]: {
      mb: 24,
    },
  }),
  addressesContainer: {
    gap: {xs: 21, lg: 24},
  },
  breadCrumbsMobile: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  radioBth: (t) => ({
    [t.breakpoints.only('xs')]: {
      height: '100%',
    },
  }),
  plan: (t) => ({
    '& span:not(label span)': {
      color: t.colors.all.errorLegacy,
    },
  }),
  errorText: {
    whiteSpace: 'break-spaces',
  },
};

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: 1,
  '.tablet-layout &': {
    gap: 36,
  },
  '.mobile-layout &': {
    gap: 48,
  },
}));
