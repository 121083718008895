import {Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    [t.breakpoints.down('lg')]: {
      gap: 24,
    },
    flexGrow: 1,
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    [t.breakpoints.between('sm', 'md')]: {
      padding: 18,
      gap: 24,
      border: 'unset',
    },
    [t.breakpoints.down('sm')]: {
      gap: 24,
      border: 'unset',
      paddingBottom: '110px',
    },
  }),
  topContainer: (t: Theme) => ({
    flexDirection: {
      xl: 'row',
      md: 'row',
    },
    justifyContent: 'space-between',
    gap: 'initial',
    [t.breakpoints.down('lg')]: {
      padding: '18px 18px 0',
    },
    [t.breakpoints.down('md')]: {
      gap: 24,
      padding: 0,
    },
  }),
  buttonsContainer: (t: Theme) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: t.colors.background,
    gap: 12,
    display: 'grid',
  }),
  wBoxContainer: {
    flexGrow: 1,
    mt: {
      xs: 24,
    },
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxPatientDetails: {
    flexGrow: 1,
    padding: {
      xs: '6px 18px',
      lg: 0,
    },
  },
  gridWrapper: {
    padding: '0px',
  },
  boxPaddings: {
    padding: {xs: '18px 16px', md: '24px'},
  },
  resetPaddingBox: {
    padding: '0',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerSx: {
    mb: {xs: 12, lg: 36},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
  }),
};
