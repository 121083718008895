import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialog: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitle: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  bthOk: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
  dialogActions: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    p: '16px 24px',
    justifyContent: 'flex-end !important',
    alignItems: 'end',
  }),
  dialogContent: {
    p: '36px 24px !important',
  },
  okBtn: {
    color: theme => theme.colors.all.grey2,
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
  mainDialogContent: {
    p: '36px 24px 12px 24px !important',
  },
  btnContainer: {
    flexDirection: 'row !important',
    justifyContent: 'space-between !important',
  },
  btn: {
    maxWidth: '102px !important',
  },
};
