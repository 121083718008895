import {Stack, Typography, Avatar, Box} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {EmptyFragment} from '@src/components/EmptyFragment';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {useColumnsNumber, useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getUserInitials} from '@src/shared/utils';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useParams} from 'react-router-dom';

import {groupRelationsByType} from '../../helpers/functionHelpers';

import {getBreakpoints, getCustomColumns, getDefaultColumns} from './helpers';
import {sx} from './styles';

export const RelationRelations = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);
  const {desktop: isDesktop, mobile: isMobile} = useMQuery();

  const isAccesses = relation?.accesses?.length;
  const relations = groupRelationsByType(relation?.relations);

  const {isSidebarOpen} = useSidebarClassObserver();

  const breakpoints = getBreakpoints(isSidebarOpen);
  const defaultColumns = getDefaultColumns(isSidebarOpen);
  const customColumns = getCustomColumns(isSidebarOpen);

  const {colsNumberXl, colsNumberLg, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  if (!relations.length && !isAccesses) {
    return (
      <Stack sx={sx.mainContainer}>
        {fetching && (
          <Stack p={isMobile ? 0 : 24}>
            <Typography sx={sx.title}>{t('PATIENT_RELATIONS')}</Typography>
            <PulseLoader loading />
          </Stack>
        )}
        {!fetching && (
          <EmptyFragment
            title={t('PATIENT_RELATIONS')}
            description={t('NO_RELATIONSHIPS')}
            isButtonIcon={false}
            noPaddings={!isDesktop}
            descriptionStyles={sx.emptyFragment}
            hasDescriptionIcon
            icon={false}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack sx={sx.mainContainer}>
      <Stack sx={sx.container}>
        {isAccesses
          ? (
            <Box ref={containerRef}>
              <Typography component="h4" sx={sx.title}>
                {t('PATIENT_RELATIONS')}
              </Typography>
              <Grid container columnSpacing={24} spacing={isMobile ? 12 : 24}>
                {relations?.map((person) => (
                  <Grid
                    xs={12}
                    sm={6}
                    lg={colsNumberLg}
                    xl={colsNumberXl}
                    xxl={3}
                    key={person?.id}
                  >
                    <Stack sx={sx.contentContainer}>
                      <Box sx={sx.content}>
                        <Avatar sx={sx.avatar} src={person?.avatarUrl}>
                          <Typography sx={sx.avatarTypography}>
                            {getUserInitials(person?.contact)}
                          </Typography>
                        </Avatar>
                        <Stack sx={sx.widthLimitContainer}>
                          <EllipsisTextTooltip
                            ellipsisText={person?.contact.firstName}
                            sx={sx.personName}
                          />
                          <EllipsisTextTooltip
                            ellipsisText={person?.contact.lastName}
                            sx={sx.personName}
                          />
                          <EllipsisTextTooltip
                            ellipsisText={person?.contact?.birthDate ?? ''}
                            sx={sx.personBirthDay}
                          />
                          <EllipsisTextTooltip
                            ellipsisText={person?.types?.map((type) => t(type as TKeys<'connections'>)).join(', ') ?? ''}
                            sx={sx.personType}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )
          : (
            <>
              <EmptyFragment
                title={t('PATIENT_RELATIONS')}
                description={t('NO_RELATIONSHIPS')}
                isButtonIcon={false}
                noPaddings
                descriptionStyles={sx.emptyFragment}
                hasDescriptionIcon
                icon={false}
              />
            </>
          )}
      </Stack>
    </Stack>
  );
};
