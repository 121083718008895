import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: {
    fontSize: '14px',
    backgroundColor: '#fff',
    flexGrow: 1,
  },
  header: {
    display: 'grid',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      svg: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    borderBottom: '1px solid #dff2f5',
    columnGap: 24,
    padding: '16px 36px',
    fontWeight: 700,
    letterSpacing: 0,
    color: '#4b5563',
    wordBreak: 'break-word',
    paddingTop: 0,
    paddingBottom: 0,
    height: 54,
  },
  body: {
    overflowY: 'auto',
  },
};
