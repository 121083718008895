import {AutocompleteInputView} from '@src/components/Google/Autocomplete/AutocompleteInputView';
import {useGoogleAutocomplete} from '@src/components/Google/Autocomplete/useGoogleAutocomplete';
import {FC, useCallback, useEffect, useState} from 'react';

export const GoogleAutocomplete: FC<{
  onChange?: (
    placeResult: google.maps.places.PlaceResult,
    autocomplete: google.maps.places.Autocomplete
  ) => void
  placeholder?: string
  value?: string
  label?: string
  error?: string
  onClear?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isRequiredField?: boolean
}> = ({onChange, placeholder, error, value, label, onClear, isRequiredField}) => {
  const {ref, autocomplete, options, getPlacePredictions} = useGoogleAutocomplete({onChange});
  const [inputValue, setInputValue] = useState(value || '');
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [debouncedError, setDebouncedError] = useState<string | undefined>(undefined);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOptionSelected(false);

    if (!newValue) {
      if (onClear) {
        onClear(e);
      }
    } else {
      getPlacePredictions(newValue);
    }

    if (onChange && autocomplete) {
      onChange(
        {address_components: [], formatted_address: newValue} as google.maps.places.PlaceResult,
        autocomplete,
      );
    }
  };

  const handleBlur = () => {
    if (inputValue && options.length > 0) {
      setIsOptionSelected(true);
    }
  };

  const onFocusHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'hidden';
      main.style.overflowY = 'hidden';
    }
  }, []);

  const onBlurHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'auto';
      main.style.overflowY = 'auto';
    }
  }, []);

  useEffect(() => {
    const inputEl = ref.current;
    if (inputEl) {
      inputEl.addEventListener('focus', onFocusHandler);
      inputEl.addEventListener('blur', onBlurHandler);
    }

    return () => {
      if (inputEl) {
        inputEl.removeEventListener('focus', onFocusHandler);
        inputEl.removeEventListener('blur', onBlurHandler);
      }
    };
  }, [ref, onFocusHandler, onBlurHandler]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const getError = () => {
    if (!inputValue.trim() && isRequiredField) {
      return error;
    }

    if (!isOptionSelected && options.length === 0) {
      return error;
    }

    if (isOptionSelected && error) {
      return error;
    }

    return undefined;
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const errorMessage = getError();

      setDebouncedError(errorMessage);
    }, 150);

    return () => clearTimeout(debounceTimeout);
  }, [inputValue, options, error, isOptionSelected]);

  return (
    <AutocompleteInputView
      value={inputValue}
      label={label}
      ref={ref}
      error={debouncedError}
      placeholder={placeholder}
      onChange={handleInputChange}
      onBlur={handleBlur}
    />
  );
};
