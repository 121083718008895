import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';

export const stylesSx: Partial<Record<string, SxProps<Theme>>> = {
  boxPaddings: {
    padding: '24px 24px 24px 81px',
  },
  checkboxPosition: {
    position: 'absolute',
    top: -9,
    left: -37,
  },
  boxContainer: {
    ml: {
      xs: 0,
      md: 33,
      lg: 0,
    },
  },
  dlPosition: {
    position: 'relative',
  },
};
