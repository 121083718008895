import {RelationProfile} from '@src/api/relations';
import {useTranslate} from '@src/i18n/useTranslate';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {customRequiredValidation, postalCodeValidator, ssnTailValidator} from '@src/shared/utils/validators';
import {differenceInYears} from 'date-fns';
import {parsePhoneNumber} from 'react-phone-number-input';
import * as Yup from 'yup';

export const useValidationSchema = ({relation}: { relation: RelationProfile | null }) => {
  const {t} = useTranslate('connections');

  return Yup.object().shape({
    firstName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    lastName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    birthDate: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test('is-birthDate', t('ENTER_A_VALID_DATE_OF_BIRTH'), function(value) {
        if (!value) return true;
        const birthDate = new Date(value);
        return differenceInYears(new Date(), birthDate) >= 18;
      }),
    birthSex: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    state: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    city: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    address1: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    endDate: Yup.string().nullable()
      .test('is-insurancePlan', t('DATE_MUST_BE_GREATER_THAN_TODAY'), function (value) {
        if (value) {
          return !!compareDatesWithToday(value, false, true);
        } else return true;
      }),
    phone: Yup
    .string()
    .nullable()
    .min(10, t('ENTER_A_10_DIGIT'))
    .test('validate-phone', t('PLEASE_ENTER_A_VALID_PHONE'), (value) => {
        if (!value) {
          return true;
        }
        const phoneNumber = parsePhoneNumber(value ?? '', 'US');
        return phoneNumber?.isValid() ?? false;
    })
    .test('phone-not-duplicate', t('ENTER_A_PHONE_NUMBER'), (value) => {
      const normalizedValue = value ? value.replace(/^\+/, '') : '';

      return !relation?.phones?.find((phoneObj) => {
        const phone = phoneObj?.phone;
        if (phone) {
          const normalizedPhone = phone.replace(/^\+/, '');
          return normalizedPhone === normalizedValue;
        }
        return false;
      });
    }),
    postalCode: postalCodeValidator(t),
    ssnTail: ssnTailValidator(t),
    insuranceCarrier: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD')),
    insurancePlan: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test('is-insurancePlan', t('ENTER_INSURANCE_PLAN'), function (value) {
        return !relation?.healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            } else {
              return insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            }
          },
        );
      }),
    policyId: Yup.string()
      .required(t('REQUIRED_FIELD'))
      .nullable()
      .test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t))
      .test('is-policyId', t('ENTER_POLICY_ID'), function (value) {
        return !relation?.healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            } else {
              return insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            }
          },
        );
      }),
  });
};
