import {
  Box,
  Typography,
  FormHelperText,
  FormControl,
  TextField,
  Autocomplete,
  SxProps,
  Theme, InputAdornment,
} from '@mui/material';
import {MobileSelect} from '@src/components/Select/MobileSelect/MobileSelect';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/search-icon.svg';
import {useMQuery} from '@src/shared/hooks';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {alpha} from '@src/theme/utils';
import {ChangeEvent, SetStateAction, Dispatch, useState} from 'react';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  select: {
    '.MuiOutlinedInput-root': {
      padding: '0 0 0 12px',
      paddingRight: '0px !important',
      '.MuiAutocomplete-endAdornment': {
        position: 'absolute',
      },
    },
    '.MuiAutocomplete-input': {
      padding: '11px 5px 11px 0px !important',
      minWidth: '0 !important',
    },
  },
  formControl: {
    m: 1,
    '.MuiButtonBase-root': {
      pointerEvents: 'none',
    },
    '.MuiSvgIcon-root': (t) => ({
      marginRight: 2,
      path: {
        d: 'path("M18 11L12 17L6 11")',
        stroke: t.palette.grey[400],
        fill: 'none',
      },
    }),
  },
  listItem: (t) => ({
    'ul li': {
      typography: '14_18_500',
      padding: '6px 16px !important',
      color: `${t.palette.grey[700]} !important`,
      backgroundColor: 'transparent !important',
      '&.MuiAutocomplete-option:hover': {
        backgroundColor: `${alpha(t.palette.secondary.main, 7)} !important`,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: `${t.palette.grey[100]} !important`,
        '&:hover': {
          backgroundColor: `${t.palette.grey[100]} !important`,
        },
      },
    },
  }),
  searchInput: (t) => ({
    '.MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      borderBottom: `1px solid ${t.colors.secondary400} !important`,
    },
    '.MuiInputAdornment-root': {
      mr: 6,
    },
    '.MuiInputBase-root': {
      pl: 12,
    },
    '.MuiInputBase-input': {
      typography: '14_18_500',
      p: '11px 12px 11px 0',
      '&::placeholder': {
        color: t => t.palette.grey[500],
        opacity: 1,
      },
    },
  }),
  optionalText: (t) => ({
    typography: '12_16_500',
    fontStyle: 'italic',
    color: t.palette.grey[500],
  }),
};

export const SearchSelect = ({
  memoOptions,
  setSelectedCarrier,
  searchText,
  debouncedChangeHandler,
  onSearchChange,
  value,
  disabled,
  variant,
  error,
  label,
  isOptional,
  placeholder,
  isLabelTop,
  clearOptions,
}: {
  label?: string
  isOptional?: boolean
  memoOptions?: string[]
  setSelectedCarrier: ((value: string) => void) | Dispatch<SetStateAction<string>>
  searchText: string
  onSearchChange?: (value: string) => void
  value?: string
  debouncedChangeHandler: (value: string) => void
  disabled?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  error?: string
  placeholder?: string
  isLabelTop?: boolean
  clearOptions?: VoidFunction
}) => {
  const {t} = useTranslate('common');
  const {mobile: isMobile} = useMQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(searchText);
  const [prevValue, setPrevValue] = useState(value);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setPrevValue(inputValue);
    setIsOpen(true);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchChange?.(e.target.value);
    debouncedChangeHandler(e.target.value);
    setInputValue(e.target.value);
  };

  if (isMobile && isOpen) {
    return (
      <MobileSelect
        clearOptions={clearOptions}
        prevValue={prevValue || ''}
        setInputValue={setInputValue}
        value={value || ''}
        onChangeMobile={({value}) => setSelectedCarrier(value)}
        options={memoOptions?.map((option) => ({value: option, name: option})) || []}
        label={label}
        onBack={handleClose}
        onClose={handleClose}
        searchInput={
          <TextField
            InputProps={{
              startAdornment: <InputAdornment
                sx={(t) => ({
                  '& svg path': {
                    fill: t.palette.grey[500],
                  },
                })}
                position="start">
                <SearchIcon />
              </InputAdornment>,
            }}
            fullWidth
            size={'small'}
            placeholder={t('SEARCH')}
            value={inputValue}
            onChange={handleSearchChange}
            sx={sx.searchInput}
          />
        }
      />
    );
  }

  return (
    <FormControl
      fullWidth
      sx={[
        (t) => ({
          m: 1,
          pointerEvents: disabled ? 'none' : 'unset',
          backgroundColor: disabled ? `${t.palette.grey[200]}` : 'initial',
        }),
        ...(Array.isArray(sx.formControl) ? sx.formControl : [sx.formControl]),
      ]}
      variant={variant}
    >
      {(isLabelTop) && (
        <Box display='flex' alignItems='center' gap={6}>
          <Typography mb={2} variant='14_18_700'>{label}</Typography>
          {isOptional && <Typography sx={sx.optionalText}>{`(${t('OPTIONAL')})`.toLowerCase()}</Typography>}
        </Box>
      )}
      <Autocomplete
        componentsProps={{
          paper: {
            sx: sx.listItem,
          },
        }}
        freeSolo
        forcePopupIcon
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        autoHighlight
        disableClearable
        sx={[
          ...spreadSx(sx.select),
          (t) => ({
            '.MuiOutlinedInput-root': {
              fieldset: {
                border: error ? '2px solid' : '1px solid',
                borderColor: error ? t.colors.all.errorLegacy : `${t.palette.grey[400]}`,
              },
              '&:hover fieldset': {
                border: error ? '2px solid' : '1px solid',
                borderColor: error ? t.colors.all.errorLegacy : `${t.palette.grey[500]}`,
              },
              '&.Mui-focused fieldset': {
                border: '2px solid',
                borderColor: error ? t.colors.all.errorLegacy : `${t.palette.grey[600]}`,
              },
            },
          }),
        ]}
        onSelect={({target}: ChangeEvent<any>) => setSelectedCarrier(target?.value)}
        value={inputValue}
        options={memoOptions || []}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || t('SEARCH')}
            color="secondary"
            value={searchText}
            onChange={(e) => debouncedChangeHandler(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Typography
                  variant="14_20_700"
                  sx={(t) => ({
                    whiteSpace: 'nowrap',
                    color: t.colors.all.grey1,
                  })}
                >
                  {!isLabelTop && label}
                </Typography>
              ),
            }}
          />
        )}
      />
      <FormHelperText
        id="component-error-text"
        sx={(t) => ({
          color: t.colors.all.errorLegacy,
          fontWeight: 500,
          fontSize: 12,
          marginLeft: 0,
          lineHeight: '18px',
          position: 'absolute',
          bottom: '-21px',
        })}
      >
        {error}
      </FormHelperText>
    </FormControl>
  );
};
