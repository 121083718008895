import {Stack, Box} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {isShowButtonEdit} from '../helpers';

import {RelationAboutWithAccess} from './RelationAboutWithAccess/RelationAboutWithAccess';
import {RelationAboutWithoutAccess} from './RelationAboutWithoutAccess/RelationAboutWithoutAccess';
import {sx} from './styles';

export const RelationAbout = () => {
  const {t, ready} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const navigate = useNavigate();
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);

  const isAccesses = relation?.accesses?.length;

  const handleClickEditButton = () => {
    if (id) {
      navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT, {id}));
    }
  };

  if (!ready) return null;

  return (
    <Stack sx={sx.container}>
      {isAccesses
        ? <RelationAboutWithAccess relation={relation} fetching={fetching}/>
        : <RelationAboutWithoutAccess relation={relation} fetching={fetching} />
      }
      {
        isShowButtonEdit({relation}) && (
          <Box sx={sx.editBthContainer}>
            <Button
              fullWidth={isMobile}
              sx={sx.buttonEdit}
              variant='outlined'
              color='primary'
              onClick={handleClickEditButton}
            >
              {t('EDIT')}
            </Button>
          </Box>
        )}
    </Stack>
  );
};
