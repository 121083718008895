import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  title: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
    },
  },
  text: {
    typography: {
      xs: '12_16_500',
      sm: '14_22_500',
    },
    mb: {
      xs: 18,
      sm: 36,
    },
  },
  mainBlock: {
    order: 1,
    padding: {
      sm: 24,
      xs: 18,
    },
    gap: 5,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calculatorConatiner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  controls: {
    flex: 1,
    marginRight: {
      md: 48,
      sm: 0,
    },
  },
  slider: {
    marginBottom: 14,
    '& .MuiSlider-track': {
      height: 6,
    },
  },
  sliderDisabled: {
    marginBottom: 14,
    '& .MuiSlider-track': {
      height: 6,
      backgroundColor: (t: Theme) => t.palette.grey[300],
      border: '1px solid transparent',
      cursor: 'auto'
    },
    '& .MuiSlider-thumb': {
      backgroundColor: (t: Theme) => t.palette.grey[300],
      boxShadow: 'none',
      ':hover,:focus-visible': {
        backgroundColor: (t: Theme) => t.palette.grey[300],
        boxShadow: 'none',
      },
      ':active': {
        backgroundColor: (t: Theme) => t.palette.grey[300],
      },
    },
    '& .MuiSlider-rail': {
      cursor: 'auto'
    },
  },
  sliderAge: {
    '& .MuiSlider-thumb': {
      boxShadow: 'none',
      ':hover,:focus-visible': {
        backgroundColor: (theme) => theme.palette.grey[300],
        boxShadow: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      left: 30,
      top: 35,
      width: '130px',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      },
    '& .MuiSlider-valueLabel:before': {
      left: 0,
      top: 20,
    }
  },
  sexPicker: {
    mb: {
      sm: 29,
      xs: 25,
    },
    width: 'fit-content',
  },
  imageBox: {
    width: 210,
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    marginTop: -15,
  },
  buttonBox: {
    marginTop: {
      xs: 42,
    },
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  tooltip: {
    width: {
      xs: 290,
      sm: 260,
      md: 270,
      lg: 270,
      xl: 270,
    },
  },
  successButton: {
    backgroundColor: (t) => t.palette.common.white,
  },
};
