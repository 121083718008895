import {InfiniteScrollContainer} from '@components';
import {Box, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
  fixedButtonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: theme => theme.colors.background,
  },
  generalContainer: {
    flex: 1,
    border: '1px solid',
    borderTop: 'unset',
    borderColor: theme => theme.colors.borderBlueLight,
  },
  dialog: (t) => ({
    '.MuiPaper-root': {
      borderColor: t.palette.error.main,
    },
  }),
  dialogTitle: (t) => ({
    borderBottomColor: t.palette.error.main,
    gap: 12,
  }),
  btnOk: (t) => ({
    backgroundColor: t.palette.common.white,
  }),
  dialogActions: (t) => ({
    backgroundColor: t.palette.error.main,
    margin: 0,
    p: '16px 24px',
    justifyContent: 'flex-end !important',
    alignItems: 'end',
  }),
  dialogContent: {
    p: '36px 24px !important',
  },
};

export const MainWrapper = styled(InfiniteScrollContainer)<{ isSidebarOpen: boolean, isScrolled: boolean}>(({theme, isSidebarOpen, isScrolled}) => ({
  background: theme.colors.background,
  // TODO: Need to change the layout of parent components and get rid of calc(...) everywhere
  [theme.breakpoints.up('xs')]: {
    padding: 18,
    height: isScrolled ? `calc(100vh - 78px - 61px - ${theme.header.height.sm}px)` : `calc(100vh - 145px - 48px - 42px - 24px - 48px - ${theme.header.height.sm}px)`,
    marginTop: isScrolled && -6,
  },
  [theme.breakpoints.up('sm')]: {
    padding: 24,
    height: `calc(100vh - 91px - 42px - 24px - 48px - ${theme.header.height.md}px)`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: '24px 36px 36px 36px',
    height: `calc(100vh - 91px - 42px - 36px - 73px - ${theme.header.height.lg}px)`,
  },
  [theme.breakpoints.up('xl')]: {
    padding: '24px 36px 36px 36px',
  },
}));

export const MainContainer = styled(Box)<{ isSidebarOpen: boolean }>(({theme, isSidebarOpen}) => ({
  display: 'grid',
  gap: 18,
  [theme.breakpoints.only('xs')]: {
    ':last-child': {
      marginBottom: 18,
    },
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: isSidebarOpen ? '1fr' : '1fr 1fr',
    gap: 24,
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: isSidebarOpen ? '1fr 1fr' : '1fr 1fr 1fr',
  },
  [theme.breakpoints.up('xxl')]: {
    gridTemplateColumns: isSidebarOpen ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
  },
}));
