import {Theme, SxProps} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    minHeight: '100%',
    borderRight: '1px solid',
    borderColor: t.palette.secondary._14,
    backgroundColor: t.palette.common.white,
    [t.breakpoints.only('xs')]: {
      border: '1px solid',
      borderColor: t.palette.secondary._14,
      backgroundColor: t.palette.common.white,
      margin: 0,
      width: '100%',
      mb: 24,
    },
  }),
  requestAvatar: (t) => ({
    width: 40,
    height: 40,
    marginRight: 16,
    backgroundColor: t.palette.secondary._21,
    color: t.palette.common.white,
  }),
  personType: (t) => ({
    color: 'grey.400',
    minHeight: 20,
    maxWidth: 232,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [t.breakpoints.only('xs')]: {
      typography: '12_18_500',
    },
  }),
  personName: (t) => ({
    maxWidth: 180,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    pr: 15,
    [t.breakpoints.only('xs')]: {
      typography: '14_20_700',
    },
  }),
  upperBlockWrapper: (t) => ({
    flexDirection: {
      xs: 'column',
      sm: 'initial',
    },
    [t.breakpoints.between('sm', 'lg')]: {
      alignItems: 'center',
    },
    justifyContent: 'space-between',
    p: {xs: 18, sm: '15px 18px', lg: 24},
    gap: 18,
    borderBottom: 'none',
    borderColor: t.palette.secondary._14,
  }),
  boxContainer: {
    position: 'relative',
  },
  title: (t) => ({
    typography: {
      xs: '22_26_500',
      sm: '16_20_600',
      lg: '24_34_500',
    },
    [t.breakpoints.between('sm', 'lg')]: {
      color: t => t.palette.secondary.main,
    },
  }),
  flexContainer: (t) => ({
    p: {
      xs: '0px 18px 18px 18px',
      sm: 18,
    },
    [t.breakpoints.between('sm', 'lg')]: {
      borderTop: `1px solid ${t.palette.secondary._14 || 'initial'}`,
    },
  }),
  requestTitle: {
    color: t => t.palette.secondary.main,
    writingMode: 'vertical-rl',
    transform: 'rotate(-360deg)',
  },
  boxIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 12,
  },
  iconBth: {
    p: 0,
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    pb: {xs: 18, sm: 0},
  },
};
