import {ErrorsProps} from "@src/types/ErrorsProps";

export const getInsurancePlanError = ({ touched, values, errors, t }: ErrorsProps) => {
  if (touched?.insurancePlan && !values.insurancePlan) {
    return errors.insurancePlan;
  }
  if (t && errors.insurancePlan === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')) {
    return errors.insurancePlan;
  }
  return '';
};
