import {Stack} from '@mui/material';
import {ReactComponent as Refresh} from '@src/shared/assets/icons/refresh.svg';
import {useMQuery} from '@src/shared/hooks';
import React from 'react';
import {Button} from 'ui-kit';

import {FilterWrapperProps} from './types';

export const FilterWrapper: React.FC<FilterWrapperProps> = ({
  children,
  onClear,
  className,
  isMotionButton,
  sxRefreshButton,
}) => {
  const {xl} = useMQuery();
  const isMotion = sxRefreshButton || (isMotionButton && xl);
  return (
    <Stack
      className={className}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="36px"
      flexWrap="nowrap"
      flexShrink={0}
      sx={{paddingRight: 18}}
    >
      {children}
      <Stack
        sx={{
          ...sxRefreshButton,
          paddingBottom: isMotion ? 0 : 8,
          paddingTop: isMotion ? 8 : 0,
        }}>
        <Button
          variant='text'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '9px',
            backgroundColor: '#EFF8FA',
            minWidth: 42,
            '&:hover': {
              backgroundColor: '#EFF8FA',
              'svg path': {
                fill: '#6EC4D2',
              },
            },
            '&:active': {
              'svg path': {
                fill: 'blue.500',
              },
            },
          }}
          color='secondary'
          onClick={onClear}>
          <Refresh />
        </Button>
      </Stack>
    </Stack>
  );
};
