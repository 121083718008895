import {EditFileDialogForm} from './types';

export const DEFAULT_DOCUMENT_FORM_VALUES: EditFileDialogForm = {
  name: '',
  category: '',
  date: '',
  description: '',
  recognize: false,
};

export const FILES_ACCEPT = '.pdf, .jpeg, .jpg, .png';
export const MAX_FILE_SIZE_BYTES = 1024 * 1024 * 5;
