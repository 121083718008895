import {useTheme} from '@mui/material';
import {EmptySearch} from '@src/components';
import {Cell} from '@src/pages/Dynamics/Board/TableComponents';
import {generateRefText} from '@src/pages/Dynamics/Dynamics.helper';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getCriticalColor} from '@src/shared/utils/results';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {AccessorFnColumnDef, createColumnHelper} from '@tanstack/react-table';
import {format} from 'date-fns';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';

import {ResultDynamicsTableProps} from '../types';

import {DATE_FORMAT} from './constants';

export const ResultDynamicsTable: FC<ResultDynamicsTableProps> = ({dynamicsWithTests, isLoading}) => {
  const {t} = useTranslate('details');
  const columnHelper = createColumnHelper<DynamicTestTransformed>();
  const theme = useTheme();

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor('testName', {
        id: 'testName',
        header: t('TEST'),
        minSize: 250,
        enableSorting: false,
      }),
      columnHelper.accessor('code', {
        id: 'units',
        header: t('UNITS'),
        minSize: 150,
        enableSorting: false,
        cell: (info) => {
          const {row} = info;
          const units = row.original.resultLaboratoryAnalyses[0]?.unit;
          return units;
        },
      }),
      columnHelper.accessor('refText', {
        id: 'reference',
        header: t('REFERENCE_RANGE'),
        minSize: 200,
        enableSorting: false,
        cell: (info) => {
          const {row} = info;
          const refMax = row.original.refMax;
          const refMin = row.original.refMin;
          const refText = row.original.refText;
          return generateRefText({refMax, refMin, refText});
        },
      }),
    ];

    const dynamicColumns = () => {
      if (!dynamicsWithTests || dynamicsWithTests.length === 0) {
        return [];
      }
      const cachedDates = new Map<string, string>();

      return dynamicsWithTests.reduce<Array<AccessorFnColumnDef<DynamicTestTransformed, string>>>((acc, item) => {
        item.resultLaboratoryAnalyses.forEach((analysis) => {
          const roundedDate = format(new Date(analysis.biomaterialSamplingDate), DATE_FORMAT);
          const formattedDate = format(new Date(analysis.biomaterialSamplingDate), 'dd/MM/yyyy');
          if (!cachedDates.has(roundedDate)) {
            cachedDates.set(roundedDate, roundedDate);

            acc.push(
              columnHelper.accessor(
                (row) =>
                  row.resultLaboratoryAnalyses.find(
                    (r: { biomaterialSamplingDate: string | number | Date }) => format(new Date(r.biomaterialSamplingDate), DATE_FORMAT) === roundedDate,
                  )?.value ?? '',
                {
                  id: roundedDate,
                  minSize: 150,
                  header: formattedDate,
                  enableSorting: false,
                  cell: ({row, column}) => {
                    const columnDate = column.id;

                    const matchingAnalysis = row.original.resultLaboratoryAnalyses.find(
                      (analysis: { biomaterialSamplingDate: string | number | Date }) => format(new Date(analysis.biomaterialSamplingDate), DATE_FORMAT) === columnDate,
                    );

                    if (!matchingAnalysis) return null;

                    const {value, comment, isCriticalRefMark} = matchingAnalysis;
                    const criticalColor = getCriticalColor(matchingAnalysis, theme);

                    return (
                      <Cell
                        value={value}
                        comment={comment}
                        color={criticalColor}
                        isCriticalRefMark={isCriticalRefMark}
                      />
                    );
                  },
                },
              ),
            );
          }
        });
        return acc;
      }, []);
    };

    return [...baseColumns, ...dynamicColumns()];
  }, [dynamicsWithTests]);

  if (isLoading || dynamicsWithTests.length === 0) {
    return <EmptySearch isLoading={isLoading} isNew />;
  }

  return (
    <NewTable
      columns={columns}
      data={dynamicsWithTests}
    />
  );
};
