import {Stack} from '@mui/material';
import {HealthcareInsurancePolicyChangeRequest} from '@src/api/insurances';
import {PageHeader as PageTitle} from '@src/components';
import {WBox} from '@src/components/WhiteBox';
import {InsuranceDeleteDialog} from '@src/features/InsuranceDeleteDialog';
import {StepDialog} from '@src/features/InsuranceDeleteDialog/types';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useAppDispatch} from '@src/store';
import {useInsurances} from '@src/store/insurance/hooks';
import {createEditDeleteInsuranceThunk, insuranceActions} from '@src/store/insurance/slice';
import {useTranslate} from 'i18n/useTranslate';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {InsuranceContent} from './InsuranceContent/InsuranceContent';
import {sx} from './styles';

export const Insurance = () => {
  const {t, ready} = useTranslate('insurance');
  const {mobile} = useMQuery();
  const {
    healthcareInsurancePolicies,
    activeHealthcareInsurancesPolicies: activeInsurance,
    fetching,
  } = useInsurances();
  const [deleteInsuranceId, setDeleteInsuranceId] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setId = (id: string | undefined) => {
    if (id) {
      setDeleteInsuranceId(id);
      dispatch(insuranceActions.setStep({step: 'open'}));
    }
  };

  const onClose = () => {
    dispatch(insuranceActions.setStep({step: StepDialog.INIT}));
  };

  const removeInsurance = useCallback(
    (data: HealthcareInsurancePolicyChangeRequest) => {
      setIsDeleting(true);
      dispatch(createEditDeleteInsuranceThunk(data))
        .unwrap()
        .then(() => {
          setDeleteInsuranceId('');
        })
        .catch(() => {})
        .finally(() => {
          setIsDeleting(false);
        });
    },
    [dispatch],
  );

  const onRemove = () => {
    if (deleteInsuranceId) {
      removeInsurance({
        deletedHealthcareInsurancePolicyIds: [deleteInsuranceId],
        healthcareInsurancePoliciesCreate: [],
        healthcareInsurancePoliciesEdit: [],
      });
    }
  };

  useScrollToTop();

  return (
    <Stack
      sx={[
        ...spreadSx(sx.mainContainer),
        {mb: mobile && (healthcareInsurancePolicies?.length ?? 0) < 2 ? 60 : 0},
      ]}
    >
      <Stack sx={sx.headerWrapper}>
        <PageTitle height={42}>{ready ? t('INSURANCE') : ''}</PageTitle>
        {!mobile && (activeInsurance?.length ?? 0) < 2 && !fetching && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
          >
            {ready ? t('ADD_INSURANCE') : ''}
          </Button>
        )}
      </Stack>
      <InsuranceContent setId={setId} activeInsurance={activeInsurance} />
      {mobile && (activeInsurance?.length ?? 0) < 2 && !fetching && (
        <WBox sx={sx.addInsuranceButtonOnMobile}>
          <Button color="secondary" onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}>
            {ready ? t('ADD_INSURANCE') : ''}
          </Button>
        </WBox>
      )}
      <InsuranceDeleteDialog
        onRemove={onRemove}
        isOpen={!!deleteInsuranceId}
        isDeleting={isDeleting}
        onClose={onClose}
        translate="insurance"
      />
    </Stack>
  );
};
