import {
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Biomarker as BiomarkerType,
  BiomarkerUnit,
  FetchBiomarkersResponse,
  storageApi,
} from '@src/api';
import {
  DEFAULT_BIOMARKER_PER_PAGE,
  NEXT_BIOMARKERS_FETCH_OFFSET,
} from '@src/components/EditDocumentDialog/components/Biomarker/constants';
import {FormInputControl} from '@src/components/FormInputControl';
import {FormSelect} from '@src/components/FormSelect';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/search-small.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {MAX_STORAGE_BIOMARKER_VALUE_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks';
import {useDebounce} from '@src/shared/hooks/useDebounce';
import {getBoldText} from '@src/shared/utils/boldText';
import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {IconButton} from 'ui-kit';

import {sx} from './styles';
import {BiomarkerProps} from './types';

export const Biomarker: FC<BiomarkerProps> = ({biomarker, onRemove, name, initialBiomarker}) => {
  const {t} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const formContext = useFormContext();

  const formBiomarkerIdName = name + '.biomarkerId';
  const formBiomarkerUnitIdName = name + '.biomarkerUnitId';
  const formValueName = name + '.value';
  const formHasUnitsName = name + '.hasUnits';

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 2000);
  const [currentBiomarkerPage, setCurrentBiomarkerPage] = useState(0);
  const [isBiomarkersLoading, setIsBiomarkersLoading] = useState(false);
  const [biomarkers, setBiomarkers] = useState<FetchBiomarkersResponse>({
    hasNext: true,
    items: [],
  });
  const [selectedBiomarker, setSelectedBiomarker] = useState<BiomarkerType>();

  const [biomarkerUnits, setBiomarkerUnits] = useState<BiomarkerUnit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<BiomarkerUnit>();

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
  };

  const handleChangeSelectedBiomarker = (newBiomarker: BiomarkerType) => {
    setSelectedBiomarker(newBiomarker);

    if (biomarker.biomarkerId !== selectedBiomarker?.id) {
      formContext.setValue(formBiomarkerUnitIdName, '');
      formContext.setValue(formValueName, '');
    }
  };

  const handleFetchBiomarkers = async (isNext?: boolean) => {
    if (isBiomarkersLoading || (isNext && !biomarkers.hasNext)) {
      return;
    }
    setIsBiomarkersLoading(true);
    try {
      const fetchPage = isNext ? currentBiomarkerPage + 1 : 0;
      const response = await storageApi.getBiomarkers({
        perPage: DEFAULT_BIOMARKER_PER_PAGE,
        startPage: fetchPage,
        phrase: debouncedSearchValue,
      });
      if (isNext) {
        setBiomarkers((prev) => ({
          hasNext: response.hasNext,
          items: [...prev.items, ...response.items],
        }));
      } else {
        setBiomarkers(response);
      }
      setCurrentBiomarkerPage(fetchPage);
    } catch (e) {
      console.error(e);
    }
    setIsBiomarkersLoading(false);
  };

  const filteredBiomarkers = useMemo(() => {
    const createBiomarkers = formContext.getValues('createBiomarkers') || [];
    const editBiomarkers = formContext.getValues('editBiomarkers') || [];
    const combinedBiomarkers = [...editBiomarkers, ...createBiomarkers];

    return biomarkers.items.filter((responseBiomarker) => {
      if (responseBiomarker.id === selectedBiomarker?.id) {
        return true;
      }
      return !combinedBiomarkers.find((cBiomarker) => {
        if (cBiomarker.biomarkerId === selectedBiomarker?.id) {
          return false;
        }
        return responseBiomarker.id === cBiomarker.biomarkerId;
      });
    });
  }, [biomarkers.items, formContext.watch('createBiomarkers'), formContext.watch('editBiomarkers')]);

  const handleFetchSelectedBiomarkerUnits = async () => {
    if (!selectedBiomarker) {
      return;
    }
    try {
      const response = await storageApi.getBiomarkersUnits({
        requests: [selectedBiomarker.id],
      });
      setBiomarkerUnits(response);
      formContext.setValue(formHasUnitsName, !!response.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (initialBiomarker) {
      const {biomarkerObj, biomarkerUnit} = initialBiomarker;
      setSelectedBiomarker(biomarkerObj);
      if (biomarkerUnit) {
        setSelectedUnit({...biomarkerUnit, biomarkerId: biomarkerObj.id});
      }
    }
  }, [initialBiomarker]);

  useEffect(() => {
    void handleFetchBiomarkers();
  }, [debouncedSearchValue]);

  useEffect(() => {
    void handleFetchSelectedBiomarkerUnits();
  }, [selectedBiomarker]);

  const handleBiomarkerRenderValue = (value: string) => {
    if (value === selectedBiomarker?.id) {
      return selectedBiomarker?.name || '';
    }
    const newBiomarker = filteredBiomarkers.find((item) => item.id === value);
    if (newBiomarker) {
      handleChangeSelectedBiomarker(newBiomarker);
      return newBiomarker.name;
    }
    return '';
  };

  const handleBiomarkerUnitRenderValue = (value: string) => {
    if (value === selectedUnit?.id) {
      return selectedUnit?.name || '';
    }
    const newUnit = biomarkerUnits.find((item) => item.id === value);
    if (newUnit) {
      setSelectedUnit(newUnit);
      return newUnit.name;
    }
    return '';
  };

  const handleBiomarkersListScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {scrollTop, scrollHeight, offsetHeight} = e.currentTarget;
    if (scrollHeight - offsetHeight < scrollTop + NEXT_BIOMARKERS_FETCH_OFFSET) {
      void handleFetchBiomarkers(true);
    }
  };

  return (
    <Stack sx={sx.container}>
      <Stack flexDirection={'row'} gap={12} flex={1}>
        <FormSelect
          name={formBiomarkerIdName}
          sx={sx.biomarkerSelect}
          placeholder={t('SELECT_BIOMARKER')}
          onBlur={handleClearSearchValue}
          renderValue={handleBiomarkerRenderValue}
          showErrorText={false}
          disabled={!!initialBiomarker}
          onScroll={handleBiomarkersListScroll}
        >
          <ListSubheader sx={sx.searchInputContainer}>
            <TextField
              fullWidth
              InputProps={{
                sx: sx.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t('SEARCH')}
              value={searchValue}
              onChange={handleChangeSearchValue}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {filteredBiomarkers.map((biomarker) => (
            <MenuItem key={biomarker.id} value={biomarker.id}>
              <ListItemText sx={sx.optionText}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: getBoldText(biomarker.name, searchValue),
                  }}
                />
              </ListItemText>
            </MenuItem>
          ))}
          {!filteredBiomarkers.length && (
            <ListSubheader sx={sx.noResultContainer}>
              <Typography variant={'14_18_500'}>{t('NO_RESULTS')}</Typography>
            </ListSubheader>
          )}
        </FormSelect>
        {isMobile && (
          <IconButton variant={'text'} color={'inherit'} onClick={onRemove}>
            <TrashIcon />
          </IconButton>
        )}
      </Stack>
      <Stack sx={sx.wrapperForm}>
        <FormInputControl
          name={formValueName}
          placeholder={t('VALUE')}
          compact={false}
          sx={sx.valueInput}
          showErrorText={false}
          disabled={!selectedBiomarker}
          maxlength={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
          max={MAX_STORAGE_BIOMARKER_VALUE_LENGTH}
        />
        <FormSelect
          name={formBiomarkerUnitIdName}
          disabled={!biomarkerUnits.length}
          renderValue={handleBiomarkerUnitRenderValue}
          showErrorText={false}
          sx={sx.unitSelect}
        >
          {biomarkerUnits.map((unit) => (
            <MenuItem key={unit.id} value={unit.id} sx={sx.unitOption}>
              <ListItemText sx={sx.optionText} primary={unit.name} />
            </MenuItem>
          ))}
        </FormSelect>
      </Stack>
      {!isMobile && (
        <IconButton variant={'text'} color={'inherit'} onClick={onRemove}>
          <TrashIcon />
        </IconButton>
      )}
    </Stack>
  );
};
