// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {DatePickerRange, SelectLabels} from '@src/components';
import Grid from '@src/components/Grid';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {IFilterResults} from '@src/pages/Results/Results.constant';
import {useMQuery} from '@src/shared/hooks';
import {FC} from 'react';

import {getPhysiciansProps} from '../types';

interface ResultsFilterDialogProps {
  selectItem: string
  filter: IFilterResults
  filterOpened: boolean
  changeSelectPhysicians: (event: SelectChangeEvent<string>) => void
  getPhysicians: getPhysiciansProps[]
  changeDate: (value: string) => void
  onClearReportDate: () => void
  onClearCollectionDate: () => void
  onClear: () => void
  onApply: () => void
  onClose: () => void;
}

export const ResultsFilterDialog: FC<ResultsFilterDialogProps> = ({
  filterOpened,
  changeSelectPhysicians,
  getPhysicians,
  changeDate,
  onClearReportDate,
  onClearCollectionDate,
  onClear,
  onApply,
  filter,
  selectItem,
  onClose,
}) => {
  const {t} = useTranslate('results');
  const {desktop, lg} = useMQuery();

  const onMiddleSizeWidth = (!lg && desktop);

  return (
    <FiltersDialogContainer
      isOpen={filterOpened}
      onClose={onClose}
      title={t('FILTERS')}
      onApply={onApply}
      onClear={onClear}
    >
      <Stack gap={24}>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && <Typography variant='14_18_700'>{t('PHYSICIAN')}</Typography>}
          <SelectLabels
            value={selectItem}
            placeholder={t('SELECT')}
            onChange={changeSelectPhysicians}
            options={getPhysicians || []}
            label={onMiddleSizeWidth ? t('PHYSICIAN') : ''}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && <Typography variant='14_18_700'>{t('REPORT_DATE')}</Typography>}
          <DatePickerRange
            label={onMiddleSizeWidth ? t('REPORT_DATE') : ''}
            variant="outlined"
            startDate={filter.reportDateFrom}
            setStartDate={changeDate('reportDateFrom')}
            setEndDate={changeDate('reportDateTo')}
            endDate={filter.reportDateTo}
            css={_`width: 100%; margin-bottom: 0 !important`}
            clearDate={onClearReportDate}
            isLabelOutside={true}
            isIconVisible={false}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          {!onMiddleSizeWidth && <Typography variant='14_18_700'>{t('COLLECTION_DATE')}</Typography>}
          <DatePickerRange
            label={onMiddleSizeWidth ? t('COLLECTION_DATE') : ''}
            variant="outlined"
            startDate={filter.collectionDateFrom}
            setStartDate={changeDate('collectionDateFrom')}
            setEndDate={changeDate('collectionDateTo')}
            endDate={filter.collectionDateTo}
            sx={{backgroundColor: 'black'}}
            css={_`width: 100%; margin-bottom: 0 !important`}
            clearDate={onClearCollectionDate}
            isLabelOutside={false}
            isIconVisible={false}
          />
        </Grid>
      </Stack>
    </FiltersDialogContainer>
  );
};
