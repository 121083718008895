import {Link, PageHeader as PageTitle, RowInformation, WBox} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {EditDocumentDialog} from '@src/components/EditDocumentDialog';
import {TKeys} from '@src/i18n/useTranslate';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {BiomarkersTable} from '@src/pages/StorageDocument/components/BiomarkersTable';
import {FileView} from '@src/pages/StorageDocument/components/FileView';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {DateFormat, dateFormatted, formatSize} from '@src/shared/utils';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {useFileData} from './hooks';
import {ContentContainer, sx} from './styles';

export const StorageDocument = () => {
  const translate = useStorageTranslate();
  const {mobile: isMobile} = useMQuery();
  const {id} = useParams();
  const {isSidebarOpen} = useSidebarClassObserver();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const rowInfoVariant = isMobile ? '14_18_700' : '16_20_700';
  const rowInfoValueVariant = isMobile ? '14_18_500' : '16_20_500';

  const {
    handleDownloadFile,
    fileDetails,
    handleToggleFavorite,
    handleUpdateFile,
    handleFetchFileData,
    fileData,
  } = useFileData(id);

  const downloadFileHandler = () => {
    void handleDownloadFile();
  };

  const handleToggleEditDialogOpen = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  return (
    <Stack sx={sx.mainContainer}>
      <PageTitle
        height={'auto'}
        alignItems="flex-start"
        itemAction={
          !isMobile
            ? (
              <Button
                color={'secondary'}
                variant={'outlined'}
                startIcon={<DownloadIcon />}
                onClick={downloadFileHandler}
              >
                {translate('DOWNLOAD_DOCUMENT')}
              </Button>
            )
            : undefined
        }
      >
        <Stack sx={sx.breadcrumbs}>
          <ArrowLeftIcon />
          <Link to={ROUTERS_PATH.STORAGE}>{translate('BACK_TO_STORAGE')}</Link>
        </Stack>
      </PageTitle>
      <ContentContainer isSidebarOpen={isSidebarOpen}>
        <WBox sx={sx.leftContainer}>
          <Typography sx={sx.fileName}>{fileDetails?.name}</Typography>
          <BiomarkersTable biomarkers={fileDetails?.biomarkers || []} />
          <Stack gap={12}>
            <RowInformation
              variant={rowInfoVariant}
              valueTypographyVariant={rowInfoValueVariant}
              noMargin
              value={translate(fileDetails?.category as TKeys<'storage'>)}
              name={translate('CATEGORY')}
              valueSx={sx.rowValue}
            />
            <RowInformation
              variant={rowInfoVariant}
              valueTypographyVariant={rowInfoValueVariant}
              noMargin
              value={dateFormatted(fileDetails?.date, DateFormat.DATE_SHORT)}
              name={translate('DATE')}
              valueSx={sx.rowValue}
            />
            <RowInformation
              variant={rowInfoVariant}
              valueTypographyVariant={rowInfoValueVariant}
              noMargin
              value={fileDetails?.type}
              name={translate('DOCUMENT_TYPE')}
              valueSx={sx.rowValue}
            />
            <RowInformation
              variant={rowInfoVariant}
              valueTypographyVariant={rowInfoValueVariant}
              noMargin
              value={formatSize(fileDetails?.size || 0)}
              name={translate('DOCUMENT_SIZE')}
              valueSx={sx.rowValue}
            />
            <RowInformation
              variant={rowInfoVariant}
              valueTypographyVariant={rowInfoValueVariant}
              noWrap
              noMargin
              value={fileDetails?.description}
              name={translate('DESCRIPTION')}
              sx={sx.rowInfo}
              valueSx={sx.rowValue}
            />
          </Stack>
        </WBox>
        <FileView
          details={fileDetails}
          isSidebarOpen={isSidebarOpen}
          onToggleFavorite={handleToggleFavorite}
          onOpenEditDialog={handleToggleEditDialogOpen}
          fileData={fileData}
          onFetchFileData={handleFetchFileData}
        />
      </ContentContainer>
      {fileDetails && (
        <EditDocumentDialog
          fileDetails={fileDetails}
          isOpen={isEditDialogOpen}
          onClose={handleToggleEditDialogOpen}
          onSave={handleUpdateFile}
        />
      )}
      {isMobile && (
        <Box sx={sx.mobileDownloadButton}>
          <Button color={'secondary'} onClick={downloadFileHandler} fullWidth>
            {translate('DOWNLOAD_DOCUMENT')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
