import {
  Stack,
  Typography,
  Avatar,
  Box,
} from '@mui/material';
import {AccountStatus, RelationContactSearchResult} from '@src/api/relations';
import {CircleStatus, Tooltip} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {createColumnHelper} from '@tanstack/react-table';
import {useMemo} from 'react';

import {AddRelationTableAction} from './AddRelationTableAction';
import {COLORS} from './constant';
import {sx} from './styles';
import {EAccountStatusName, IAddRelationTableProps} from './types';

export const AddRelationTable = ({
  relationContacts,
  relationType,
}: IAddRelationTableProps) => {
  const {t} = useTranslate('connections');
  const {xl} = useMQuery();

  const columnHelper = createColumnHelper<RelationContactSearchResult>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('firstName', {
        id: 'firstName',
        header: t('NAME'),
        minSize: xl ? 350 : 305,
        enableSorting: false,
        cell: ({row}) => {
          const accountStatus = row.original.accountStatus;
          return (
            <Stack sx={sx.presonColumnWrapper}>
              <Tooltip title={`Account status - ${EAccountStatusName[accountStatus]}`}>
                <Box sx={sx.flag}>
                  <CircleStatus color={COLORS[AccountStatus[accountStatus]].color} />
                </Box>
              </Tooltip>
              <Avatar sx={sx.requestAvatar} src={row.original.accountAvatarUrl}>
                <Typography variant="14_20_700">
                  {getUserInitials({
                    firstName: row.original.firstName,
                    lastName: row.original.lastName,
                    middleName: row.original.middleName,
                  })}
                </Typography>
              </Avatar>
              <Stack sx={sx.personNameWrapper}>
                <EllipsisTextTooltip variant="14_18_700" sx={sx.personName} ellipsisText={getName({firstName: row.original.firstName, lastName: row.original.lastName})}>
                  {getName({
                    firstName: row.original.firstName,
                    lastName: row.original.lastName,
                  })}
                </EllipsisTextTooltip>
                <Typography variant="14_18_400">{row.original.birthDateS}</Typography>
              </Stack>
            </Stack>
          );
        },
      }),
      columnHelper.accessor('phone', {
        id: 'phone',
        header: t('PHONE'),
        minSize: 97,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{getValue()}</Box>;
        },
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: t('EMAIL'),
        minSize: 165,
        enableSorting: false,
        cell: ({getValue}) => {
          return (
            <EllipsisTextTooltip
              variant="14_18_400"
              ellipsisText={getValue()}
            >
              {getValue()}
            </EllipsisTextTooltip >
          );
        },
      }),
      columnHelper.accessor('ssnTail', {
        id: 'ssnTail',
        header: t('SSN_LAST_4_DIGITS'),
        minSize: 112,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{getValue()}</Box>;
        },
      }),
      columnHelper.accessor('policyNumber', {
        id: 'policyNumber',
        header: t('POLICY_ID'),
        minSize: 140,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{getValue()}</Box>;
        },
      }),
      columnHelper.display({
        id: 'menu',
        header: t('ACTION'),
        enableSorting: false,
        minSize: 200,
        cell: ({row}) => {
          return (
            <AddRelationTableAction
              id={row.original.id}
              relationError={row.original.relationError}
              relationshipType={relationType || null}
              newContactBirthDate={row.original.birthDateS}
            />
          );
        },
      }),
    ], [columnHelper, relationType, xl, t]);

  if (relationContacts.length < 1) {
    return (
      <Typography
        variant='14_18_500'
        sx={sx.patientNotFound}
      >
        {t('NO_SUITABLE_RESULTS')}
      </Typography>
    );
  };

  return (
    <NewTable
      columns={columns}
      data={relationContacts}
      isPaddingFirstColumn={true}
      isPaddingLastColumn={true}
    />
  );
};
