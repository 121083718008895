import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HealthcareInsurancePolicy} from '@src/api';
import {
  HealthcareInsurancePolicyChangeRequest,
  InsuranceCarriersList,
  InsurancePlansList,
  insurancesApi,
} from '@src/api/insurances';
import i18n from '@src/i18n/i18n';
import {getError} from '@src/store/common/error-handlers';
import {notifyActions} from '@src/store/notifications/slice';
import {ValidationErrorType} from '@src/types';

export type RequestNPIOrgFlowSteps = 'init' | 'open' | 'loading' | 'running' | 'success' | 'error';
export interface FavoritesState {
  healthcareInsurancePolicies: HealthcareInsurancePolicy[] | null
  insuranceCarriers: InsuranceCarriersList[] | null
  insurancePlans: InsurancePlansList[] | null
  step: RequestNPIOrgFlowSteps
  fetching: boolean
  error?: ValidationErrorType | null
}
const initialState: FavoritesState = {
  healthcareInsurancePolicies: null,
  insuranceCarriers: null,
  insurancePlans: null,
  step: 'init',
  fetching: false,
};

export const createEditDeleteInsuranceThunk = createAsyncThunk(
  'insurance/createEditDeleteInsurance',
  async (payload: HealthcareInsurancePolicyChangeRequest, {rejectWithValue, dispatch}) => {
    const isDelete = !!payload.deletedHealthcareInsurancePolicyIds?.length;
    try {
      await insurancesApi.createInsurance(payload);

      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'success',
            text: i18n.t(
              isDelete
                ? 'INSURANCE_SUCCESS_DELETE'
                : 'INSURANCE_SUCCESS_SAVE',
            ),
          },
        ]),
      );
      const healthcareInsurancePolicies = await insurancesApi.getInsurances();
      dispatch(insuranceActions.setInsurances({healthcareInsurancePolicies}));
    } catch (e) {
      const error = getError(e);
      dispatch(
        notifyActions.showNotifications([
          {id: '', type: 'error', text: i18n.t(isDelete ? 'INSURANCE_ERROR_DELETE' : 'INSURANCE_ERROR_SAVE')},
        ]),
      );
      return rejectWithValue(error);
    }
  },
);

export const insurance = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    requestInsurances () {},
    requestInsuranceCarriers (_state, _action: PayloadAction<{filter: string}>) {},
    requestInsurancePlans (_state, _action: PayloadAction<{insuranceCarrierId: string}>) {},
    createInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    editInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    deleteInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    setInsurances (state, {payload}: PayloadAction<{healthcareInsurancePolicies: HealthcareInsurancePolicy[]}>) {
      state.healthcareInsurancePolicies = payload.healthcareInsurancePolicies;
    },
    setInsurancePlans (state, {payload}: PayloadAction<{ insurancePlans: InsurancePlansList[] }>) {
      state.insurancePlans = payload.insurancePlans;
    },
    setInsuranceCarriers (
      state,
      {payload}: PayloadAction<{ insuranceCarriers: InsuranceCarriersList[] }>,
    ) {
      state.insuranceCarriers = payload.insuranceCarriers;
    },
    clearInsuranceCarriers (state) {
      state.insuranceCarriers = [];
    },
    setStep (state, {payload}: PayloadAction<{ step: RequestNPIOrgFlowSteps }>) {
      state.step = payload.step;
    },
    setFetching (state, {payload}: PayloadAction<FavoritesState['fetching']>) {
      state.fetching = payload;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const insuranceReducer = insurance.reducer;
export const insuranceActions = insurance.actions;
export const insuranceStoreName = insurance.name;
