import {Box, Typography} from '@mui/material';
import {EmptySearch} from '@src/components';
import {EBackgroundFlagColor, EFlagColor, EFlagName} from '@src/pages/Results/Results.constant';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {useWindowWidth} from '@src/shared/hooks/useWindowWidth';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {DATE_FORMAT, dateToFormat, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {createColumnHelper} from '@tanstack/react-table';
import {Result} from 'api';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useMemo} from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';

import {CRITICAL_FLAG} from '../constants';

interface ResultsTableProps {
  result?: Result[]
  isLoading?: boolean
}

export const ResultsTable: FC<ResultsTableProps> = ({result, isLoading}) => {
  const {t} = useTranslate('results');
  const columnHelper = createColumnHelper<Result>();
  const navigate = useNavigate();
  const relation = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const params = useParams();
  const {desktop} = useMQuery();
  const windowWidth = useWindowWidth();
  const {isSidebarOpen} = useSidebarClassObserver();
  const widthColumn = desktop ? 1200 : 1450;
  const subtractWidthSidebar = isSidebarOpen && desktop ? 280 : 0;

  const physicianColumnWidth = Math.max((widthColumn - subtractWidthSidebar) - (1920 - windowWidth), 150);

  const columns = useMemo(
    () => [
      columnHelper.accessor('reported', {
        id: 'reported',
        header: t('REPORTED'),
        minSize: 150,
        sortingFn: 'datetime',
        cell: ({getValue}) => {
          return (
            <Box>{dateToFormat(DATE_FORMAT, getValue() || '')}</Box>
          );
        },
      }),
      columnHelper.accessor('collected', {
        id: 'collected',
        header: t('COLLECTED'),
        minSize: 150,
        sortingFn: 'datetime',
        cell: ({getValue}) => {
          return <Box>{dateToFormat(DATE_FORMAT, getValue() || '')}</Box>;
        },
      }),
      columnHelper.accessor('physician', {
        id: 'physician',
        header: t('PHYSICIAN'),
        size: 1,
        minSize: physicianColumnWidth,
        enableSorting: false,
        cell: ({getValue}) => {
          return <Box>{getFullNameWithoutMiddleName(getValue()) || ''}</Box>;
        },
      }),
      columnHelper.accessor('flag', {
        id: 'flag',
        header: t('FLAG'),
        minSize: 100,
        enableSorting: false,
        cell: ({getValue}) => {
          const flag = getValue();
          return (
            <Box>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : null}
            </Box>
          );
        },
      }),
    ], [columnHelper, t, physicianColumnWidth]);

  const handleViewPage = ({id, companyId}: { id: string, companyId: string }) => {
    if (relation && params?.id) {
      return navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${params.id}/${id}/${companyId}/results`);
    } else {
      return navigate(`${id}/${companyId}`);
    };
  };

  const isError = (row: any) => row.flag === CRITICAL_FLAG;

  if (!result?.length) {
    return <EmptySearch isLoading={isLoading} isNew />;
  }

  return (
    <NewTable
      columns={columns}
      data={result}
      onRowClick={handleViewPage}
      isError={isError}
    />
  );
};
