import {StorageFileDetails} from '@api';

import {DEFAULT_DOCUMENT_FORM_VALUES} from './constants';
import {EditFileDialogForm} from './types';

export const getDefaultValues = (fileDetails?: StorageFileDetails): EditFileDialogForm => {
  if (!fileDetails) {
    return DEFAULT_DOCUMENT_FORM_VALUES;
  }

  const {name, description, date, category, recognize, biomarkers} = fileDetails;
  return {
    name,
    date,
    description,
    category,
    recognize,
    editBiomarkers: biomarkers.map(({value, biomarkerObj, biomarkerUnit, id}) => ({
      value,
      biomarkerId: biomarkerObj?.id,
      biomarkerUnitId: biomarkerUnit?.id,
      hasUnits: !!biomarkerUnit?.id,
      initialId: id,
    })),
  };
};
