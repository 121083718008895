import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    flexGrow: 1,
    gap: {xs: 36, lg: 0},
    [t.breakpoints.up('md')]: {
      height: 'calc(100vh - 200px)',
      overflow: 'hidden',
    },
    [t.breakpoints.between('sm', 'lg')]: {
      backgroundColor: t.palette.common.white,
      flexGrow: 1,
      gap: 0,
      height: 'calc(100vh - 198px)',
      overflow: 'hidden',
    },
    [t.breakpoints.only('xs')]: {
      backgroundColor: t.palette.common.white,
      flexGrow: 1,
      p: 18,
      border: '1px solid',
      borderColor: t.palette.secondary._14,
      mb: 76,
    },
  }),
  contentContainer: (t) => ({
    height: {xs: 114, sm: 132},
    border: '1px solid',
    borderColor: t.palette.secondary._28,
    p: '12px 18px',
    boxSizing: 'border-box',
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'start',
    [t.breakpoints.only('xs')]: {
      p: 12,
    },
    backgroundColor: t.palette.secondary._7,
    '&:hover': {
      backgroundColor: t.palette.secondary._14,
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: t.palette.secondary._28,
      borderColor: t.palette.secondary._62,
      cursor: 'pointer',
    },
  }),
  content: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },
  avatar: (t) => ({
    width: {xs: 40, sm: 72},
    height: {xs: 40, sm: 72},
    mr: 16,
    mt: {xs: 8, lg: 0},
    backgroundColor: t.palette.secondary._21,
    color: t.colors.all.white,
  }),
  requestAvatar: (t) => ({
    width: 40,
    height: 40,
    mr: 16,
    backgroundColor: t.palette.secondary._21,
    color: t.colors.all.white,
  }),
  personBirthDay: {
    typography: {
      xs: '12_20_500',
      sm: '14_18_500',
    },
  },
  personType: {
    color: 'grey.600',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  personName: (t) => ({
    [t.breakpoints.only('xs')]: {
      typography: '16_20_700',
    },
  }),
  emptyDataInformation: (t) => ({
    display: 'flex',
    alignItems: 'center',
    mb: {xs: 0, sm: 48},
    color: t.palette.grey[600],
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  }),
  personInfoWrapper: {
    overflow: 'hidden',
  },
  personInfo: {
    '& > :last-child': {
      mt: {
        xs: 3,
        sm: 6,
      },
    },
    '& > :first-child': {
      mb: -3,
    },
  },
  link: {
    display: 'flex',
    height: 42,
    p: '0px 9px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    cursor: 'pointer',
  },
  tabs: {
    typography: '16_20_500',
    p: '6px 90px 12px 90px',
  },
  wrapper: (t) => ({
    gap: {
      xs: 0,
      sm: 48,
      lg: 48,
    },
    justifyContent: {
      xs: 'space-between',
      sm: 'flex-start',
    },
    m: 0,
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    pr: {xs: 18, lg: 0},
    p: {
      sm: '24px 12px 24px 24px',
      lg: '24px 40px 24px 24px',
    },
    [t.breakpoints.only('xs')]: {
      overflow: 'initial',
      pr: 24,
    },
  }),
  addRelationButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  addRelationButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    p: '16px 18px',
  },
  boxContainer: {
    mr: {
      xs: -24,
      sm: 0,
      lg: -38,
    },
  },
  fetchingBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    pt: {xs: 18, sm: 0},
  },
};
