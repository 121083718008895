import {styled} from '@mui/material';
import {ReactComponent as SortIcon} from '@src/shared/assets/icons/sorting_arrows_24x24.svg';
import {initialColors, paletteColors} from '@src/theme';

import {IStyledHeaderTRProps, IStyledTDProps, IStyledTHProps, IStyledTRProps} from './types';

export const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  border-collapse: separate;
`;

export const StyledTR = styled('tr')<IStyledTRProps>(({isPointer, isError, height, minHeight}) => {
  const hoverBackgroundError = isError ? paletteColors.red[100] : 'transparent';

  return {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    display: 'flex',
    width: '100%',
    cursor: isPointer ? 'pointer' : 'default',
    backgroundColor: isError ? paletteColors.red[100] : 'initial',
    minHeight,
    height,

    ':hover': {
      backgroundColor: isPointer ? initialColors.grayBackground : hoverBackgroundError,
    },

    '&.active': {
      backgroundColor: initialColors.grayBackground,
    },

    'td:first-child': {
      paddingLeft: '18px',
    },

    td: {
      padding: '6px 10px',
      borderBottom: `1px solid ${initialColors.blueLight}`,
    },
  };
});

export const StyledTH = styled('th')<IStyledTHProps>(({isFirstColumn, isHorizontalScrolled}) => ({
  width: '100%',
  display: 'flex',
  position: 'sticky',
  top: '0',
  background: initialColors.white,
  zIndex: isFirstColumn ? '2' : '1',
  left: isFirstColumn ? '0' : undefined,
  boxShadow: isHorizontalScrolled && isFirstColumn
    ? '8px 0px 9px 0px rgba(34, 60, 80, 0.04)'
    : 'none',
}));

export const StyledTD = styled('td')<IStyledTDProps>(({isFirstColumn, isLastColumn, isHorizontalScrolled, height, isError}) => {
  const backgroundError = isError ? paletteColors.red[100] : initialColors.white;
  return {
    width: '100%',
    display: 'flex',
    alignItems: height === 'auto' ? 'start' : 'center',
    top: '0',
    zIndex: isFirstColumn ? '1' : '0',
    position: isFirstColumn ? 'sticky' : 'initial',
    left: isFirstColumn ? '0' : '',
    backgroundColor: isFirstColumn && isHorizontalScrolled ? backgroundError : 'transparent',
    overflow: isLastColumn ? 'hidden' : 'unset',
    textOverflow: isLastColumn ? 'ellipsis' : 'unset',
    whiteSpace: isLastColumn ? 'nowrap' : 'unset',
    borderBottom: `1px solid ${initialColors.blueLight}`,
    boxShadow: isHorizontalScrolled && isFirstColumn
      ? '8px 10px 9px 0px rgba(34, 60, 80, 0.04)'
      : 'none',
  };
});

export const StyledTableHead = styled('thead')`
  position: sticky;
  top: 0;
  background-color: ${({theme}) => theme.colors.all};
  z-index: 2;
  display: grid;

  th:first-child {
    padding-left: 18px;
  }

  th {
    padding: 6px 10px;
    border-top: 1px solid ${initialColors.blueLight};
    border-bottom: 1px solid ${initialColors.blueLight};
    height: 52px;
  }
`;

export const StyledHeaderTR = styled('tr')<IStyledHeaderTRProps>(({isVerticallyScrolled}) => ({
  display: 'flex',
  width: '100%',
  boxShadow: isVerticallyScrolled ? '8px 0px 9px 0px rgba(34, 60, 80, 0.14)' : 'none',
}));

export const HeaderItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  textOverflow: ellipsis;
  whiteSpace: nowrap;
`;

export const StyledSortIcon = styled(SortIcon)<{ sorting: 'asc' | 'desc' | null }>`
  width: 15px;
  height: 15px;

  .up {
    color: ${({sorting}) => (sorting === 'asc' ? paletteColors.grey[600] : paletteColors.grey[400])};
  }

  .down {
    color: ${({sorting}) => (sorting === 'desc' ? paletteColors.grey[600] : paletteColors.grey[400])};
  }
`;
