import {useState, useEffect, useRef, useCallback} from 'react';

interface IBreakpoints {
  xxl?: number
  xl?: number
  lg?: number
  sm?: number
  md?: number
}

interface IColumns extends IBreakpoints {}

interface IUseColumnsNumberProps {
  breakpoints: IBreakpoints
  defaultColumns: IColumns
  customColumns: IColumns
}

export const useColumnsNumber = ({breakpoints, defaultColumns, customColumns}: IUseColumnsNumberProps) => {
  const {xxl, xl, lg, md, sm} = breakpoints;
  const {xxl: defaultXxl, xl: defaultXl, lg: defaultLg, md: defaultMd, sm: defaultSm} = defaultColumns;
  const {xxl: customXxl, xl: customXl, lg: customLg, md: customMd, sm: customSm} = customColumns;
  const [colsNumberXxl, setColsNumberXxl] = useState(defaultXxl);
  const [colsNumberXl, setColsNumberXl] = useState(defaultXl);
  const [colsNumberLg, setColsNumberLg] = useState(defaultLg);
  const [colsNumberMd, setColsNumberMd] = useState(defaultMd);
  const [colsNumberSm, setColsNumberSm] = useState(defaultSm);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContainerResize = useCallback(() => {
    if (containerRef.current && sm && md && lg && xl && xxl) {
      const width = containerRef.current.offsetWidth;

      setColsNumberSm(width < sm ? customSm : defaultSm);
      setColsNumberMd(width < md ? customMd : defaultMd);
      setColsNumberLg(width < lg ? customLg : defaultLg);
      setColsNumberXl(width < xl ? customXl : defaultXl);
      setColsNumberXxl(width > xxl ? customXxl : defaultXxl);
    }
  }, [xxl, xl, lg, md, sm, customSm, customMd, customLg, customXl, customXxl, defaultSm, defaultMd, defaultLg, defaultXl, defaultXxl]);

  useEffect(() => {
    handleContainerResize();
    const resizeObserver = new ResizeObserver(handleContainerResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, breakpoints, customColumns, defaultColumns, handleContainerResize]);

  return {colsNumberXxl, colsNumberXl, colsNumberLg, colsNumberMd, colsNumberSm, containerRef};
};
