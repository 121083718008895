import {AnyAction} from '@reduxjs/toolkit';
import {BMIRecommendationPayload} from '@src/api';
import {DEFAULT_HEIGHT, DEFAULT_WEIGHT} from '@src/shared/constants/BMIconstants';
import {getBmi, getWeightTitle} from '@src/shared/utils/bmiUtils';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store';

import {TRequestNPIOrgFlowSteps, bmiActions, fetchRecommendation} from './slice';

const useBmiState = () => useAppSelector((state) => state.bmi);

export const useRecomendations = () => {
  const {recommendationData} = useBmiState();
  const dispatch = useAppDispatch();

  const getRecommendations = useCallback((props: BMIRecommendationPayload) => {
    dispatch(fetchRecommendation(props) as unknown as AnyAction);
  }, [dispatch]);

  return {
    recommendationData,
    getRecommendations,
  };
};

export const useBmi = () => {
  const bmiState = useBmiState();
  const dispatch = useDispatch();

  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [weight, setWeight] = useState(DEFAULT_WEIGHT);

  useEffect(() => {
    if (bmiState.height) {
      setHeight(bmiState.height);
    };
  }, [bmiState.height]);

  useEffect(() => {
    if (bmiState.weight) {
      setWeight(bmiState.weight);
    };
  }, [bmiState.weight]);

  const bmi = useMemo(() => {
    return getBmi(weight, height);
  }, [height, weight]);

  const weightTitle = useMemo(() => {
    return getWeightTitle(weight, height);
  }, [height, weight]);

  useEffect(() => {
    if (!bmiState.bmi) {
      dispatch(bmiActions.requestGetBMI());
    }
  }, [bmiState.bmi, dispatch]);

  return {
    weightTitle,
    bmi,
    height,
    weight,
    isFetching: bmiState.fetching,
    data: bmiState.data,
    setHeight,
    setWeight,
  };
};

export const useSteps = () => {
  const {step} = useBmiState();
  const dispatch = useDispatch();

  return {
    step,
    setStep: (step: TRequestNPIOrgFlowSteps) => dispatch(bmiActions.setStep({step})),
  };
};

export const useCreateBmi = () => {
  const dispatch = useDispatch();
  return ({height, weight}: {height: number, weight: number}) => dispatch(bmiActions.requestCreateBmi({height, weight}));
};
