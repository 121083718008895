// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {CircleStatus} from '@components';
import {Box, IconButton, SvgIcon, Typography} from '@mui/material';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as IconSVGArrowDown} from '@src/shared/assets/icons/chevron-down.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, DATE_FORMAT, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {paletteColors} from '@src/theme';
import {useTranslate} from 'i18n/useTranslate';
import React, {useState, useEffect, MouseEventHandler} from 'react';
import {useNavigate, useParams, useMatch} from 'react-router-dom';

import {EBackgroundFlagColor, EFlagColor, EFlagName} from './Results.constant';
import {Cell, ExpandableRowContainer, InternalRow, MainRow} from './styles';
import {IRowTableProps} from './types';

export const TableRow: React.FC<IRowTableProps> = ({className, gridTemplateColumns, data, onExpand, allExpanded, onlySemanticBlock = true, sx, isNew}) => {
  const navigate = useNavigate();
  const relation = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const {mobile, tablet} = useMQuery();
  const {t} = useTranslate('results');
  const params = useParams();
  const {id, reported, collected, physician, flag, companyId} = data;
  const [expanded, setExpanded] = useState(false);
  const goToResult = () => {
    relation
      ? navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${params?.id ?? ''}/${id}/${companyId}/results`)
      : navigate(`${id}/${companyId}`);
  };

  const mobileGridRow = {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 2fr',
  };

  const handleExpand: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setExpanded(st => {
      const newVal = !st;
      onExpand?.(newVal);
      return !st;
    });
  };

  useEffect(() => {
    setExpanded(allExpanded ?? false);
  }, [allExpanded]);

  return (
    <ExpandableRowContainer
      sx={sx}
      className={className}
      onlySemanticBlock={onlySemanticBlock}
      critical={flag === 'CRITICAL' ? 1 : 0}>
      <MainRow
        onClick={goToResult}
        style={{gridTemplateColumns}}
      >
        <Cell gap={12} sx={mobile ? mobileGridRow : undefined} pl={(mobile || (tablet && isNew)) ? 0 : 24}>
          {(mobile || (tablet && isNew)) && flag && (
            <Box width={24} display='flex' justifyContent='center'>
              <CircleStatus color={EFlagColor[flag]} />
            </Box>
          )}
          {!isNew && dateToFormat(DATE_FORMAT, reported ?? '')}
          {isNew && (
            <>
              <Typography variant='12_16_500'>{dateToFormat(DATE_FORMAT, reported ?? '')}</Typography>
              <Typography
                variant='12_16_500'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>{getFullNameWithoutMiddleName(physician) || ''}</Typography>
            </>
          )}
        </Cell>
        {(!mobile && !isNew) && (
          <>
            <Cell>{dateToFormat(DATE_FORMAT, collected || '')}</Cell>
            <Cell>{getFullNameWithoutMiddleName(physician) || ''}</Cell>
            <Cell>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : null}
            </Cell>
          </>
        )}
        {(mobile && !isNew) && (
          <>
            <Cell>{getFullNameWithoutMiddleName(physician) || ''}</Cell>
          </>
        )}
        {(mobile || (tablet && isNew)) && (
          <Cell sx={{justifyContent: 'end'}}>
            <IconButton sx={{p: 0}} onClick={handleExpand}>
              {expanded
                ? (
                  <SvgIcon sx={{transform: 'rotate(180deg)', width: 24, height: 24, color: paletteColors.grey[300]}}>
                    <IconSVGArrowDown width={24} height={24} style={{marginBottom: 10}} />
                  </SvgIcon>
                )
                : (
                  <SvgIcon sx={{width: 24, height: 24, color: paletteColors.grey[300]}}>
                    <IconSVGArrowDown width={24} height={24} style={{marginBottom: 10}} />
                  </SvgIcon>
                )}
            </IconButton>
          </Cell>
        )}
      </MainRow>
      {(mobile || tablet) && expanded && (
        <Box mb={'16px'}>
          <InternalRow style={{gridTemplateColumns}}>
            {!isNew && (
              <>
                <Cell ml={36}><strong>{t('COLLECTED')}</strong></Cell>
                <Cell>{dateToFormat(DATE_FORMAT, collected || '')}</Cell>
              </>
            )}
            {isNew && (
              <Cell ml={36} sx={{gap: 10}}>
                <strong>{t('COLLECTED')}</strong>
                <Typography variant='12_16_500'>{dateToFormat(DATE_FORMAT, collected || '')}</Typography>
              </Cell>
            )}
          </InternalRow>
        </Box>
      )}
    </ExpandableRowContainer>
  );
};
