import {Box, Stack, SvgIcon, Typography} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ChevronRightIcon} from '@src/shared/assets/icons/chevron-right.svg';
import {ReactComponent as BmiImage} from '@src/shared/assets/images/bmi.svg';
import {useMQuery} from '@src/shared/hooks';
import {dateFormatted} from '@src/shared/utils';
import {getWeightTitleFromBmi} from '@src/shared/utils/bmiUtils';
import {prepareDataForCharts} from '@src/shared/utils/prepareDateForCharts';
import {Link} from 'react-router-dom';
import {Button} from 'ui-kit';

import {MiniCharts} from '../MiniCharts/MiniCharts';

import {sx} from './styles';
import {BMICardProps} from './types';

export const BMICard = ({
  title,
  bmiData,
}: BMICardProps) => {
  const {mobile} = useMQuery();
  const {t} = useTranslate('journal');
  const isHasData = bmiData.length !== 0;

  const bmiValue = bmiData[0]?.value;
  const bmiTitle = getWeightTitleFromBmi(bmiValue);
  const bmiDate = dateFormatted(bmiData[0]?.createdTime);
  const {chartData, isEmptyData} = prepareDataForCharts(bmiData, null, null, 1, true);

  return (
    <WBox sx={sx.card}>
      <Stack width='100%'>
        <Box sx={sx.header} gap={12}>
          <Typography variant='24_34_500'>{title}</Typography>
          {!isEmptyData && <Typography sx={sx.chartTitle} variant='14_18_500'>{t('LAST_WEEK_RESULTS')}</Typography>}
        </Box>
        <Box sx={sx.content}>
          <Stack justifyContent='space-between'>
            <Box >
              {!isHasData
                ? <Typography sx={sx.description}>{t('FOR_MOST_PEOPLE_BMI_')}</Typography>
                : (
                  <Stack>
                    <Typography sx={sx.bmiValue}>{bmiValue}</Typography>
                    <Typography sx={sx.weightTitle}>{t(bmiTitle)}</Typography>
                    <Typography sx={sx.date}>{bmiDate}</Typography>
                  </Stack>
                )}
            </Box>
            <Box sx={sx.buttonContainer}>
              <Button
                component={Link}
                to={ROUTERS_PATH.BMI}
                sx={sx.linkButton}
                size='sm'
                variant={'text'}
                color='secondary'
                endIcon={
                  <SvgIcon>
                    <ChevronRightIcon />
                  </SvgIcon>
                }
                >
                  <Typography sx={sx.buttonText}>{isHasData ? t('SEE_MORE') : t('CALCULATE_BMI')}</Typography>
                </Button>
            </Box>
          </Stack>

          {!mobile && !isHasData && (
            <Box sx={sx.imgContainer}>
              {<BmiImage />}
            </Box>
          )}
          {isHasData && (
            <Box sx={sx.chartContainer}>
              <MiniCharts data={chartData} />
            </Box>
          )}
        </Box>

      </Stack>
    </WBox>
  );
};
