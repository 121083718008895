import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogContent: {
    p: {xs: '18px 24px 12px 24px !important', sm: '24px !important', lg: '36px 24px 24px 24px !important'},
  },
  dialogActions: {
    flexDirection: 'row!important',
    gap: '24px !important',
  },
};
