import {StorageFileCategory} from '@api';
import {DatePickerRange} from '@components';
import {ListItemText, SelectChangeEvent, Stack} from '@mui/material';
import {FiltersDialog as FiltersDialogContainer} from '@src/components/fragments/FiltersDialog';
import {TKeys} from '@src/i18n/useTranslate';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {FC, useEffect, useState} from 'react';
import {Option, SelectControl} from 'ui-kit';

import {getCategoryOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';

import {FiltersDialogProps} from './types';

export const FiltersDialog: FC<FiltersDialogProps> = ({filters, onUpdateFilters, isOpen, onClose}) => {
  const translate = useStorageTranslate();
  const [dialogFilters, setDialogFilters] = useState(filters);

  useEffect(() => {
    if (isOpen) {
      setDialogFilters(filters);
    }
  }, [isOpen]);

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    setDialogFilters((prevState) => ({...prevState, category: value}));
  };
  const handleChangeDate = (name: 'from' | 'to') => (date: Date | null) => {
    setDialogFilters((prevState) => ({...prevState, [name]: date}));
  };
  const handleClearDate = () => {
    setDialogFilters((prevState) => ({...prevState, ...INITIAL_DATES}));
  };

  const handleClearFilters = () => {
    setDialogFilters(INITIAL_FILTERS);
  };

  const handleApply = () => {
    onClose();
    onUpdateFilters(dialogFilters);
  };

  return (
    <FiltersDialogContainer
      isOpen={isOpen}
      title={translate('FILTER')}
      onClose={onClose}
      onApply={handleApply}
      onClear={handleClearFilters}
    >
      <Stack gap={24}>
        <SelectControl
          label={translate('CATEGORY')}
          placeholder={translate('SELECT')}
          value={dialogFilters.category || ''}
          onChange={handleChangeCategory}
          renderValue={(val: string) => translate(val as TKeys<'storage'>)}
        >
          {getCategoryOptions(translate).map((option) => (
            <Option key={option.label} value={option.value}>
              <ListItemText primary={option.label} />
            </Option>
          ))}
        </SelectControl>
        <DatePickerRange
          sx={{mb: '0 !important'}}
          isIconVisible={false}
          variant="outlined"
          label={translate('DATE_RANGE')}
          startDate={dialogFilters.from}
          endDate={dialogFilters.to}
          setStartDate={handleChangeDate('from')}
          setEndDate={handleChangeDate('to')}
          clearDate={handleClearDate}
          labelTop
          startRangeWithCurrentMonth
        />
      </Stack>
    </FiltersDialogContainer>
  );
};
