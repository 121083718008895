// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {DatePickerRange, FilterWrapper, SelectLabels} from '@src/components';
import Grid from '@src/components/Grid';
import {BoxPaddings} from '@src/components/WhiteBox';
import {IFilterResults} from '@src/pages/Results/Results.constant';
import {useMQuery} from '@src/shared/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

import {sx} from '../styled';
import {getPhysiciansProps} from '../types';

interface ResultsFilterTableProps {
  selectItem: string
  filter: IFilterResults
  changeSelectPhysicians: (event: SelectChangeEvent<string>) => void
  getPhysicians: getPhysiciansProps[]
  changeDate: (value: string) => void
  onClearReportDate: () => void
  onClearCollectionDate: () => void
  onClear: () => void
}

export const ResultsFilterTable: FC<ResultsFilterTableProps> = ({changeSelectPhysicians, getPhysicians, changeDate, onClearReportDate, onClearCollectionDate, onClear, filter, selectItem}) => {
  const {t} = useTranslate('results');
  const {xl} = useMQuery();

  const rowFilter = {
    display: 'flex',
    flexDirection: xl ? 'column' : 'row',
    gap: 4,
  };

  const inputTitlePosition = xl ? 'flex-start' : 'center';

  return (
    <BoxPaddings noBottom noTop sx={sx.boxContainer}>
      <FilterWrapper
        onClear={onClear}
        isMotionButton={true}
        css={_`label {flex: unset; width: 100%; max-width: unset} `}
      >
        <Grid
          container
          spacing={36}
          flexGrow={1}
          sx={{padding: '16px 0 24px 18px'}}>
          <Grid
            xs={4}
            lg={4}
            xl={4}
            sx={rowFilter}
          >
            <Stack justifyContent={inputTitlePosition}>
              <Typography variant='14_18_700' width={75}>{t('PHYSICIAN')}</Typography>
            </Stack>
            <SelectLabels
              value={selectItem}
              placeholder={t('SELECT')}
              onChange={changeSelectPhysicians}
              options={getPhysicians || []}
            />
          </Grid>
          <Grid
            xs={4}
            lg={4}
            xl={4}
            sx={rowFilter}
          >
            <Stack justifyContent={inputTitlePosition}>
              <Typography variant='14_18_700' width={95}>{t('REPORT_DATE')}</Typography>
            </Stack>
            <DatePickerRange
              startAdornmentName="Report date"
              variant="outlined"
              startDate={filter.reportDateFrom}
              setStartDate={changeDate('reportDateFrom')}
              setEndDate={changeDate('reportDateTo')}
              endDate={filter.reportDateTo}
              clearDate={onClearReportDate}
              isIconVisible={false}
            />
          </Grid>
          <Grid
            xs={4}
            lg={4}
            xl={4}
            sx={rowFilter}
          >
            <Stack justifyContent={inputTitlePosition}>
              <Typography variant='14_18_700' width={120}>{t('COLLECTION_DATE')}</Typography>
            </Stack>
            <DatePickerRange
              startAdornmentName="Collection date"
              variant="outlined"
              startDate={filter.collectionDateFrom}
              setStartDate={changeDate('collectionDateFrom')}
              setEndDate={changeDate('collectionDateTo')}
              endDate={filter.collectionDateTo}
              clearDate={onClearCollectionDate}
              isIconVisible={false}
            />
          </Grid>
        </Grid>
      </FilterWrapper>
    </BoxPaddings>
  );
};
