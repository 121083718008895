import {Typography, Stack} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as IconSVGWarning} from '@src/shared/assets/images/icons/icon_warning_yellow.svg';
import {paletteColors} from '@src/theme';
import {FC} from 'react';

import {PulseLoader} from '../PulseLoader';

import {sx} from './styled';
import {IEmptySearchProps} from './types';

export const EmptySearch: FC<IEmptySearchProps> = ({isLoading, isNew}) => {
  const {t} = useTranslate('common');
  if (isNew) {
    return (
      <Stack sx={sx.newStackWrapper} justifyContent={isLoading ? 'center' : 'start'}>
        {
          isLoading
            ? <PulseLoader loading={true} />
            : (
              <>
                <Typography variant='14_18_500' color={paletteColors.grey[400]}>
                  {t('NO_SUITABLE_RESULTS')}
                </Typography>
              </>
            )
        }
      </Stack >
    );
  }

  return (
    <Stack
      sx={sx.stackWrapper}
    >
      {isLoading
        ? <PulseLoader loading={true} />
        : (
          <>
            <IconSVGWarning />
            <Typography fontSize="14px" fontWeight={500}>
              {t('THERE_ARE_NO')}
            </Typography>
          </>
        )
      }
    </Stack>
  );
};
