import {Typography} from '@mui/material';
import {DialogTitle, Dialog, DialogContent, DialogActions} from '@src/components/Dialog';
import {TranslationKeys} from '@src/i18n/TranslationKeys';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {InsuranceDeleteDialogProps} from './types';

export const InsuranceDeleteDialog: FC<InsuranceDeleteDialogProps> = ({onRemove, isOpen, isDeleting, onClose, translate}) => {
  const {t} = useTranslate(translate as keyof TranslationKeys);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      size='sm'
      border='none'
    >
      <DialogTitle onClose={onClose} width='66px'>
        {t('DELETE_INSURANCE' as TKeys)}
      </DialogTitle>
      <DialogContent sx={sx.dialogContent}>
        <Typography variant='16_20_500'>{t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_INSURANCE' as TKeys)}</Typography>
      </DialogContent>
      <DialogActions sx={sx.dialogActions} spaceBetween>
        <Button
          variant='outlined'
          disabled={isDeleting}
          color='secondary'
          onClick={onClose}
        >{t('CANCEL')}</Button>
        <Button
          variant='contained'
          disabled={isDeleting}
          color='secondary'
          onClick={onRemove}
        >{t('DELETE')}</Button>
      </DialogActions>
    </Dialog>
  );
};
