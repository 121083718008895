import {Box, Stack} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {RelationContactsEdit} from '../RelationEditLayouts/RelationContactsEdit/RelationContactsEdit';
import {isShowButtonEdit} from '../helpers';

import {RelationContactsWithAccess} from './RelationContactsWithAccess';
import {RelationContactsWithoutAccess} from './RelationContactsWithoutAccess';
import {sx} from './styles';

export const RelationContacts = () => {
  const [isEditing, setIsEditing] = useState(false);

  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);

  const isAccesses = relation?.accesses?.length;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <Stack sx={sx.container}>
      {!isEditing && isAccesses
        ? <RelationContactsWithAccess relation={relation} fetching={fetching} />
        : <RelationContactsWithoutAccess relation={relation} fetching={fetching} />
      }
      {
        !isEditing && isShowButtonEdit({relation}) && (
          <Box sx={sx.buttonsContainer}>
            <Button
              fullWidth={isMobile}
              variant='outlined'
              color='primary'
              onClick={handleEditClick}>
              {
                relation?.actualAddresses?.main
                  ? t('EDIT')
                  : t('ADD')
              }
            </Button>
          </Box>
        )}
      {isEditing && <RelationContactsEdit handleCancelClick={handleCancelClick}/>}
    </Stack>
  );
};
