import {Box, Stack, Typography} from '@mui/material';
import {RelationProfile} from '@src/api/relations';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as InfoIcon} from '@src/shared/assets/icons/info-without-background.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getName} from '@src/shared/utils/getName';

import {RenderEmails, RenderPhones, hasMainItems, hasOptionalItems} from '../../../helpers/RenderPhonesAndEmails';
import {calcMarginTop} from '../styled';

import {sx} from './styles';

export const RelationContactsWithoutAccess = ({relation, fetching}: { relation: RelationProfile | null, fetching: boolean }) => {
  const {t} = useTranslate('connections');
  const {mobile} = useMQuery();

  const isPhone = relation?.phones?.length;
  const isEmail = relation?.emails?.length;

  if (fetching) {
    return (
      <Box sx={sx.fetchingBlock}>
        <PulseLoader loading />
      </Box>
    );
  }

  return (
    <>
      <Stack sx={sx.container}>
        <Stack sx={sx.upperBlockContainer}>
          <Box>
            <Grid container columnSpacing={24} gap={mobile ? 24 : 0}>
              <Grid
                gap={24}
                xs={12}
                lg={6}
                sm={6}
                md={6}
                xl={6}>
                <Stack>
                  <Typography component='h4' sx={sx.mainTitle}>{t('PHONE_LIST')}</Typography>
                  {
                    isPhone
                      ? (
                        <>
                          <Stack>
                            {hasMainItems(relation?.phones) && (
                              <Typography sx={sx.phoneAndEmailTitleMain}>{t('MAIN')}</Typography>
                            )}
                            <RenderPhones phones={relation?.phones} isMainPhone />
                          </Stack>
                          <Stack>
                            {
                              hasOptionalItems(relation?.phones) && (
                                <Typography sx={sx.phoneAndEmailTitleOptional} mt={calcMarginTop(relation?.phones)}>
                                  {t('OPTIONAL')}
                                </Typography>
                              )}
                            <RenderPhones phones={relation?.phones} isMainPhone={false} />
                          </Stack>
                        </>
                      )

                      : (
                        <Typography sx={sx.notSpecifiedText}>
                          {t('NOT_SPECIFIED')}
                        </Typography>
                      )
                  }
                </Stack>
              </Grid>
              <Grid
                xs={12}
                lg={6}
                sm={6}
                md={6}
                xl={6}>
                <Stack>
                  <Typography
                    component='h4'
                    sx={sx.mainTitle}
                    mb={36}>{t('EMAIL_LIST')}</Typography>
                  {
                    isEmail
                      ? (
                        <>
                          <Stack>
                            {hasMainItems(relation?.emails) && (
                              <Typography sx={sx.phoneAndEmailTitleMain}>{t('MAIN')}</Typography>
                            )}
                            <RenderEmails emails={relation?.emails} isMainEmail />
                          </Stack>
                          <Stack>
                            {
                              hasOptionalItems(relation?.emails) && (
                                <Typography
                                  sx={sx.phoneAndEmailTitleOptional}
                                  mt={calcMarginTop(relation?.phones)}>
                                  {t('OPTIONAL')}
                                </Typography>
                              )}
                            <RenderEmails emails={relation?.emails} isMainEmail={false} />
                          </Stack>
                        </>
                      )
                      : (
                        <Typography sx={sx.notSpecifiedText}>
                          {t('NOT_SPECIFIED')}
                        </Typography>
                      )
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={sx.boxInfoContainer}>
            <InfoIcon />
            <Typography sx={sx.editInfoText}>
              {t('YOU_CANT_EDIT_THIS_INFORMATION', {name: getName(relation?.contact, {short: true})})}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
