import {Avatar, Box, Stack, Typography} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Add} from '@src/shared/assets/icons/primary-plus-sm.svg';
import {useColumnsNumber} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {createRelationPath, getUserInitials} from '@src/shared/utils';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useAccountProfile} from '@src/store/account/hooks';
import {useContactRelations} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {RequestsContextType, RequestsType} from '@src/types';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMatch, useNavigate, useOutletContext} from 'react-router-dom';
import {Button} from 'ui-kit';

import {Requests} from '../Requests';

import {calculateGap} from './helpers';
import {checkIfPearsonYounger18} from './helpers/checkIfPearsonYounger18';
import {sx} from './styles';

export const Relations = () => {
  const {t, ready} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet, desktop: isDesktop} = useMQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRequestsBlockOpen, setIsRequestsBlockOpen] = useState(true);

  const {isIncomingRequest, setIsIncomingRequest} = useOutletContext<RequestsContextType>();
  const {groupedRelations, fetching} = useContactRelations('');
  const {accountProfile} = useAccountProfile();

  const relationsPage = useMatch(ROUTERS_PATH.CONNECTIONS_RELATIONS);
  const breakpoints = {
    xxl: 1297,
    xl: 989,
    lg: 648,
    sm: 624,
    md: 936,
  };

  const defaultColumns = {
    xxl: 4,
    xl: 4,
    lg: 6,
    sm: 6,
    md: 4,
  };

  const customColumns = {
    xxl: 3,
    xl: 6,
    lg: 12,
    sm: 12,
    md: 6,
  };
  const {colsNumberXxl, colsNumberXl, colsNumberLg, colsNumberMd, colsNumberSm, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  const currentPersonAge = accountProfile?.contact?.birthDate;
  const isPersonOlder18 = !currentPersonAge ? true : checkIfPearsonYounger18(currentPersonAge);

  const isShowBtnAddRelation = !isMobile && !!groupedRelations?.length && isPersonOlder18;
  const isShowBtnAddRelationEmpty = !isMobile && isPersonOlder18 && !fetching;
  const isShowBtnAddRelationMobile = isMobile && relationsPage && isPersonOlder18;

  useEffect(() => {
    setIsRequestsBlockOpen(!isTablet);
  }, [isTablet]);

  if (!ready) {
    return null;
  }

  return (
    <Stack height="100%">
      {isMobile && (
        <Requests
          isIncomingRequest={isIncomingRequest}
          setIsIncomingRequest={setIsIncomingRequest}
          setIsRequestsBlockOpen={setIsRequestsBlockOpen}
          isRequestsBlockOpen={isRequestsBlockOpen}
          requestsType={RequestsType.Relation}
        />
      )}
      <Stack sx={sx.container} flexDirection={{xs: 'column', sm: 'row'}}>
        {!isMobile && (
          <Requests
            isIncomingRequest={isIncomingRequest}
            setIsIncomingRequest={setIsIncomingRequest}
            setIsRequestsBlockOpen={setIsRequestsBlockOpen}
            isRequestsBlockOpen={isRequestsBlockOpen}
            requestsType={RequestsType.Relation}
          />
        )}
        <Stack sx={[...spreadSx(sx.wrapper), {overflowY: isRequestsBlockOpen && !isDesktop ? 'hidden' : 'auto'}]}>
          {!groupedRelations?.length && (
            <Stack>
              <Typography sx={sx.emptyDataInformation}>
                {t('NO_RELATIONSHIPS')}
              </Typography>
              {isShowBtnAddRelationEmpty && (
                <Box>
                  <Button
                    onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                    startIcon={<Add />}
                    variant="outlined"
                    color="primary"
                  >
                    {t('ADD_RELATION')}
                  </Button>
                </Box>
              )}
            </Stack>
          )}
          {fetching && (
            <Box sx={sx.fetchingBlock}>
              <PulseLoader loading />
            </Box>
          )}
          {!fetching && (
            <Box sx={sx.boxContainer}>
              <Grid
                ref={containerRef}
                container
                spacing={{xs: 12, lg: 24}}
                width={isMobile ? 'initial' : '100%'}>
                {groupedRelations?.map((person) => (
                  <Grid
                    xs={12}
                    sm={colsNumberSm}
                    md={colsNumberMd}
                    lg={colsNumberLg}
                    xl={colsNumberXl}
                    xxl={colsNumberXxl}
                    key={person?.id}
                  >
                    <Stack sx={sx.contentContainer}>
                      <Box
                        sx={sx.content}
                        onClick={() => {
                          person.contact.id &&
                          navigate(createRelationPath(isMobile, person.contact.id));
                          dispatch(relationsActions.setRelation({relation: null}));
                        }}>
                        <Avatar sx={sx.avatar} src={person?.avatarUrl} alt={''}>
                          <Typography variant={isMobile ? '14_18_700' : '24_28_600'}>{getUserInitials(person?.contact)}</Typography>
                        </Avatar>
                        <Stack sx={sx.personInfoWrapper} gap={calculateGap({birthDate: person?.contact?.birthDate, isMobile})}>
                          <Stack sx={sx.personInfo}>
                            <EllipsisTextTooltip
                              variant={isMobile ? '14_18_700' : '18_24_700'}
                              ellipsisText={person?.contact.firstName}
                              sx={sx.personName}
                            />
                            <EllipsisTextTooltip
                              variant={isMobile ? '14_18_700' : '18_24_700'}
                              ellipsisText={person?.contact.lastName}
                              sx={sx.personName}
                            />
                            <Typography sx={sx.personBirthDay}>
                              {person?.contact?.birthDate}
                            </Typography>
                          </Stack>
                          <EllipsisTextTooltip
                            ellipsisText={person?.types ? person.types.map((type: TKeys<'connections'>) => t(type)).join(', ') : ''}
                            sx={sx.personType}
                            variant={isMobile ? '12_24_500' : '14_24_500'}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {isShowBtnAddRelation && (
            <Box sx={sx.addRelationButton}>
              <Button
                onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                startIcon={<Add />}
                variant="outlined"
                color="primary"
              >
                {t('ADD_RELATION')}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
      {isShowBtnAddRelationMobile && (
        <WBox sx={sx.addRelationButtonOnMobile}>
          <Button onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}>
            {t('ADD_RELATION')}
          </Button>
        </WBox>
      )}
    </Stack>
  );
};
