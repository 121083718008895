import {ChangeEvent} from 'react';

export const addressOnClear = (
  e: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
) => {
  if (!e.target?.value) {
    setFieldValue('address1', null, true);
    setFieldValue('address2', null, false);
    setFieldValue('state', null, false);
    setFieldValue('postalCode', null, false);
    setFieldValue('city', null, false);
    setFieldValue('location', null, false);
    setFieldValue('streetNumber', null, true);
  };
};
