import {useTranslate} from '@src/i18n/useTranslate';
import * as Yup from 'yup';

export const useValidationSchema = () => {
  const {t} = useTranslate('connections');

  return Yup.object({
    address1: Yup.string()
      .nullable()
      .test('isEmptyAddress', t('ENTER_VALID_ADDRESS'), function (value) {
        return !!(value && value.trim().length > 0);
      }),

    streetNumber: Yup.string()
      .nullable()
      .test('is-clickToOption', t('ENTER_VALID_ADDRESS'), function () {
        if (!this.parent.address1 || this.parent.address1.trim().length === 0) {
          return true;
        }

        return Boolean(this.parent.location?.googlePlaceId);
      })
      .test('is-addressStreetNumber', t('PLEASE_PROVIDE_THE_BUILDING_ADDRESS'), function (value) {
        if (!value && this.parent.address1) {
          return false;
        }
        return true;
      }),

    address2: Yup.string().nullable(),
  });
};
