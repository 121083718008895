import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  tipContainer: {
    padding: {sm: '18px 24px 6px 24px', xs: '18px 18px 6px 18px'},
  },
  mainContainer: {
    padding: 24,
    flex: 1,
    height: 0,
  },
  documentsList: (t) => ({
    display: 'flex',
    flexDirection: 'row',
    rowGap: 42,
    columnGap: {sm: 42, xs: 24},
    flexWrap: 'wrap',
    backgroundColor: t.colors.all.grey6,
    padding: {sm: 24, xs: 12},
    height: {
      lg: 490,
      sm: `calc(100vh - ${t.header.height.sm}px)`,
      md: `calc(100vh - ${t.header.height.md}px)`,
    },
    justifyContent: {xs: 'space-between', lg: 'unset'},
  }),
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBox: {
    alignSelf: 'self-start',
    padding: 0,
    pt: 2,
  },
  actionButtons: theme => ({
    flexDirection: {sm: 'row', xs: 'column-reverse'},
    gap: 12,
    justifyContent: 'space-between',
    p: {sm: 24, xs: 18},
    borderTop: '1px solid',
    borderColor: (theme) => theme.colors.borderBlueLight,
    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.common.white,
    },
  }),
  image: {
    width: 148,
    height: 193,
  },
  listImage: {
    width: {sm: 152, xs: 117},
    height: 152,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileName: {
    width: {sm: 152, xs: 117},
    whiteSpace: 'wrap',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  filesListCard: {
    flexDirection: 'row',
    gap: 12,
    cursor: 'pointer',
  },
  soloFileName: {
    whiteSpace: 'wrap',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
  },
  mobileMainWrapper: {
    padding: 18,
    flex: 1,
    height: 200,
    maxHeight: {
      xs: 'calc(100% - 133px - 50px - 44px)',
      sm: 'unset',
    },
  },
};
