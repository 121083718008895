import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    flexGrow: 1,
    boxSizing: 'border-box',
    border: '1px solid',
    width: 'calc(100vw - 602px)',
    borderColor: alpha(t.palette.secondary.main, 14),
    '.tablet-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 36,
      border: 'unset',
    },
    '.mobile-layout &': {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      border: 'unset',
    },
  }),
  topContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'initial',
    flexGrow: 1,
    padding: 24,
    paddingBottom: 0,
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
      flexGrow: 'initial',
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
      flexGrow: 'initial',
    },
  },
  container: {
    gap: 24,
    '.tablet-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
    '.mobile-layout &': {
      flexDirection: 'column',
      alignItems: 'initial',
      padding: 0,
      gap: 24,
    },
  },
  testsSelect: {
    label: {
      flex: 'unset',
    },
  },
  mobileDialogContainer: t => ({
    [t.breakpoints.down('sm')]: {
      mt: 24,
    },
  }),
};
