import {Box, Typography, SxProps, Theme, useTheme} from '@mui/material';
import {Dl, TableRowProps} from '@src/components';
import {PopoverTooltip, usePopoverTooltip} from '@src/components/PopoverTooltip';
import {TableHeaderItem} from '@src/components/Table/types';
import {Accordion, AccordionDetails, AccordionSummary} from '@src/pages/Details/StyledAccordionSummary/StyledAccordionSummary';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {DATE_FORMAT} from '@src/shared/utils';
import {getCriticalColor} from '@src/shared/utils/results';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {initialColors} from '@src/theme';
import {alpha} from '@src/theme/utils';
import {format} from 'date-fns';
import {useTranslate} from 'i18n/useTranslate';
import {DateTime, Interval} from 'luxon';
import {FC} from 'react';
import {DynamicTestTransformed} from 'store/results/helpers';

import {generateRefText} from '../Dynamics.helper';

import {ReactComponent as Critical} from './images/critical.svg';

type TRowTableProps = TableRowProps<DynamicTestTransformed> & {
  headers: TableHeaderItem[]
  start?: Date
  end?: Date
};

const defaultStyles: Partial<Record<string, SxProps<Theme>>> = {
  accordion: (t) => ({
    borderBottom: `1px solid ${t.colors.borderLight}`,
    padding: {xs: 0, lg: '0 36px'},
  }),
  accordionSummary: {
    borderBottom: 'none',
    padding: 0,
    '&.Mui-expanded': {
      borderBottom: 'none',
    },
  },
};

export const Cell: FC<{
  value?: string | null
  comment: string
  color: string
  isCriticalRefMark?: boolean
}> = ({value, comment, color, isCriticalRefMark}) => {
  const {onOpen: handleClick, ...forPopover} = usePopoverTooltip(comment);
  return (
    <div>
      <Box onClick={handleClick} sx={{color, display: 'flex', alignItems: 'center'}}>
        <Typography variant={'14_16_500'}>{value}</Typography>
        {isCriticalRefMark && <Critical style={{marginLeft: '8px'}} />}
      </Box>
      <PopoverTooltip {...forPopover} header>
        {comment}
      </PopoverTooltip>
    </div>
  );
};

export const TableRow: FC<TRowTableProps> = ({
  className,
  data,
  gridTemplateColumns,
  headers,
  start,
  end,
  sx,
}) => {
  const {resultLaboratoryAnalyses, testName, refMax, refMin, refText, unit} = data;
  const {mobile, tablet} = useMQuery();
  const start_ = start || new Date(0);
  const end_ = end || new Date();
  const t = useTheme();
  const interval = Interval.fromDateTimes(start_, end_);
  const filteredData = resultLaboratoryAnalyses.filter((result) =>
    interval.contains(DateTime.fromISO(result.biomaterialSamplingDate)),
  );
  if (mobile || tablet) {
    return (
      <Accordion sx={[...spreadSx(defaultStyles.accordion), sx]}>
        <AccordionSummary sx={defaultStyles.accordionSummary}>
          <Box pl={'10px'}>{testName || '--'}</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pl={{
              xs: 60,
              sm: 56,
              md: 70,
            }}
            paddingY={'14px'}
          >
            <Dl>
              <dt>{headers[0]?.name}</dt>
              <dd>{testName}</dd>
            </Dl>
            <Dl>
              <dt>{headers[1]?.name}</dt>
              <dd>{unit}</dd>
            </Dl>
            <Dl>
              <dt>{headers[2]?.name}</dt>
              <dd>{generateRefText({refMax, refMin, refText})}</dd>
            </Dl>
            {filteredData
              .map((data, index: number) => {
                const {value, comment, isCriticalRefMark} = data;

                const criticalColor = getCriticalColor(data, t);
                if (!value) {
                  return null;
                }
                return (
                  <Dl key={`${index}/${value}`}>
                    <dt>{headers[index + 3]?.name}</dt>
                    <dd>
                      <Cell
                        value={value}
                        comment={comment}
                        color={criticalColor}
                        isCriticalRefMark={isCriticalRefMark}
                      />
                    </dd>
                  </Dl>
                );
              })
              .filter(Boolean)}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box
      className={className}
      sx={{
        div: {height: 22},
        gridTemplateColumns,
        height: 44,
        padding: '0 40px',
        border: `1px solid ${mobile ? initialColors.blueLight : initialColors.white}`,
        '&:hover': {backgroundColor: alpha(t.palette.secondary.main, 7)},
        ...sx,
      }}
    >
      <div>{testName}</div>
      <div>{unit}</div>
      <div>{generateRefText({refMax, refMin, refText})}</div>
      {filteredData.map((data, index: number) => {
        const {value, comment, isCriticalRefMark} = data;
        const criticalColor = getCriticalColor(data, t);

        return (
          <Cell
            value={value}
            key={`${index}/${value ?? ''}`}
            comment={comment}
            color={criticalColor}
            isCriticalRefMark={isCriticalRefMark}
          />
        );
      })}
    </Box>
  );
};

export const useHeader = (data: DynamicTestTransformed[], start: any, end: any) => {
  const {t} = useTranslate('details');
  const dateItems = data
    ? data?.[0]?.resultLaboratoryAnalyses?.map(
      ((d) => ({
        name: format(
          new Date((d.biomaterialSamplingDate || 0) as string | number | Date),
          DATE_FORMAT,
        ),
      })) || [],
    )
    : [];
  const filterDates =
    start || end
      ? dateItems?.filter((date: any) =>
        dateInInterval(
          start as Date | null,
          end as Date | null,
          date.name as string | Date | null | undefined,
        ),
      )
      : dateItems;

  const HEADER_LIST: TableHeaderItem[] = [
    {name: t('TEST')},
    {name: t('UNITS')},
    {name: t('REFERENCE_RANGE')},
    ...(filterDates || []),
  ];

  const columnLength = new Array(filterDates ? filterDates.length : 0).fill('130px');
  const gridTemplateColumns = `280px 160px 220px ${columnLength.join(' ')}`;
  return {HEADER_LIST, gridTemplateColumns};
};
