import {TableHeaderItem} from '@src/components/Table/types';
import {useTranslate} from 'i18n/useTranslate';

export const CRITICAL_FLAG = 'CRITICAL';

export const useHeader = () => {
  const {t} = useTranslate('results');
  const HEADER_LIST: TableHeaderItem[] = [
    {
      name: t('TESTS_PANEL'),
    },
  ];
  return {HEADER_LIST};
};
