import {ErrorsProps} from "@src/types/ErrorsProps";

export const getPolicyIdError = ({touched, values, errors, t}: ErrorsProps) => {
  if (touched?.policyId && errors.policyId) {
    return errors.policyId;
  }
  if (touched?.policyId && !values.policyId) {
    return errors.policyId;
  }
  if (t && errors.policyId === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')) {
    return errors.policyId;
  }
  return '';
};
