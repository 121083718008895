// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Typography, css} from '@mui/material';
import {InputControl} from '@src/components/form/InputControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as SuccessIcon} from '@src/shared/assets/icons/success.svg';
import {useMQuery} from '@src/shared/hooks';
import {Steps} from '@src/types';
import React, {FC, useEffect, useState} from 'react';
import {Button} from 'ui-kit';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../Dialog';

import {sx} from './styles';
import {ISendResultProps} from './types';

export const SendResult: FC<ISendResultProps> = ({onSubmit, step, close}) => {
  const {t} = useTranslate('details');
  const {mobile: isMobile} = useMQuery();

  const [email, setEmail] = useState(() => localStorage.getItem('email') || '');
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const isModalOpened = step === Steps.SUCCESS || step === Steps.ERROR;
  const modalSize = isMobile ? 'xs' : '402px';

  const toggleSendResultWindow = () => {
    setIsOpen((prev) => !prev);
    setIsError(false);
  };

  const closeModal = () => {
    close();
    localStorage.removeItem('email');
    setEmail('');
  };

  const handleChangeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    localStorage.setItem('email', value);
  };

  const toggleConfirm = () => {
    if (isOpen) {
      const reg = /\S+@\S+\.\S+/;
      if (reg.test(email)) {
        onSubmit(email);
        setIsError(false);
        toggleSendResultWindow();
        return;
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('email');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Button
        onClick={toggleSendResultWindow}
        color="secondary"
        variant="contained"
        size="lg">
        {t('SEND')}
      </Button>
      <Dialog
        open={isOpen}
        title="Send Results"
        onClose={toggleSendResultWindow}
        sx={{backgroundColor: 'rgb(55 65 81 / 50%)'}}
        size={modalSize}
      >
        <DialogTitle onClose={toggleSendResultWindow}>{t('SEND_RESULTS')}</DialogTitle>
        <DialogContent>
          <InputControl
            placeholder="example@domain.com"
            label={t('BY_EMAIL')}
            css={(t) => css`
                margin-bottom: -20px;
                position: relative;
                label {
                  position: absolute;
                  top: 3px;
                  z-index: 100;
                  font-weight: 700;
                }
                div {
                  padding-left: 65px;
                  border-bottom-color: ${t.palette.grey[300]};
                }
              `}
            error={isError ? t('INCORRECT_EMAIL') : ''}
            onChange={handleChangeMail}
            value={email}
          />
        </DialogContent>
        <DialogActions
          sx={{flexDirection: 'column'}}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleSendResultWindow}
            fullWidth
            sx={{flexGrow: 'inherit !important'}}
          >
            {t('CANCEL')}
          </Button>
          <Button
            fullWidth
            sx={{flexGrow: 'inherit !important'}}
            variant="contained"
            color="secondary"
            onClick={toggleConfirm}
          >
            {t('SUBMIT')}
          </Button>
        </DialogActions>
      </Dialog>
      {step === Steps.ERROR && (
        <Dialog
          sx={sx.dialogError}
          open={isModalOpened}
          onClose={closeModal}
          size={modalSize}>
          <DialogTitle
            width='64px'
            onClose={closeModal}
            noContent={false}
            sx={sx.dialogTitleError}>
            <ErrorIcon />
            {t('ERROR')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">
              {t('RESULT_HAS_NOT_BEEN_SUCCESSFULLY_SENT', {email})}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={sx.dialogActionsError}
            onClose={closeModal}
          >
            <Button
              fullWidth={isMobile}
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeModal}>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {step === Steps.SUCCESS && (
        <Dialog open={isModalOpened} onClose={closeModal} size={modalSize}>
          <DialogTitle
            width='64px'
            sx={sx.dialogTitleSuccess}
            onClose={closeModal}
            noContent={false}>
            <SuccessIcon />
            {t('SUCCESS')}
          </DialogTitle>
          <DialogContent sx={sx.confirmDeleteContent}>
            <Typography variant="14_18_500">
              {t('RESULT_HAS_BEEN_SUCCESSFULLY_SENT', {email})}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={sx.dialogActionsSuccess}
            onClose={closeModal}
          >
            <Button
              fullWidth={isMobile}
              sx={sx.bth}
              variant="contained"
              color="inherit"
              onClick={closeModal}>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
