/* eslint-disable no-comments/disallowComments */
import {HealthcareInsurancePolicy, Profile, Result} from '@src/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ClassRef = new (...args: any[]) => unknown;

export type Nullable<T> = T | null;

export type Possible<T> = T | undefined;

export type RequireOne<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & {
  [P in K]-?: T[P];
};

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type OptionalExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P>
  ? P
  : never;

export type Gender = 'FEMALE' | 'MALE' | 'UNKNOWN';

/** ISO date format */
export type ISODate = string;

/** Раса */
export type Race =
  | 'AmericanIndianOrAlaskaNative'
  | 'Asian'
  | 'BlackOrAfricanAmerican'
  | 'NativeHawaiianOrOtherPacificIslander'
  | 'White'
  | 'OtherRace'
  | 'Asku'
  | 'Unknown';

export type Ethnicity = 'HispanicOrLatino' | 'NotHispanicOrLatino';

/**
 * SLF : Self
 * GRP : Grandparent
 * GCH : Grandchild
 * FCH : Foster Child
 * WRD : Ward of the Court
 * CHD : Child
 * DEP : Handicapped/Dependent
 * MTH : Mother
 * FTH : Father
 * OTH : Other
 * EMC : Emergency contact
 */
export type Relationship =
  | 'SLF'
  | 'GRP'
  | 'GCH'
  | 'FCH'
  | 'WRD'
  | 'CHD'
  | 'DEP'
  | 'MTH'
  | 'FTH'
  | 'OTH'
  | 'EMC';

export type Priority = 'Stat' | 'Routine';

export type CollectionType = 'InHouse' | 'PSC' | 'HouseCall';

export type BillingType = 'DrawNow' | 'DrawLater';

export type OrderStatus =
  | 'Draft'
  | 'Creating' // шаги с patient по review
  | 'Created' // после review
  | 'Collected' // после collection
  | 'New' // статусы ниже пока не используются
  | 'InProgress'
  | 'InWork'
  | 'Completed';

export enum ValidationErrorType {
  ACCOUNT_NPI_EXISTS = 'ACCOUNT_NPI_EXISTS',
  ACCOUNT_PHONE_EXISTS = 'ACCOUNT_PHONE_EXISTS',
  COMPANY_NPI_EXISTS = 'COMPANY_NPI_EXISTS',
  NPI_VALIDATION_ERROR = 'NPI_VALIDATION_ERROR',
  PHONE_VALIDATION_ERROR = 'PHONE_VALIDATION_ERROR',
  ROLE_COMPANY_MODULE_EXISTS = 'ROLE_COMPANY_MODULE_EXISTS',
  BIRTHDATE_VALIDATION_ERROR = 'BIRTHDATE_VALIDATION_ERROR',
  NPI_VERIFICATION_EXISTS = 'NPI_VERIFICATION_EXISTS',
  CODE_VALIDATION_ERROR = 'CODE_VALIDATION_ERROR',
  RECAPTCHA_VALIDATION_ERROR = 'RECAPTCHA_VALIDATION_ERROR',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  OWNER_ROLE_NOT_FOUND = 'OWNER_ROLE_NOT_FOUND',
  DEFAULT_ROLE_NOT_FOUND = 'DEFAULT_ROLE_NOT_FOUND',
  MODULE_NOT_FOUND = 'MODULE_NOT_FOUND',
  COMPANY_MODULE_NOT_FOUND = 'COMPANY_MODULE_NOT_FOUND',
  PERMISSIONS_NOT_FOUND = 'PERMISSIONS_NOT_FOUND',
  CURRENT_PASSWORD_INCORRECT = 'CURRENT_PASSWORD_INCORRECT',
  NEW_PASSWORD_EQUALS_CURRENT_PASSWORD = 'NEW_PASSWORD_EQUALS_CURRENT_PASSWORD',
  MODIFICATION_FORBIDDEN = 'MODIFICATION_FORBIDDEN',
  PHONE_NOT_FOUND = 'PHONE_NOT_FOUND',
  MOBILE_PHONE_REQUIRED = 'MOBILE_PHONE_REQUIRED',
  REMOVE_MAIN_PHONE = 'REMOVE_MAIN_PHONE',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_CONFIRMATION_TOKEN_ERROR = 'EMAIL_CONFIRMATION_TOKEN_ERROR',
  REMOVE_MAIN_EMAIL = 'REMOVE_MAIN_EMAIL',
  NPI_TYPE_INCORRECT = 'NPI_TYPE_INCORRECT',
  NPI_TYPE_INCORRECT_INDIVIDUAL = 'NPI_TYPE_INCORRECT_INDIVIDUAL',
  NPI_TYPE_INCORRECT_ORG = 'NPI_TYPE_INCORRECT_ORG',
  NPI_NOT_FOUND = 'NPI_NOT_FOUND',
  NPI_NOT_ACTIVE = 'NPI_NOT_ACTIVE',
  PARENT_NPI_REQUIRED = 'PARENT_NPI_REQUIRED',
  TOO_MANY_NPI_REGISTRATIONS = 'TOO_MANY_NPI_REGISTRATIONS',
  NOT_NULL = 'NOT_NULL',
  NOT_BLANK = 'NOT_BLANK',
  NOT_EMPTY = 'NOT_EMPTY',
  EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR',
  INVALID_FORMAT_ERROR = 'INVALID_FORMAT_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NOT_POSITIVE = 'NOT_POSITIVE',
  NOT_DIGIT_10_0 = 'NOT_DIGIT_10_0',
  MIN_10_MAX_10 = 'MIN_10_MAX_10',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  COMPANY_MODULE_ALREADY_EXISTS = 'COMPANY_MODULE_ALREADY_EXISTS',
  INSUFFICIENT_ACCESS_RIGHTS = 'INSUFFICIENT_ACCESS_RIGHTS',
  ACCOUNT_COMPANY_MODULE_ROLE_EXISTS = 'ACCOUNT_COMPANY_MODULE_ROLE_EXISTS',
  ACCOUNT_EMAIL_EXISTS = 'ACCOUNT_EMAIL_EXISTS',
  INVALID_DOMAIN = 'INVALID_DOMAIN',
  ACCOUNT_COMPANY_INVITE_EXISTS = 'ACCOUNT_COMPANY_INVITE_EXISTS',
  ZIP_CODE_NUMBER_VALIDATION_ERROR = 'ZIP_CODE_NUMBER_VALIDATION_ERROR',
  NOT_URL = 'NOT_URL',
  OPENID_ENDPOINT_UNAVAILABLE = 'OPENID_ENDPOINT_UNAVAILABLE',
  OPENID_ENDPOINT_READ_ERROR = 'OPENID_ENDPOINT_READ_ERROR',
  OPENID_ENDPOINT_METADATA_INVALID = 'OPENID_ENDPOINT_METADATA_INVALID',
  OPENID_ENDPOINT_ERROR = 'OPENID_ENDPOINT_ERROR',
}

export interface ValidationError<T=string> {
  field: T
  error: ValidationErrorType
}

export type UUID = string;

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type ISODateString = string;

export interface OverviewProps {
  accountProfile: Profile | null
  isAccountLoading?: boolean
  data: Result[]
  isResultsLoading: boolean
  isSidebarOpen?: boolean
  insurances: HealthcareInsurancePolicy[]
}

export enum AccountType {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum RequestsType {
  Relation = 'Relation',
  Accesses = 'Accesses',
}

export interface RequestsContextType {
  isIncomingRequest: boolean
  setIsIncomingRequest: React.Dispatch<React.SetStateAction<boolean>>
}

export enum Steps {
  OPEN = 'open',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  INIT = 'init'
};

export enum RelationshipTypes {
  SELF = 'SELF',
  CHD = 'CHD',
  EMC = 'EMC',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  OTH = 'OTH',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
  WRD = 'WRD'
}
