import {HealthcareInsurancePolicy} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {phoneNotRequiredUpdateValidator} from '@src/shared/utils/phoneNotRequiredUpdateValidator';
import {customRequiredValidation, postalCodeValidator} from '@src/shared/utils/validators';
import {differenceInYears} from 'date-fns';
import {isEqual} from 'lodash-es';
import * as Yup from 'yup';

export const useValidationSchema = ({
  isValidateGuarantorEdit,
  accesses,
  insurance,
}: {
  isValidateGuarantorEdit: boolean
  accesses: Record<string, string>
  insurance: HealthcareInsurancePolicy | undefined
}) => {
  const {t} = useTranslate('connections');

  const create = (
    baseValue: string | null | undefined,
    changedValue: string | null | undefined,
  ) => {
    if (!baseValue && changedValue) {
      return true;
    }
    return false;
  };

  const update = (
    baseValue: string | null | undefined,
    changedValue: string | null | undefined,
  ) => {
    if (baseValue && changedValue) {
      return true;
    }
    return false;
  };

  const delete_ = (
    baseValue: string | null | undefined,
    changedValue: string | null | undefined,
  ) => {
    if (baseValue && !changedValue) {
      return true;
    }
    return false;
  };

  const validateField = (
    operations: string,
    baseValue: string | null | undefined,
    changedValue: string | null | undefined,
  ) => {
    if (isEqual(baseValue, changedValue)) return true;

    let valid = false;
    if (operations?.includes('C')) {
      valid = create(baseValue, changedValue);
    }
    if (valid) return valid;
    if (operations?.includes('U')) {
      valid = update(baseValue, changedValue);
    }
    if (valid) return valid;
    if (operations?.includes('D')) {
      valid = delete_(baseValue, changedValue);
    }
    return valid;
  };

  return Yup.object().shape({
    relationship: Yup.string().notRequired().nullable()
      .test('is-relationship', 'Change forbidden', (value) => {
        return isValidateGuarantorEdit ? validateField(accesses.relationType, insurance?.guarantor?.relationType, value) : true;
      },
      ),
    firstName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    lastName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    middleName: Yup.string()
      .nullable()
      .test('is-middleName', 'Change forbidden', (value = null) =>
        isValidateGuarantorEdit ? validateField(accesses.middleName, insurance?.guarantor?.middleName || null, value) : true,
      ),
    birthDate: Yup.string().required(t('REQUIRED_FIELD')).nullable()
      .test('is-birthDate', 'Change forbidden', (value) =>
        validateField(
          accesses.birthDate,
          insurance?.guarantor?.birthDate,
          value,
        ),
      )
      .test('is-birthDate', t('ENTER_A_VALID_DATE_OF_BIRTH'), function(value) {
        if (!value) return true;
        const birthDate = new Date(value);
        return differenceInYears(new Date(), birthDate) >= 18;
      }),
    birthSex: Yup.string().required(t('REQUIRED_FIELD')).nullable()
      .test('is-birthSex', 'Change forbidden', (value) =>
        isValidateGuarantorEdit ? validateField(accesses.birthSex, insurance?.guarantor?.birthSex, value) : true,
      ),
    ssnTail: Yup.string()
      .matches(/^\d{4}?$/, t('MIN_SSN_LENGTH'))
      .nullable()
      .test('is-ssnTail', 'Change forbidden', (value = null) => {
        return isValidateGuarantorEdit ? validateField(accesses.ssnTail, insurance?.guarantor?.ssnTail || null, value) : true;
      },
      ),
    state: Yup.string().required(t('REQUIRED_FIELD')).nullable()
      .test('is-state', 'Change forbidden', (value) =>
        isValidateGuarantorEdit ? validateField(accesses.state, insurance?.guarantor?.state, value) : true,
      ),
    city: Yup.string().required(t('REQUIRED_FIELD')).nullable()
      .test('is-city', 'Change forbidden', (value) =>
        isValidateGuarantorEdit ? validateField(accesses.city, insurance?.guarantor?.city, value) : true,
      ),
    postalCode: postalCodeValidator(t),
    address1: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    address2: Yup.string()
      .nullable()
      .test('is-address2', 'Change forbidden', (value = null) => {
        return isValidateGuarantorEdit ? validateField(accesses.address2, insurance?.guarantor?.additional || null, value) : true;
      },
      ),
    phone: phoneNotRequiredUpdateValidator(t).test('is-phone', 'Change forbidden', (value = null) =>
      isValidateGuarantorEdit ? validateField(accesses.phone, insurance?.guarantor?.phone || null, value) : true,
    ),
    endDate: Yup.string()
      .nullable()
      .test('is-endDate', t('REQUIRED_FIELD'), (value) => {
        return !(typeof insurance?.endDate === 'string' && !value);
      }),
  });
};
