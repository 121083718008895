/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Divider, IconButton, Stack, Typography, useTheme} from '@mui/material';
import {RowInformation} from '@src/components';
import {Icons} from '@src/shared/assets/icons';
import {useMQuery} from '@src/shared/hooks';
import {DateFormat, dateFormatted} from '@src/shared/utils';
import {DynamicResultWithAnalysesData} from '@src/store/results/helpers';
import React, {FC, UIEvent, useRef} from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
} from 'recharts';

import {sx} from './styles';

interface RechartsProps {
  data: DynamicResultWithAnalysesData[]
  domain: { min: number, max: number }
  wrapperClassName?: string
}

interface TtProps extends TooltipProps<string, string> {
  closeTooltip: (e: React.MouseEvent) => void
  isMobile: boolean
}
const CustomTooltip = (props: TtProps) => {
  const {active, payload, closeTooltip} = props;
  if (active && payload && payload.length > 0) {
    const data: DynamicResultWithAnalysesData = payload[0].payload;
    const {value, unit, refText, biomaterialSamplingDate} = data;
    return (
      <Stack sx={sx.tooltipContainer}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height={24}>
          <Typography variant="14_18_700" sx={sx.text}>
            {dateFormatted(biomaterialSamplingDate, DateFormat.DATE_TIME)}
          </Typography>
          {props.isMobile && (
            <IconButton sx={sx.closeButton} onClick={closeTooltip}>
              <Icons.Cross.X24 />
            </IconButton>
          )}
        </Stack>
        <Stack sx={sx.text}>
          <RowInformation value={value} name="Result" />
          <RowInformation value={unit} name="Units" />
          <RowInformation value={refText || '--'} name="Reference Range" />
          <Divider />
        </Stack>
      </Stack>
    );
  }

  return null;
};

const CustomizedDot: FC<any> = (props) => {
  const {cx, cy, value, payload, active} = props;

  if (value == null) return null;
  const referenceColor = payload?.isRefMark ? '#FAB41C' : undefined;
  const criticalColor = payload?.isCriticalRefMark ? '#EA3D5C' : undefined;
  const colorCircle = criticalColor || referenceColor || '#88999E';

  return (
    <svg
      x={cx - 12}
      y={cy - 20}
      width={40}
      height={40}
      fill={colorCircle}
      viewBox="0 0 1024 1024">
      <circle
        cx="300"
        cy="500"
        r="150"
        fill={active ? 'rgba(44, 150, 181,0.5)' : '#fff'} />
      <circle cx="300" cy="500" r="90" />
    </svg>
  );
};

export const Recharts: React.FC<RechartsProps> = ({data, domain, wrapperClassName = 'bar-graph-wrapper'}) => {
  const ref = useRef<any>();
  const {mobile, tabletPortrait, tabletLandscape, lg, xl, xxl} = useMQuery();
  const theme = useTheme();
  const dataWithoutEmpty = data.filter((test) => test.value);
  const closeTooltip = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (ref.current) {
      ref.current.setState({
        isTooltipActive: false,
      });
    }
  };

  const criticalAreaColor = '#F4E3E6';
  const safeAreaColor = '#DFFFE4';
  const outZoneColor = '#FFD7D7';

  const calculateBarchartWidth = () => {
    const numberOfItem = dataWithoutEmpty.length;

    if (mobile && numberOfItem > 4) return numberOfItem * 100;
    if (tabletPortrait && numberOfItem > 6) return numberOfItem * 100;
    if (tabletLandscape && numberOfItem > 6) return numberOfItem * 100;
    if (lg && numberOfItem > 12) return numberOfItem * 100;
    if (xl && numberOfItem > 16) return numberOfItem * 100;
    if (xxl && numberOfItem > 26) return numberOfItem * 100;

    return '100%';
  };

  const handleOnScroll = (e: UIEvent<HTMLDivElement>) => {
    const wrapper = document.querySelector(
      `.${wrapperClassName} .recharts-surface`,
    );
    const graphWrapper = document.querySelector(
      `.${wrapperClassName} .graph-wrapper`,
    );

    const allAxis = document.querySelectorAll(
      `.${wrapperClassName} .recharts-yAxis`,
    );

    const xAxis = document.querySelector(
      `.${wrapperClassName} .recharts-xAxis`,
    );

    const xAxisHeight = xAxis?.getBoundingClientRect().height || 0;

    allAxis?.forEach((axis) => {
      const orientation = axis
        .querySelector(
          `.${wrapperClassName} .recharts-cartesian-axis-tick-line`,
        )
        ?.getAttribute('orientation');

      const rectClassName = `y-axis-rect-${orientation || ''}`;

      const firstChild = axis.children[0];
      if (firstChild.nodeName !== 'rect') {
        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        const yAxisHeight = axis.getBoundingClientRect().height;
        const yAxisWidth = axis.getBoundingClientRect().width;

        rect.setAttribute('x', '-1');
        rect.setAttribute('y', '0');
        rect.setAttribute('width', String(yAxisWidth + 8));
        rect.setAttribute('height', String(yAxisHeight + xAxisHeight));
        rect.setAttribute('fill', 'white');
        rect.setAttribute('class', rectClassName);

        axis.insertBefore(rect, axis.firstChild);
      }

      const wrapperClientWidth = wrapper?.clientWidth || 0;
      const graphWrapperClientWidth = graphWrapper?.clientWidth || 0;

      const position =
        orientation === 'left'
          ? e.currentTarget.scrollLeft
          : e.currentTarget.scrollLeft -
          wrapperClientWidth +
          graphWrapperClientWidth;

      // eslint-disable-next-line no-comments/disallowComments
      // @ts-expect-error
      axis.style = 'transform: translateX(' + position + 'px);';
    });
  };

  const tenPc = Math.ceil(((domain.max - domain.min) / 100) * 10);
  return (
    <Box
      sx={sx.graphWrapper}
      className={`graph-wrapper ${wrapperClassName}`}
      onScroll={handleOnScroll}
    >
      <ResponsiveContainer width={calculateBarchartWidth()} height={290}>
        <ComposedChart
          barCategoryGap="-1"
          data={dataWithoutEmpty}
          margin={{
            top: 0,
            bottom: 0,
            right: 0,
            left: -60,
          }}
          ref={ref}
        >
          <Bar
            dataKey="chart.zones.offset"
            stackId="a"
            fill={'none'}
            isAnimationActive={false} />
          <Bar
            dataKey="chart.zones.lowerOverCritical"
            stackId="a"
            fill={outZoneColor}
            isAnimationActive={false}
          />
          <Bar
            dataKey="chart.zones.lowerCritical"
            stackId="a"
            fill={criticalAreaColor}
            isAnimationActive={false}
          />
          <Bar
            dataKey="chart.zones.normal"
            stackId="a"
            fill={safeAreaColor}
            isAnimationActive={false}
          />
          <Bar
            dataKey="chart.zones.upperCritical"
            stackId="a"
            fill={criticalAreaColor}
            isAnimationActive={false}
          />
          <Bar
            dataKey="chart.zones.upperOverCritical"
            stackId="a"
            fill={outZoneColor}
            isAnimationActive={false}
          />
          <XAxis
            dataKey="shortDate"
            stroke={theme.palette.grey[400]}
            fontSize="14px"
            tickMargin={8}
            axisLine={false}
            tickSize={0}
          />
          <CartesianGrid />
          <Line
            isAnimationActive={false}
            dataKey="dot"
            stroke="#2C96B5"
            dot={<CustomizedDot />}
            fill="none"
            strokeWidth={2}
            connectNulls
            activeDot={<CustomizedDot active />}
          />
          <YAxis
            width={100}
            stroke={theme.palette.grey[400]}
            fontSize="14px"
            tickMargin={8}
            axisLine={false}
            tickSize={0}
            domain={[domain.min - tenPc, domain.max + tenPc]}
          />
          <Tooltip
            isAnimationActive={false}
            content={<CustomTooltip closeTooltip={closeTooltip} isMobile={mobile} />}
            trigger={mobile ? 'click' : 'hover'}
            wrapperStyle={{pointerEvents: 'initial', width: '240px'}}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
