import {MenuTypes} from '@api';
import {StorageFilters} from '@src/store/storage/types';

export const INITIAL_DATES = {
  from: null,
  to: null,
};

export const INITIAL_FILTERS: StorageFilters = {
  phrase: '',
  menu: MenuTypes.ALL,
  ...INITIAL_DATES,
};
