/* eslint-disable no-comments/disallowComments */
import {useGoogleLoader} from '@src/components/Google/GoogleLoaderProvider';
import {useEffect, useRef, useState} from 'react';

import {googleAutocompleteOptions} from './types';

export const useGoogleAutocomplete = ({
  onChange,
}: {
  onChange?: (
    placeResult: google.maps.places.PlaceResult,
    autocomplete: google.maps.places.Autocomplete
  ) => void
} = {}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>(undefined);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | undefined>(undefined);
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const loader = useGoogleLoader();

  // create autocomplete
  useEffect(() => {
    void loader?.importLibrary('places').then(({Autocomplete, AutocompleteService}) => {
      if (!ref.current) return;

      const autocomplete = new Autocomplete(
        ref.current,
        googleAutocompleteOptions,
      );
      const service = new AutocompleteService();

      setAutocomplete(autocomplete);
      setAutocompleteService(service);
    });
  }, [loader]);

  const onchangeRef = useRef(onChange);
  onchangeRef.current = onChange;

  // subscribe to events
  useEffect(() => {
    if (!autocomplete || !onchangeRef.current) return;

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      onchangeRef.current?.(place, autocomplete);
    });

    return () => {
      autocomplete.unbindAll();
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [autocomplete]);

  const getPlacePredictions = (inputValue: string) => {
    if (autocompleteService) {
      void autocompleteService.getPlacePredictions(
        {input: inputValue},
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
            setOptions(predictions);
          } else {
            setOptions([]);
          }
        },
      );
    }
  };

  return {ref, autocomplete, options, getPlacePredictions};
};
