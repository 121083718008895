import {Box, SxProps, Theme, styled} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: (theme) => theme.pageTitleHeight,
  },
  emptyWrapper: {
    display: 'flex',
    flexGrow: 1,
    gap: 24,
  },
};

export const Cell = styled(Box)`
  align-items: flex-start;
  display: flex;
`;

export const MainRow = styled(Box)(() => `
  display: grid;
  align-content: center;
  min-height: 45px;
  box-sizing: border-box;
  column-gap: 20px;
  padding-top: 5px;
  padding-bottom: 0px;
  cursor: pointer;
  & > div {
    align-items: center;
  }
`);

export const InternalRow = styled(Box)`
  display: grid;
  column-gap: 20px;
  min-height: 30px;
  padding: 12px 0px 0px;
  box-sizing: border-box;
`;

export const ExpandableRowContainer = styled(Box)<{ critical: number, onlySemanticBlock?: boolean }>(({theme, critical, onlySemanticBlock}) => `
  paddingX: {xs: '12px', sm: '24px', lg: '36px'};
  padding-left: ${onlySemanticBlock ? '36px' : '24px'};
  box-sizing: border-box;
  .mobile-layout & {
    padding-left: 8px;
    padding-right: 8px;
  }
  .tablet-layout & {
    padding-left: 12px;
    padding-right: 12px;
  }
  cursor: initial;
  border-top: ${critical ? `1px solid ${alpha(theme.palette.primary.main, 14)}` : 'unset'};
  border-bottom: ${critical ? `1px solid ${alpha(theme.palette.primary.main, 14)} !important` : `1px solid ${alpha(theme.palette.secondary.main, 14)} !important`};
  background-color: ${critical ? alpha(theme.palette.primary.main, 7) : 'initial'};
  &:active {
    background-color: ${critical ? alpha(theme.palette.primary.main, 14) : 'initial'};
  };
  ${theme.breakpoints.up('sm')} {
    &:hover {
      background-color: ${critical ? alpha(theme.palette.primary.main, 14) : alpha(theme.palette.secondary.main, 7)};
    };
    '&:active': {
      background-color: ${critical ? alpha(theme.palette.primary.main, 14) : alpha(theme.palette.secondary.main, 14)};
    };
  }
`);

ExpandableRowContainer.defaultProps = {
  paddingY: 0,
};
