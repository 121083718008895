/** @jsxImportSource @emotion/react */
import {Checkbox as MuiCheckbox, CheckboxProps, css, FormControlLabel, FormControlLabelProps} from '@mui/material';
import React, {FC, memo} from 'react';

export const Checkbox: FC<CheckboxProps> = ({disabled: _, checked, onChange, ...restProps}) => {
  return (
    <MuiCheckbox
      color="secondary"
      sx={(_theme) => ({
        padding: '12px',
        marginRight: '8px',
        width: 42,
        height: 42,
      })}
      checked={checked ?? undefined}
      onChange={onChange}
      {...restProps}
    />
  );
};
Checkbox.defaultProps = {
  color: 'secondary',
};
interface Props extends Omit<FormControlLabelProps, 'control' | 'onChange'> {
  value?: boolean | undefined | null
  onChange?: CheckboxProps['onChange']
  noMargin?: boolean
  name?: string
}
export const CheckboxControl = memo(({value, onChange, noMargin, name = 'checkbox', ...restProps}: Props) => {
  return (
    <FormControlLabel
      name={name}
      css={css`
        margin-left: -5px;
            `}
      {...restProps}
      control={<MuiCheckbox
        color="secondary"
        sx={(t) => ({
          padding: '12px',
          marginRight: noMargin ? 0 : '8px',
          '&.Mui-disabled': {rect: {fill: t.palette.grey[300]}},
        })}
        checked={value ?? undefined}
        onChange={onChange}
      />}
    />
  );
});

CheckboxControl.displayName = 'CheckboxControl';
