import {Box, Stack, Avatar, Typography} from '@mui/material';
import {Link} from '@src/components';
import {CollapsibleSection} from '@src/components/CollapsibleSection';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {SidebarTabTetrary} from '@src/components/SidebarTabTetrary';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ResultIcon} from '@src/shared/assets/icons/24x24/results.svg';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as InfoIcon} from '@src/shared/assets/icons/avatar.svg';
import {ReactComponent as RelationsIcon} from '@src/shared/assets/icons/relations-icon.svg';
import {useMQuery} from '@src/shared/hooks';
import {getUserInitials} from '@src/shared/utils';
import {getName} from '@src/shared/utils/getName';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useLayoutEffect, useState} from 'react';
import {Outlet, generatePath, useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {BreakRelationModal} from '../BreakRelationModal';
import {ChangeAccessModal} from '../ChangeAccessModal';
import {createNavigateHandler} from '../helpers';
import {MainTabs, SubTabs} from '../types';

import {sx} from './styles';

export const RelationTabletAndMobileLayout = () => {
  const {t, ready} = useTranslate('connections');
  const {desktop: isDesktop, mobile: isMobile} = useMQuery();

  const {id} = useParams();
  const {relationId, fetching} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const location = useLocation();
  const navigate = useNavigate();

  const routes = {
    PERSONAL: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT),
    CONTACTS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS),
    HEALTH_POLICIES: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES),
    RELATIONS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RELATIONS),
    RESULTS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS),
    RESULT_DETAILS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DETAILS),
    RESULT_DYNAMICS: useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DYNAMICS),
  };

  const noPadding = (routes.RELATIONS || routes.RESULTS || routes.RESULT_DETAILS || routes.RESULT_DYNAMICS);

  const [activeTab, setActiveTab] = useState(MainTabs.INFO);
  const [activeSection, setActiveSection] = useState<SubTabs | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAccessModalOpen, setIsDeleteAccessModalOpen] = useState(false);

  const isActiveResultsMainTab = routes.RESULTS || routes.RESULT_DETAILS || routes.RESULT_DYNAMICS;
  const isActiveInfoMainTab = routes.PERSONAL || routes.CONTACTS || routes.HEALTH_POLICIES;

  const navigateHandler = createNavigateHandler(navigate, id);

  const handleSectionClick = (section: SubTabs) => {
    const newSection = activeSection === section ? null : section;

    setActiveSection(newSection);

    if (newSection) {
      let path = '';

      switch (section) {
        case SubTabs.PERSONAL:
          path = `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/about`;
          break;
        case SubTabs.CONTACTS:
          path = `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/contacts`;
          break;
        case SubTabs.HEALTH_POLICIES:
          path = `${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/health-policies`;
          break;
        default:
          break;
      }

      navigate(path);
    }
  };

  const openChangeAccess = () => {
    if (!isDesktop && id) {
      const path = generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_CHANGE_ACCESS, {id});
      navigate(path, {state: {from: location.pathname}});
    } else {
      setIsDeleteAccessModalOpen(true);
    }
  };

  useLayoutEffect(() => {
    if (isActiveInfoMainTab) {
      if (activeTab !== MainTabs.INFO) {
        setActiveTab(MainTabs.INFO);
        setActiveSection(null);
      }
    } else if (routes.RELATIONS) {
      setActiveTab(MainTabs.RELATIONS);
    } else if (isActiveResultsMainTab) {
      setActiveTab(MainTabs.RESULTS);
    }
  }, [location, routes, isActiveResultsMainTab]);

  const isTabletPadding = isMobile && (noPadding !== null);

  const paddingTabs = {
    sm: isTabletPadding ? '24px' : '0px',
    xs: isTabletPadding ? '18px' : '0px',
  };

  if (relationId === undefined && !fetching) {
    navigate(ROUTERS_PATH.NOT_FOUND);
    return null;
  }

  if (!ready) return null;

  return (
    <Stack sx={sx.wrapper}>
      <Box sx={sx.boxContainer}>
        <Stack sx={sx.breadCrumbs}>
          <ArrowLeftIcon />
          <Link sx={sx.breadCrumbsLink} to={ROUTERS_PATH.CONNECTIONS_RELATIONS}>{t('BACK_TO_ALL_RELATIONS')}</Link>
        </Stack>
        <Stack sx={sx.breakRelationWrapper}>
          <Stack sx={sx.breakRelationContainer}>
            <Button
              sx={sx.breakRelationBth}
              size='xs'
              variant="text"
              color="secondary"
              onClick={() => setIsModalOpen(true)}
            >
              {t('BREAK_RELATION')}
            </Button>
            <Typography sx={sx.text}>{t('WITH')}</Typography>
            <Box sx={sx.avatarBoxContainer}>
              <Avatar sx={sx.avatar} src={relation?.avatarUrl} alt={''}>
                <Typography variant="12_16_700">{getUserInitials(relation?.contact)}</Typography>
              </Avatar>
              <EllipsisTextTooltip
                sx={sx.text}
                id='relation-contact'
                ellipsisText={getName(relation?.contact, {short: true})}
                component="p"
                variant="18_24_500" />
              <Typography sx={sx.text} variant="18_24_500">{'?'}</Typography>
            </Box>
          </Stack>
          <Button
            sx={sx.changeAccessBth}
            size='sm'
            variant="outlined"
            color="secondary"
            onClick={openChangeAccess}
          >
            {t('CHANGE_ACCESS')}
          </Button>
        </Stack>
      </Box>
      <Stack flexDirection='column' gap={12}>
        <Box sx={sx.mainTabsContainer}>
          <>
            <SidebarTabTetrary
              id='tab-info'
              label={t('INFO')}
              icon={<InfoIcon width={18} height={18} />}
              isActive={activeTab === MainTabs.INFO}
              onClick={navigateHandler(MainTabs.INFO)}
            />
            <SidebarTabTetrary
              id='tab-relations'
              label={t('RELATIONS')}
              icon={<RelationsIcon width={18} height={18} />}
              isActive={activeTab === MainTabs.RELATIONS}
              onClick={navigateHandler(MainTabs.RELATIONS)}
            />
            <SidebarTabTetrary
              id='tab-results'
              label={t('RESULTS')}
              icon={<ResultIcon width={18} height={18} />}
              isActive={activeTab === MainTabs.RESULTS}
              onClick={navigateHandler(MainTabs.RESULTS)}
            />
          </>
        </Box>
        {activeTab === MainTabs.INFO
          ? (
            <WBox sx={sx.container}>
              <CollapsibleSection
                title={t('PERSONAL')}
                onClick={() => handleSectionClick(SubTabs.PERSONAL)}
                isActive={activeSection === SubTabs.PERSONAL}>
                <Outlet />
              </CollapsibleSection>
              <CollapsibleSection
                title={t('CONTACTS')}
                onClick={() => handleSectionClick(SubTabs.CONTACTS)}
                isActive={activeSection === SubTabs.CONTACTS}>
                <Outlet />
              </CollapsibleSection>
              <CollapsibleSection
                title={t('HEALTH_POLICIES')}
                onClick={() => handleSectionClick(SubTabs.HEALTH_POLICIES)}
                isActive={activeSection === SubTabs.HEALTH_POLICIES}>
                <Outlet />
              </CollapsibleSection>
            </WBox>
          )
          : (
            <WBox
              noPaddings
              sx={{
                ...sx.container,
                p: paddingTabs,
              }}
            >
              <Outlet />
            </WBox>
          )
        }
      </Stack>
      <BreakRelationModal open={isModalOpen} setIsModalOpen={setIsModalOpen} contactRelationId={id} />
      {isDesktop &&
        <ChangeAccessModal isOpen={isDeleteAccessModalOpen} onClose={() => setIsDeleteAccessModalOpen(false)} />}
    </Stack>
  );
};
