import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {bmiApi, BMIDataType, BMIRecommendationPayload} from '@src/api';
import {ValidationErrorType} from '@src/types';

import {getError} from '../common/error-handlers';
import {notifyActions} from '../notifications/slice';

export type TRequestNPIOrgFlowSteps =
  | 'init'
  | 'loading'
  | 'success'
  | 'error';
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IBmiState {
  bmi: number | null
  height: number | null
  weight: number | null
  fetching: boolean
  error?: ValidationErrorType | null
  data: BMIDataType[] | null
  step: TRequestNPIOrgFlowSteps
  recommendationData: {
    recommendation: string | null
    description: string | null
    nameOfRecommendation: string | null
  }
}
const initialState: IBmiState = {
  bmi: null,
  height: null,
  weight: null,
  fetching: false,
  error: null,
  data: null,
  step: 'init',
  recommendationData: {
    recommendation: null,
    description: null,
    nameOfRecommendation: null,
  },
};

export const fetchRecommendation = createAsyncThunk(
  'bmi/fetchRecomendation',
  async (payload: BMIRecommendationPayload, {dispatch}) => {
    try {
      const response = await bmiApi.getRecomendation(payload);
      return response.data;
    } catch (e: any) {
      const error = getError(e);
      dispatch(bmi.actions.setCommonError(e));
      dispatch(notifyActions.showErrors(error));
    };
  },
);

export const bmi = createSlice({
  name: 'bmi',
  initialState,
  reducers: {
    requestGetBMI () {},
    requestCreateBmi (_state, _action: PayloadAction<{height: number, weight: number}>) {},
    setBmiData (state, {payload}: PayloadAction<IBmiState['data']>) {
      if (payload) {
        const lastData = payload[0];
        state.data = payload;
        state.bmi = lastData.value;
        state.height = lastData.height;
        state.weight = lastData.weight;
      }
    },
    setFetching (state, {payload}: PayloadAction<IBmiState['fetching']>) {
      if (!payload) {
        state.fetching = false;
      }
      state.fetching = payload;
    },
    setStep (
      state,
      {payload}: PayloadAction<{ step: TRequestNPIOrgFlowSteps }>,
    ) {
      state.step = payload.step;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendation.fulfilled, (state, action) => {
      if (action.payload) {
        const {
          recommendation,
          description,
          name: nameOfRecommendation,
        } = action.payload;
        state.recommendationData = {
          recommendation,
          description,
          nameOfRecommendation,
        };
      };
    });
  },
});

export const bmiReducer = bmi.reducer;
export const bmiActions = bmi.actions;
export const bmiStoreName = bmi.name;
