import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  whiteBox: {
    padding: {
      lg: 36,
      sm: 24,
      xs: '18px 16px',
    },
    flexGrow: 1,
    '.mobile-layout &': {
      padding: '18px 16px!important',
    },
  },
  content: {
    gap: {
      lg: 36,
      sm: 36,
      md: 36,
      xs: 24,
    },
  },
  insuranceWrapper: {
    gap: 24,
  },
  guarantorWrapper: {
    gap: {
      lg: 18,
      sm: 18,
      md: 18,
      xs: 16,
    },
  },
  guarantorContentWrapper: {
    flexDirection: {
      lg: 'row',
      sm: 'row',
      md: 'row',
      xs: 'column',
    },
    gap: 24,
  },
  noInsuranceDescription: (t) => ({
    color: t.colors.all.grey[500],
  }),
  contentWrapper: {
    flexGrow: 1,
    gap: {
      lg: 36,
      md: 24,
      sm: 24,
      xs: 24,
    },
  },
  addInsuranceButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  wrapperEditButton: {
    flexDirection: 'row',
    gap: 18,
  },
  listHeaderWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: (t) => ({
    width: 42,
    border: {
      lg: `1px solid ${t.palette.primary.main}`,
      md: `1px solid ${t.palette.primary.main}`,
      sm: `1px solid ${t.palette.primary.main}`,
      xs: 'none',
    },
    borderRadius: 0,
    svg: {
      path: {
        fill: t.palette.primary.main,
      },
    },
    ':hover': {
      borderColor: t.palette.primary.light,
      svg: {
        path: {
          fill: t.palette.primary.light,
        },
      },
    },
    ':active': {
      borderColor: t.palette.primary.dark,
      svg: {
        path: {
          fill: t.palette.primary.dark,
        },
      },
    },
  }),
};
