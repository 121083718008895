/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {SvgIcon, Theme} from '@mui/material';
import {ReactComponent as IconSVGSuccess} from '@src/shared/assets/images/icons/icon_success_round.svg';
import {H5, Text} from 'components/Text';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';
import {toast, ToastOptions} from 'react-toastify';

import {ReactComponent as IconClose} from '@src/shared/assets/icons/close.svg';
import {ReactComponent as IconError} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as IconWarn} from '@src/shared/assets/icons/warning.svg';
import {customErrorStyles, customSuccessStyles, customWarnStyles, sx} from './styled';
import {ToastContentProps, ToastDataProps} from './types';

const IconCloseBtn = () => {
  return (
    <SvgIcon sx={sx.closeButton}>
      <IconClose />
    </SvgIcon>
  );
};

const ErrorToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={18}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('TOAST_ERROR_HEADER')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}>{text}</Text>
    </>
  );
};

export const errorToast = (data: ToastDataProps, options?: ToastOptions) => {
  toast.error(() => <ErrorToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customErrorStyles,
    icon: <IconError />,
    closeButton: <IconCloseBtn />,
    toastId: data.id ?? data.text,
    ...options,
  });
  toast.update(data.id ?? data.text, options);
};

const WarnToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={20}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('TOAST_WARNING_HEADER')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}>{text}</Text>
    </>
  );
};

export const warningToast = (data: ToastContentProps) => {
  toast.warn(() => <WarnToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customWarnStyles,
    icon: <IconWarn />,
    closeButton: <IconCloseBtn />,
  });
};

const SuccessToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={700}>{header ?? t('SUCCESS_TOAST_HEADER')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}>{text}</Text>
    </>
  );
};

export const successToast = (data: ToastContentProps) => {
  toast.success(() => <SuccessToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customSuccessStyles,
    icon: <IconSVGSuccess />,
    closeButton: <IconCloseBtn />,
    autoClose: 10000,
    position: data.position,
  });
};
