import {Stack, SxProps, Theme} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';

const loaderContainer: SxProps<Theme> = {
  alignItems: 'center',
  justifyContent: 'center',
  height: 124,
  padding: {
    xs: 18,
    md: 24,
  },
};

export const Loader = () => {
  return (
    <Stack sx={loaderContainer}>
      <PulseLoader loading />
    </Stack>
  );
};
