import {Stack, Box, Typography, SxProps, Collapse, List, ListItem, Link, Theme} from '@mui/material';
import {Profile} from '@src/api';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {useTranslate} from '@src/i18n/useTranslate';
import {getInitialContact} from '@src/pages/Overview/fragments/GeneralInfo';
import {GeneralSubInfo} from '@src/pages/Overview/fragments/GeneralSubInfo';
import {Loader} from '@src/pages/Overview/fragments/Loader';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-icon.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useState} from 'react';
import {Button, IconButtonNew} from 'ui-kit';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    padding: {
      xs: '0px 18px 18px 18px',
      sm: '0px 24px 24px 24px',
      md: '30px 24px 24px 24px',
      lg: 24,
    },
    textAlign: 'start',
    overflow: 'hidden',
  },
  titleContainer: {
    paddingBottom: {
      xs: 3,
      sm: 0,
    },
    mb: {xs: 18, sm: 24, lg: 30},
    marginTop: {
      xs: 41,
      sm: 'inherit',
    },
    justifyContent: {
      xs: 'left',
      md: 'space-between',
    },
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    marginRight: {
      xs: 24,
      sm: 'inherit',
    },
    typography: {xs: '12_16_700', sm: '14_18_700', md: '24_28_400', lg: '24_34_500'},
  },
  subTitle: {
    typography: {xs: '12_16_700', sm: '14_18_700', md: '16_20_700', lg: '16_20_700'},
  },
  subtitlePhones: {
    mt: {xs: 17, sm: 0, md: 'inherit'},
  },
  contentContainer: {
    width: {
      xs: '100%',
      sm: '100%',
    },
    marginTop: {
      xs: 24,
      sm: 0,
      md: 'inherit',
    },
    mb: {xl: 30},
  },
  contentContainerLg: {
    mt: {lg: 30, xl: 0},
  },
  content: {
    typography: {xs: '12_16_500', sm: '14_18_500', md: '16_20_500', xl: '16_20_500'},
  },
  contentWithMain: {
    typography: {xs: '12_16_500', sm: '14_18_500', md: '16_20_500'},
  },
  button: {
    ml: {xs: -6, lg: -15},
    mt: {xs: 6, md: 12},
    fontWeight: 600,
  },
  boxInfo: (t: Theme) => ({
    marginTop: {
      xs: 4,
      sm: 4,
    },
    [t.breakpoints.only('sm')]: {
      '&:last-child': {
        mt: 2,
      },
    },
  }),
  editIconContainer: (t: Theme) => ({
    mt: {xs: 40, sm: 0, md: 'inherit'},
    [t.breakpoints.only('md')]: {
      mt: -12,
    },
  }),
  editIconContainerXl: (t: Theme) => ({
    [t.breakpoints.only('xl')]: {
      mt: -12,
    },
    [t.breakpoints.only('lg')]: {
      mt: -14,
    },
  }),
  phonesContainer: (t) => ({
    [t.breakpoints.only('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    [t.breakpoints.only('xl')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    [t.breakpoints.only('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      mb: 30,
    },
    [t.breakpoints.only('sm')]: {
      mt: 42,
    },
    [t.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
  flexContainer: (t: Theme) => ({
    [t.breakpoints.only('md')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 30,
    },
    [t.breakpoints.only('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 18,
    },
  }),
  boxContainerEmails: (t: Theme) => ({
    [t.breakpoints.only('md')]: {
      width: '50%',
      pr: 32,
    },
  }),
  boxContainerEmailsXl: (t: Theme) => ({
    [t.breakpoints.only('xl')]: {
      width: '50%',
      pr: 36,
      mb: 0,
    },
    [t.breakpoints.only('lg')]: {
      width: '50%',
      pr: 36,
      mb: 0,
    },
  }),
  boxContainerEmailsLg: (t: Theme) => ({
    [t.breakpoints.only('lg')]: {
      width: '100%',
      pr: 0,
      mb: 0,
    },
  }),
  boxContainerPhones: (t: Theme) => ({
    [t.breakpoints.only('md')]: {
      mr: 24,
    },
  }),
  boxContainerPhonesXl: (t: Theme) => ({
    [t.breakpoints.only('xl')]: {
      mr: 18,
    },
    [t.breakpoints.only('lg')]: {
      mr: 20,
    },
  }),
};

interface ContactsProps {
  profile: Profile | null
  isSidebarOpen?: boolean
  isLoading?: boolean
}

export const Contacts = ({profile, isSidebarOpen, isLoading}: ContactsProps) => {
  const {t} = useTranslate('overview');
  const {
    mobile: isMobile,
    tabletPortrait: isTabletPortrait,
    tablet: isTablet,
    tabletLandscape: isTabletLandscape,
    lg: isDesktopLg,
    xxl: isDesktopXxl,
    xl: isDesktopXl,
  } = useMQuery();
  const [phonesListIsChecked, setPhonesIsChecked] = useState(false);
  const [emailsListIsChecked, setEmailsIsChecked] = useState(false);

  const checkMorePhones = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPhonesIsChecked((prev) => !prev);
  };
  const checkMoreEmails = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setEmailsIsChecked((prev) => !prev);
  };

  const hasMoreThenTwoPhones = profile?.phones?.length && profile?.phones?.length > 2;

  if (isLoading) {
    return (
      <Loader/>
    );
  }

  return (
    <Stack sx={sx.container}>
      {(isMobile || isTabletPortrait) && (
        <GeneralSubInfo contact={profile?.contact || getInitialContact()} />
      )}

      {(!isTablet && !isMobile && ((isDesktopXl && !isSidebarOpen) || isDesktopXxl || (isDesktopLg && isSidebarOpen))) && (
        <Stack sx={sx.titleContainer}>
          <Typography sx={sx.title} component='h3'>
            {t('CONTACTS')}
          </Typography>
          <Link target='_blank' href={`${envs.PASSPORT_WEB_URL}/profile/phones`}>
            <IconButtonNew color={'tertiary'} size={'lg'} sx={sx.editIconContainer}>
              <EditIcon />
            </IconButtonNew>
          </Link>
        </Stack>
      )}

      <Stack direction={(isTabletLandscape ? 'row' : 'column')} sx={sx.flexContainer}>
        {profile?.phones?.length && (
          <Stack sx={sx.phonesContainer}>
            <Box
              sx={(isDesktopXl && isSidebarOpen) || (isDesktopLg && !isSidebarOpen)
                ? [...spreadSx(sx.contentContainer), ...spreadSx(sx.boxContainerPhonesXl)]
                : [...spreadSx(sx.contentContainer), ...spreadSx(sx.boxContainerPhones)]}>
              <Typography sx={[...spreadSx(sx.subTitle), ...spreadSx(sx.subtitlePhones)]} component='h5'>
                {t('PHONES')}
              </Typography>
              <Box sx={sx.boxInfo}>
                {profile?.phones?.slice(0, 2).map((item) => (
                  <ListItem disablePadding key={item.id}>
                    <Typography sx={sx.content}>{formatPhoneNumber(item.phone)}{'\u00A0'}</Typography>
                    {item?.main && <Typography sx={sx.contentWithMain}>(main)</Typography>}
                  </ListItem>
                ))}
              </Box>
              <Collapse in={phonesListIsChecked}>
                <List sx={{padding: 0}}>
                  {profile?.phones?.slice(2).map((item) => (
                    <ListItem disablePadding key={item.id}>
                      <Typography sx={sx.content}>{formatPhoneNumber(item?.phone)}{'\u00A0'}</Typography>
                      {item?.main && <Typography sx={sx.contentWithMain}>(main)</Typography>}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              {hasMoreThenTwoPhones && (
                <Button
                  color='primary'
                  variant={'text'}
                  size='xs'
                  sx={sx.button}
                  onClick={checkMorePhones}
                >
                  {t(phonesListIsChecked ? 'LESS' : 'MORE')}
                </Button>
              )}
            </Box>
            {(isMobile || isTabletPortrait) && (
              <Link sx={sx.link} target='_blank' href={`${envs.PASSPORT_WEB_URL}/profile/phones`}>
                <IconButtonNew color={'tertiary'} size={'lg'} sx={sx.editIconContainer}>
                  <EditIcon />
                </IconButtonNew>
              </Link>
            )}
            {profile?.actualAddresses && (isTabletLandscape || (isDesktopLg && !isSidebarOpen) || (isDesktopXl && isSidebarOpen))
              ? (
                <Box sx={sx.contentContainer}>
                  <Typography sx={sx.subTitle} component='h5'>
                    {t('ADDRESS')}
                  </Typography>
                  <Box sx={sx.boxInfo}>
                    <ListItem disablePadding>
                      <Typography sx={sx.content}>{profile?.actualAddresses?.main}</Typography>
                    </ListItem>
                  </Box>
                </Box>
              )
              : null}
            {(isTabletLandscape || (isDesktopLg && !isSidebarOpen) || (isDesktopXl && isSidebarOpen)) && (
              <Link target='_blank' href={`${envs.PASSPORT_WEB_URL}/profile/phones`}>
                <IconButtonNew
                  color={'tertiary'}
                  size={'lg'}
                  sx={(isDesktopXl && isSidebarOpen) || (isDesktopLg && !isSidebarOpen)
                    ? [...spreadSx(sx.editIconContainer), ...spreadSx(sx.editIconContainerXl)]
                    : sx.editIconContainer}>
                  <EditIcon />
                </IconButtonNew>
              </Link>
            )}
          </Stack>
        )}
        {profile?.emails?.length
          ? (
            <Box
              sx={(isDesktopXl && isSidebarOpen) || (isDesktopLg && !isSidebarOpen)
                ? [...spreadSx(sx.contentContainer), ...spreadSx(sx.boxContainerEmailsXl)]
                : [...spreadSx(sx.contentContainer), ...spreadSx(sx.boxContainerEmails), ...spreadSx(sx.boxContainerEmailsLg)]}>
              <Typography sx={sx.subTitle} component='h5'>
                {t('EMAILS')}
              </Typography>
              <Box sx={sx.boxInfo}>
                {profile?.emails?.slice(0, 2).map(({id, email, main}) => (
                  <ListItem disablePadding key={id}>
                    <EllipsisTextTooltip sx={sx.content} ellipsisText={email} />
                    {main && <Typography sx={sx.contentWithMain}>&nbsp;(main)</Typography>}
                  </ListItem>
                ))}
              </Box>
              <Collapse in={emailsListIsChecked}>
                <List sx={{padding: 0}}>
                  {profile?.emails?.slice(2).map(({email, id}) => (
                    <ListItem disablePadding key={id}>
                      <EllipsisTextTooltip sx={sx.content} ellipsisText={email} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              {profile?.emails?.length > 2 && (
                <Button
                  color='primary'
                  variant={'text'}
                  size='xs'
                  sx={sx.button}
                  onClick={checkMoreEmails}
                >
                  {t(emailsListIsChecked ? 'LESS' : 'MORE')}
                </Button>
              )}
            </Box>
          )
          : null}
        {profile?.actualAddresses && !isTabletLandscape &&
          ((isDesktopXl && !isSidebarOpen) || (isMobile ||
            isTabletPortrait || isDesktopXxl || (isDesktopLg &&
              isSidebarOpen)))
          ? (
            <Box sx={(isDesktopLg && !isSidebarOpen) ? sx.contentContainer : [...spreadSx(sx.contentContainer), ...spreadSx(sx.contentContainerLg)]}>
              <Typography sx={sx.subTitle} component='h5'>
                {t('ADDRESS')}
              </Typography>
              <Box sx={sx.boxInfo}>
                <ListItem disablePadding>
                  <Typography sx={sx.content}>{profile?.actualAddresses?.main}</Typography>
                </ListItem>
              </Box>
            </Box>
          )
          : null}
      </Stack>
    </Stack>
  );
};
