import {Box, Stack} from '@mui/material';
import {EmptyFragment} from '@src/components/EmptyFragment';
import {PulseLoader} from '@src/components/PulseLoader';
import {WhiteBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useParams} from 'react-router-dom';

import {ResultsTab} from './ResultsTab/ResultsTab';

export const RelationResults = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);
  const isAccesses = !!relation?.accesses?.length;

  return (
    <>
      <Stack flexGrow={1} gap={24}>
        {isAccesses
          ? (
            <ResultsTab fetching={fetching} />
          )
          : (
            <WhiteBox sx={{flexGrow: 1, padding: 24}}>
              {fetching
                ? (
                  <Box sx={{width: '100%', justifyContent: 'center', display: 'flex'}}>
                    <PulseLoader loading={true} />
                  </Box>
                )
                : (
                  <EmptyFragment
                    title={t('RESULTS')}
                    description={t('YOU_DONT_HAVE_ACCESS_TO_THIS_DATA')}
                    button={t('REQUEST_ACCESS')}
                    isButtonIcon={false}
                    noPaddings
                  />
                )}
            </WhiteBox>
          )}
      </Stack>
    </>
  );
};
