import {OverviewProps} from '@src/types';
import {OverviewDesktopXlOpenSidebar} from './OverviewDesktopXlOpenSidebar';
import {OverviewDesktopLgOpenSidebar} from './OverviewDesktopLgOpenSidebar';

export const OverviewDesktopLg = ({isSidebarOpen, ...props}: OverviewProps) => {
  return (
    isSidebarOpen
      ? (
        <OverviewDesktopLgOpenSidebar
          {...props}
          isSidebarOpen={isSidebarOpen}
        />
      )
      : (
        <OverviewDesktopXlOpenSidebar
          {...props}
        />
      )
  );
};
