import {css as cssClassName} from '@emotion/css';
import {paletteColors} from '@src/theme';
import {SxProps, Theme} from '@mui/material';

const baseToastStyles = cssClassName`
  width: 300px;
  height: 70px;
`;

export const customErrorStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.error[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.error[200]};
    }
}
`;

export const customWarnStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.warning[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.warning[200]};
    }
}
`;

export const customSuccessStyles = cssClassName`
${baseToastStyles};
border: 1px solid ${paletteColors.success[200]};
.Toastify {
    &__progress-bar {
        background: ${paletteColors.success[200]};
        height: 8px;
    }
}
`;

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  closeButton: {
    height: 20,
    width: 20,
    position: 'relative',
    bottom: 5,
  },
};
