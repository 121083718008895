// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */

import {
  DatePickerRange,
  SelectLabels,
} from '@components';
import {css as _, SelectChangeEvent, Stack} from '@mui/material';
import {FiltersDialog} from '@src/components/fragments/FiltersDialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {FC, useEffect, useState} from 'react';

import {initialFilter} from '../../Results.constant';

import {IFilterDialogProps} from './types';

export const FilterDialog: FC<IFilterDialogProps> = ({
  filter,
  handleClose,
  isFilterOpen,
  physicianOptions,
  setNewFilters,
}) => {
  const {t} = useTranslate('results');
  const [dialogFilters, setDialogFilters] = useState(filter);

  useEffect(() => {
    if (isFilterOpen) {
      setDialogFilters(filter);
    }
  }, [isFilterOpen]);

  const handlePhysicianChange = (value: string) => {
    const data = physicianOptions.find((item) => item.name === value);
    setDialogFilters((prev) => ({
      ...prev,
      physician: data ? {value: data.value, name: data.name} : null,
    }));
  };

  const handleSelectPhysician = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    handlePhysicianChange(value);
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setDialogFilters((prev) => ({...prev, [name]: date}));
  };

  const handleClearReportDate = () => {
    setDialogFilters((prev) => {
      return {
        ...prev,
        reportDateFrom: null,
        reportDateTo: null,
      };
    });
  };
  const handleClearCollectionDate = () => {
    setDialogFilters((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleClear = () => {
    setDialogFilters(initialFilter);
  };

  const handleApply = () => {
    handleClose();
    setNewFilters(dialogFilters);
  };

  return (
    <FiltersDialog
      isOpen={isFilterOpen}
      title={t('FILTERS')}
      onClose={handleClose}
      onApply={handleApply}
      onClear={handleClear}
    >
      <Stack gap={48}>
        <SelectLabels
          value={dialogFilters.physician?.name || ''}
          onChange={handleSelectPhysician}
          onChangeMobile={({value}) => handlePhysicianChange(value)}
          options={physicianOptions || []}
          label={t('PHYSICIAN')}
          placeholder={t('SELECT')}
          onFullClose={handleClose}
        />
        <DatePickerRange
          label="Report Date"
          variant="outlined"
          startDate={dialogFilters.reportDateFrom}
          setStartDate={handleChangeDate('reportDateFrom')}
          setEndDate={handleChangeDate('reportDateTo')}
          endDate={dialogFilters.reportDateTo}
          css={_`width: 100%; margin-bottom: 0 !important`}
          clearDate={handleClearReportDate}
          isLabelOutside={true}
          isIconVisible={false}
        />
        <DatePickerRange
          label="Collection Date"
          variant="outlined"
          startDate={dialogFilters.collectionDateFrom}
          setStartDate={handleChangeDate('collectionDateFrom')}
          setEndDate={handleChangeDate('collectionDateTo')}
          endDate={dialogFilters.collectionDateTo}
          css={_`width: 100%; margin-bottom: 0 !important`}
          clearDate={handleClearCollectionDate}
          isLabelOutside={true}
          isIconVisible={false}
        />
      </Stack>
    </FiltersDialog>
  );
};
