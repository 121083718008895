import {Typography} from '@mui/material';
import {Dialog, DialogTitle} from '@src/components/Dialog';
import {IMenuItem} from '@src/components/MenuList/MenuList';
import {useSupportItems} from '@src/components/SupportMenu/SupportMenuContainer';
import {useTranslate} from '@src/i18n/useTranslate';
import {Icons} from '@src/shared/assets/icons';
import {ReactComponent as Language} from '@src/shared/assets/icons/flag-us.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getName} from '@src/shared/utils/getName';
import {useUser} from '@src/store/user/hooks';
import {FC, useMemo} from 'react';
import {useAccount, useLogout} from 'store/account/hooks';

import {ProfileMenuView} from './ProfileMenu';

const useMenuItems = () => {
  const {account} = useAccount();
  const logout = useLogout();
  const {handleAboutSystemModal} = useUser();
  const {mobile, tablet} = useMQuery();

  const title = getName(account, {initialsOnlyMiddleName: true});

  const supportItems = useSupportItems();
  const additionalItems = useMemo(() => {
    if (mobile || tablet) {
      return supportItems.menuItems;
    }
    return [];
  }, [supportItems, mobile, tablet]);

  const menuItems: IMenuItem[] = useMemo(() => {
    return [
      {
        label: 'English',
        iconLeft: <Language />,
      },
      {
        label: 'Passport',
        to: envs.PASSPORT_WEB_URL,
      },
      {
        label: 'About system',
        onClick: handleAboutSystemModal,
      },
      ...additionalItems,
      {
        label: 'Log out',
        iconRight: <Icons.Logout.X24 />,
        onClick: logout,
      },
    ];
  }, [handleAboutSystemModal, logout, additionalItems]);

  return {menuItems, title};
};

export const ProfileMenuContainer: FC = () => {
  const {t} = useTranslate('common');
  const {title, menuItems} = useMenuItems();
  const {handleAboutSystemModal, isModalOpen} = useUser();
  const {account} = useAccount();
  return (
    <>
      <ProfileMenuView
        name={title}
        menu={menuItems}
        src={account?.avatarUrl}
      />
      <Dialog
        open={isModalOpen}
        onClose={handleAboutSystemModal}
        size='xl'>
        <DialogTitle onClose={handleAboutSystemModal} noContent={false}>{t('ABOUT_SYSTEM')}</DialogTitle>
        <Typography variant='14_20_400' sx={{padding: '12px 24px'}}>{t('ABOUT_SYSTEM_MODAL')}</Typography>
      </Dialog>
    </>
  );
};
