/* eslint-disable */
import {StorageThumbnailType} from '@api';
import {Box, Stack} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {ShareDocumentsDialog} from '@src/components/ShareDocumentsDialog';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {getBase64DisplayLink} from '@src/pages/StorageDocument/helpers';
import {useFileFirstThumbnail} from '@src/pages/StorageDocument/hooks';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-grey.svg';
import {ReactComponent as LikeFilledIcon} from '@src/shared/assets/icons/like_filled.svg';
import {ReactComponent as LikeIcon} from '@src/shared/assets/icons/like_outed.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, IconButton} from 'ui-kit';

import {DeleteDialog} from '../DeleteDialog';

import {RightContainer, sx} from './styles';
import {FileViewProps} from './types';
import {openBase64NewTab} from "@src/shared/utils/pdfHelpers";

export const FileView: FC<FileViewProps> = ({
  details,
  isSidebarOpen,
  onToggleFavorite,
  onOpenEditDialog,
  fileData,
  onFetchFileData,
}) => {
  const translate = useStorageTranslate();

  const {mobile: isMobile} = useMQuery();
  const navigate = useNavigate();
  const {fileThumbnail, isLoading, handleFetchThumbnails} = useFileFirstThumbnail({
    id: details?.id,
    type: StorageThumbnailType.MEDIUM,
  });
  const fileSize = useRef(details?.size || 0)

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const handleToggleShareDialogOpen = () => {
    setIsShareDialogOpen(!isShareDialogOpen);
  };

  const handleNavigateToList = () => {
    navigate(ROUTERS_PATH.STORAGE);
  };

  const getImgSrc = () => {
    if (fileThumbnail) {
      return getBase64DisplayLink(fileThumbnail.data.contentType, fileThumbnail.data.data);
    }
    return '';
  };

  useEffect(() => {
    if(fileSize.current !== details?.size && fileSize.current !== 0){
      handleFetchThumbnails()
    }
    fileSize.current = details?.size || 0
  }, [details?.size]);

  const handleOpenFile = async () => {
    try {
      const newFileData = fileData || await onFetchFileData();
      if (newFileData) {
        openBase64NewTab(newFileData.data, newFileData.contentType)
      }
    } catch (e){
      console.error(e)
    }
  };

  return (
    <RightContainer isSidebarOpen={isSidebarOpen}>
      {isMobile && (
        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <IconButton color={'secondary'} variant={'text'} onClick={onToggleFavorite}>
            {details?.favorite ? <LikeFilledIcon /> : <LikeIcon />}
          </IconButton>
          <Stack flexDirection={'row'}>
            <IconButton variant={'text'} onClick={handleDeleteDialogOpen}>
              <TrashIcon />
            </IconButton>
            <IconButton
              variant={'text'}
              color={'secondary'}
              sx={sx.mobileShareButton}
              onClick={handleToggleShareDialogOpen}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              variant={'text'}
              strokeIcon
              color={'secondary'}
              onClick={onOpenEditDialog}>
              <EditIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {!isMobile && (
        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button
            variant={'text'}
            color={'secondary'}
            startIcon={details?.favorite ? <LikeFilledIcon /> : <LikeIcon />}
            sx={sx.actionButton}
            onClick={onToggleFavorite}
          >
            {details?.favorite ? translate('REMOVE_FROM_FAVORITES') : translate('ADD_TO_FAVORITES')}
          </Button>
          <Stack flexDirection={'row'} gap={18}>
            <Button
              variant={'text'}
              startIcon={<TrashIcon />}
              sx={sx.actionButton}
              onClick={handleDeleteDialogOpen}
            >
              {translate('DELETE')}
            </Button>
            <Button
              variant={'text'}
              color={'secondary'}
              startIcon={<ShareIcon />}
              sx={sx.actionButton}
              onClick={handleToggleShareDialogOpen}
            >
              {translate('SHARE')}
            </Button>
            <Button
              variant={'text'}
              color={'secondary'}
              startIcon={<EditIcon />}
              strokeIcon
              sx={sx.actionButton}
              onClick={onOpenEditDialog}
            >
              {translate('EDIT')}
            </Button>
          </Stack>
        </Stack>
      )}
      {isLoading && (
        <Box sx={sx.loader}>
          <PulseLoader loading />
        </Box>
      )}
      {!isLoading && (fileThumbnail || fileData) && (
        <Box sx={sx.imageContainer} onClick={handleOpenFile}>
          <Box
            sx={sx.image}
            component={'img'}
            src={getImgSrc()}
            alt={details?.name}
          />
        </Box>
      )}
      {details && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          id={details.id}
          name={details.name}
          onClose={handleDeleteDialogClose}
          afterDeleteAction={handleNavigateToList}
        />
      )}
      {details && (
        <ShareDocumentsDialog
          isOpen={isShareDialogOpen}
          onClose={handleToggleShareDialogOpen}
          documentId={details.id}
        />
      )}
    </RightContainer>
  );
};
