import {EmptySearch} from '@components';
import {Box, Typography} from '@mui/material';
import {useMQuery} from '@src/shared/hooks';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

import {generateClassName, generateRefText} from '../Dynamics.helper';
import {Recharts} from '../Recharts';

import {IGraphicsProps} from './types';

export const Graphics: FC<IGraphicsProps> = ({data, isLoading, isNew}) => {
  const {t} = useTranslate('details');
  const {mobile} = useMQuery();

  if (!data?.length || isLoading) {
    return <EmptySearch isLoading={isLoading} isNew={isNew} />;
  }

  return (
    <>
      {data?.map(
        ({resultLaboratoryAnalyses, refMin, refMax, refText, code, testName, domain, unit}) => {
          const key: string = code ?? testName;
          return resultLaboratoryAnalyses.some((a: any) => a.dot)
            ? (
              <Box
                key={`${key}graphics`}
                mb={{sm: '24px', lg: '36px'}}
                sx={{padding: mobile ? '0px' : '0px 24px'}}
              >
                <Typography fontSize={18} fontWeight={500} pb={4}>
                  {testName}
                </Typography>
                <Typography
                  color="grey.400"
                  fontSize={14}
                  fontWeight={500}
                  marginBottom="16px">
                  {t('REFERENCE_RANGE')}: {generateRefText({refMax, refMin, refText})}
                </Typography>
                <Recharts
                  data={resultLaboratoryAnalyses}
                  domain={domain}
                  wrapperClassName={generateClassName()}
                />
              </Box>
            )
            : (
              <></>
            );
        },
      ) || null}
    </>
  );
};
