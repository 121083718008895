import {Box, SxProps, Stack, Theme} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {OverviewProps} from '@src/types';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {Contacts} from './fragments/Contacts';
import {GeneralInfo} from './fragments/GeneralInfo';
import {OverviewEmptyFragment} from './fragments/OverviewEmptyFragment';
import {OverviewInsurance} from './fragments/OverviewInsurance';
import {OverviewLastResults} from './fragments/OverviewLastResults';
import {OverviewRelations} from './fragments/OverviewRelations';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  upperSideFragments: {
    padding: '0',
    maxWidth: '100%',
  },
};

export const OverviewTabletLandscape: FC<OverviewProps> = ({
  accountProfile,
  isAccountLoading,
  data,
  isResultsLoading,
  insurances,
}) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();

  return (
    <Stack gap={24}>
      <Box sx={sx.upperContainer}>
        <WBox sx={sx.upperSideFragments}>
          <GeneralInfo contact={accountProfile?.contact} avatarUrl={accountProfile?.avatarUrl} isLoading={isAccountLoading}/>
          <Contacts profile={accountProfile} isLoading={isAccountLoading}/>
        </WBox>
      </Box>
      <Box sx={sx.upperContainer}>
        <WBox noPaddings>
          {accountProfile?.relations?.length
            ? (
              <OverviewRelations relations={accountProfile?.relations} cardsCount={2} />
            )
            : (
              <OverviewEmptyFragment
                title={t('RELATIONS')}
                button={t('ADD_RELATIONS')}
                isDisabled={false}
                onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                description={t('YOU_DIDNT_ADD_ANY_RELATIONS_YET')}
                isLoading={isAccountLoading}
              />
            )}
        </WBox>
      </Box>

      <Box sx={sx.upperContainer}>
        <WBox noPaddings={true}>
          {data?.length
            ? (
              <OverviewLastResults results={data}/>
            )
            : (
              <OverviewEmptyFragment
                title={t('LAST_RESULTS')}
                description={t('YOU_DONT_HAVE_ANY_RESULTS_YET')}
                isLoading={isResultsLoading}
              />
            )
          }
        </WBox>
      </Box>

      <Box sx={sx.upperContainer}>
        <WBox noPaddings>
          {insurances.length
            ? (
              <OverviewInsurance insurances={insurances} cardsCount={2} />
            )
            : (
              <OverviewEmptyFragment
                isDisabled={false}
                title={t('INSURANCE')}
                button={t('ADD_INSURANCE')}
                onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                description={t('YOU_DIDNT_ADD_ANY_INSURANCE_YET')}
                isLoading={isAccountLoading}
              />
            )}
        </WBox>
      </Box>
    </Stack>
  );
};
