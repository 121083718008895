import {ButtonBase, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Switch} from '@src/components/form/SwitchControl';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {initialFilter} from '@src/pages/Results/Results.constant';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {TableRow} from '@src/pages/Results/TableRow';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useRelationResults} from '@src/store/relations/hooks';
import {Result} from 'api';
import {
  EmptySearch,
  Table,
  ITableScrollUp,
  Text,
} from 'components';
import {BoxBody, WhiteBox} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {ResultsFilterDialog} from './components/ResultsFilterDialog';
import {ResultsFilterTable} from './components/ResultsFilterTable';
import {ResultsTable} from './components/ResultsTable';
import {useHeader} from './constants';
import {sx} from './styled';

export const ResultsTab = ({fetching}: {fetching: boolean}) => {
  const {t} = useTranslate('results');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);

  const [filter, setFilter] = useState(initialFilter);
  const [tempFilter, setTempFilter] = useState(filter);
  const {results: data, fetching: isLoading} = useRelationResults(relationId);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [result, setResult] = useState<Result[]>();
  const {mobile, tablet, desktop, lg} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const refTable = useRef<ITableScrollUp>();
  const [allExpanded, setAllExpanded] = useState(false);
  const [selectItem, setSelectItem] = useState('');
  const [tempSelectItem, setTempSelectItem] = useState(selectItem);
  const [filterApplied, setFilterApplied] = useState(false);

  const testLoading = fetching || isLocalLoading;

  const handleSetResult = (newValue: Result[]) => {
    setResult(newValue);
    setIsLocalLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      setIsLocalLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) handleSetResult(data);
  }, [data]);

  const getPhysicians = [
    ...new Map(data?.map((item) => [item?.physician?.id, item.physician])).values(),
  ]
    .map((item) => ({value: item?.id || '', name: getFullName(item), key: item?.id || ''}))
    .filter((item) => item.name && item.value);

  const handleApplyFilter = useCallback(() => {
    const {physician, reportDateFrom, reportDateTo, collectionDateFrom, collectionDateTo} =
      tempFilter;
    setFilter(tempFilter);
    setSelectItem(tempSelectItem);
    setFilterApplied(true);
    const doFiltration = (resultItem: Result) => {
      const filterByPhysician = physician ? resultItem.physician?.id === physician.value : true;

      const filterByRepoterDate =
        reportDateFrom || reportDateTo
          ? dateInInterval(reportDateFrom, reportDateTo, resultItem.reported)
          : true;

      const filterByCollectionDate =
        collectionDateFrom || collectionDateTo
          ? dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected)
          : true;
      return filterByPhysician && filterByRepoterDate && filterByCollectionDate;
    };
    if (data) handleSetResult(data?.filter((item) => doFiltration(item)));
    refTable.current?.scrollUp();
    setFilterOpened(false);
  }, [data, filter, tempFilter]);

  const handleClearFilter = () => {
    setTempSelectItem('');
    setTempFilter(initialFilter);
  };

  const handleClearReportDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        reportDateFrom: null,
        reportDateTo: null,
      };
    });
  };

  const handleClearCollectionDate = () => {
    setTempFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handlePhysicianSelect = (event: SelectChangeEvent<string>) => {
    setTempSelectItem(event.target.value);
    const data = getPhysicians.find((item) => item.name === event.target.value);
    if (data && event) {
      const item = event?.target?.value;
      setTempFilter((prev) => ({
        ...prev,
        physician: item ? {value: data?.value, name: data?.name} : null,
      }));
    }
  };

  const {HEADER_LIST} = useHeader();

  const handleExpandAll = () => {
    setAllExpanded((prev) => {
      return !prev;
    });
  };

  const handleChangeDate = (name: string) => (date: Date | null) => {
    setTempFilter((prev) => ({...prev, [name]: date}));
  };

  const handleOpenFilterClick = () => {
    setFilterOpened(true);
    setFilterApplied(false);
    setTempFilter(filter);
    setTempSelectItem(selectItem);
  };

  const handleCloseFilterDialog = () => {
    if (!filterApplied) {
      setTempFilter(filter);
      setTempSelectItem(selectItem);
    }
    setFilterOpened(false);
  };

  useEffect(() => {
    if (!filterOpened) {
      handleApplyFilter();
    };
  }, [tempFilter]);

  const isDisplayedSwich = result?.length !== 0;

  if (testLoading) {
    return <EmptySearch isLoading />;
  }

  if (data?.length === 0) {
    return (
      <Stack p={mobile ? 0 : 24}>
        <Typography variant='24_34_500'>{t('RESULTS')}</Typography>
        <Typography variant='14_18_500' sx={sx.patientNotFound}>
          {t('NO_RESULTS_WERE_FOUND')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {(mobile || tablet) && (
        <Stack
          sx={{
            ...sx.headerWrapper,
            padding: !mobile ? '24px 24px 0px 24px' : 0,
          }}>
          <Typography variant='22_26_500'>{t('RESULTS')}</Typography>
          <SettingsButton
            sx={{boxSizing: 'initial'}}
            onClick={handleOpenFilterClick}
          />
        </Stack>
      )}
      <WhiteBox noPaddings noBorder={!mobile || !isDisplayedSwich}>
        {desktop && (
          <Stack
            sx={{
              ...sx.headerWrapper,
              p: 18,
            }}>
            <Typography component="h4" variant="24_34_500">
              {t('RESULTS')}
            </Typography>
            {lg && <SettingsButton onClick={handleOpenFilterClick} />}
          </Stack>
        )}
        <BoxBody>
          {((isDisplayedSwich && mobile) ?? mobile) && (
            <ButtonBase
              onClick={handleExpandAll}
              sx={sx.buttonBaseSx}
            >
              <Text>{t('EXPAND_ALL')}</Text>
              <Switch sx={{mr: '-12px'}} readOnly checked={allExpanded} />
            </ButtonBase>
          )}
          {(!lg && desktop) && (
            <ResultsFilterTable
              filter={tempFilter}
              selectItem={selectItem}
              changeSelectPhysicians={handlePhysicianSelect}
              getPhysicians={getPhysicians}
              changeDate={handleChangeDate}
              onClearReportDate={handleClearReportDate}
              onClearCollectionDate={handleClearCollectionDate}
              onClear={handleClearFilter}
            />
          )}
          {mobile && (
            <Table
              classNames={{header: 'table-header'}}
              ref={refTable}
              gridTemplateColumns={'5fr 1fr'}
              data={result || []}
              headerList={HEADER_LIST}
              loading={testLoading ?? false}
              rowComponent={{component: TableRow, props: {allExpanded, onlySemanticBlock: false, isNew: true}}}
              onlySemanticBlock={false}
              emptyData={<EmptySearch isLoading={testLoading} isNew />}
            />
          )}
          {!mobile && (
            <ResultsTable
              result={result}
              isLoading={testLoading}
            />
          )}
        </BoxBody>
      </WhiteBox >
      <ResultsFilterDialog
        filter={tempFilter}
        selectItem={tempSelectItem}
        filterOpened={filterOpened}
        changeSelectPhysicians={handlePhysicianSelect}
        getPhysicians={getPhysicians}
        changeDate={handleChangeDate}
        onClearReportDate={handleClearReportDate}
        onClearCollectionDate={handleClearCollectionDate}
        onClear={handleClearFilter}
        onApply={handleApplyFilter}
        onClose={handleCloseFilterDialog}
      />
    </>
  );
};
