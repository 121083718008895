import {Reference} from '@src/api';

export const getNameReference = (reference: Reference, units: string | null) => {
  const minValue = reference?.minValue;
  const maxValue = reference?.maxValue;
  const minValueIsNull = minValue == null;
  const maxValueIsNull = maxValue == null;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && !maxValueIsNull) return `${minValue}-${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (minValueIsNull && !maxValueIsNull) return `<${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && maxValueIsNull) return `>${minValue}`;
};
