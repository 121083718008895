import {Typography} from '@mui/material';
import {WBox} from '@src/components';
import {Dialog, DialogActions, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {useMQuery} from '@src/shared/hooks';
import {storageActions} from '@src/store/storage/slice';
import {Steps} from '@src/types';
import {useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {FavoritesDialogModalProps} from './types';

export const FavoritesDialog = ({open}: FavoritesDialogModalProps) => {
  const {t, ready} = useTranslate('storage');
  const {mobile: isMobile} = useMQuery();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(storageActions.setStep({step: Steps.INIT}));
    close();
  };

  const modalSize = isMobile ? 'xs' : 'sm';

  if (!ready) return null;
  return (

    <Dialog
      sx={sx.dialogError}
      border='none'
      open={open}
      onClose={closeModal}
      size={modalSize}>
      <DialogTitle
        width='64px'
        onClose={closeModal}
        noContent={false}
        sx={sx.dialogTitleError}>
        <Error />
        {t('ERROR')}
      </DialogTitle>
      <WBox sx={sx.confirmDeleteContent}>
        <Typography variant="14_18_500">
          {t('FAILED_DELETE_FROM_FAVORITES')}
        </Typography>
      </WBox>
      <DialogActions
        sx={sx.dialogActionsError}
        onClose={closeModal}
      >
        <Button
          fullWidth={isMobile}
          sx={sx.bth}
          variant="contained"
          color="inherit"
          onClick={closeModal}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
