import {Stack, Typography} from '@mui/material';
import {Breadcrumbs, Link, Text, WBox} from '@src/components';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {IncomingRequests} from '@src/pages/Connections/fragments/Accesses/Requests/fragments/IncomingRequests';
import {OutgoingRequests} from '@src/pages/Connections/fragments/Accesses/Requests/fragments/OutgoingRequests';
import {RequestAccessBtn} from '@src/pages/Connections/fragments/Accesses/common/RequestAccessBtn';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useAccessesRequests} from '@src/store/accesses/hooks';
import {AccountType} from '@src/types';
import {useLocation, useNavigate} from 'react-router-dom';

import {sx} from './styles';

export const AccessesRequestsMobileLayout = () => {
  const {t, ready} = useTranslate('accesses');
  const {mobile: isMobile} = useMQuery();

  const {incoming, outgoing} = useAccessesRequests();

  const {state} = useLocation();
  const navigate = useNavigate();

  const currentAccessType: TKeys<'accesses'> = state.accessesTypeForMobile === AccountType.Incoming ? 'INCOMING' : 'OUTGOING';

  const noCurrentRequests = () => {
    if (state.accessesTypeForMobile === AccountType.Incoming) {
      return !incoming?.length;
    }
    return !outgoing?.length;
  };

  if (!isMobile) {
    navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES);
  }

  if (!ready) return null;

  return (
    <Stack gap={18}>
      <Breadcrumbs>
        <Link sx={sx.link} to={ROUTERS_PATH.CONNECTIONS_ACCESSES}>
          {t(currentAccessType)}
        </Link>
        <Text sx={sx.text}>{t('REQUESTS')}</Text>
      </Breadcrumbs>
      <Stack sx={sx.container}>
        <Stack sx={noCurrentRequests() ? sx.requestsContainerEmpty : sx.requestsContainer}>
          <Typography
            variant='22_26_500'>
            {t('REQUESTS')}
          </Typography>
        </Stack>
        {state.accessesTypeForMobile === AccountType.Incoming
          ? <IncomingRequests data={incoming} />
          : <OutgoingRequests data={outgoing} />
        }
      </Stack>
      <WBox sx={sx.wBoxContainer}>
        <RequestAccessBtn />
      </WBox>
    </Stack>
  );
};
