import {useMediaQuery, useTheme} from '@mui/material';

export const useMQuery = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletPortrait = useMediaQuery(theme.breakpoints.only('sm'));
  const tabletLandscape = useMediaQuery(theme.breakpoints.only('md'));
  const tabletWorkaround = useMediaQuery(theme.breakpoints.down('lg'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const xxl = useMediaQuery(theme.breakpoints.only('xxl'));
  const tbl = tabletWorkaround && !mobile;
  const definitions = {
    mobile,
    tablet: tbl,
    tabletPortrait,
    tabletLandscape,
    desktop: !mobile && !tbl,
    lg,
    xl,
    xxl,
  };
  return definitions;
};
