import {IconButton, Stack, Typography} from '@mui/material';
import {HealthcareInsurancePolicy} from '@src/api';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper/ListWrapper';
import {PulseLoader} from '@src/components/PulseLoader';
import {WBox} from '@src/components/WhiteBox';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Edit} from '@src/shared/assets/icons/20x20/edit_grey.svg';
import {ReactComponent as Trash} from '@src/shared/assets/icons/24x24/trash-gray.svg';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {useMQuery} from '@src/shared/hooks';
import {NO_VALUE, dateFormatted} from '@src/shared/utils';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {useInsurances} from '@src/store/insurance/hooks';
import {TKeys, useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {COUNT_INSURANCE} from './constants';
import {sx} from './styles';

interface InsuranceContentProps {
  setId: (id?: string) => void
  activeInsurance: HealthcareInsurancePolicy[] | undefined
};

export const InsuranceContent: FC<InsuranceContentProps> = ({setId, activeInsurance}) => {
  const {t, ready} = useTranslate('insurance');
  const navigate = useNavigate();
  const {mobile, tablet} = useMQuery();
  const {healthcareInsurancePolicies, fetching} = useInsurances();

  const goToInsuranceEdit = (id: HealthcareInsurancePolicy['id']) => {
    if (!id) {
      return;
    }

    const path = generatePath(ROUTERS_PATH.EDIT_INSURANCE, {
      id,
    });
    navigate(path);
  };

  if (fetching || !ready) {
    return (
      <WBox sx={sx.whiteBox}>
        <Typography sx={{pb: '24px'}} variant={mobile ? '22_26_500' : '24_34_500'}>
          {ready ? t('PRIMARY_INSURANCE') : ''}
        </Typography>
        <PulseLoader loading={true} />
      </WBox>
    );
  }

  return (
    <>
      {(healthcareInsurancePolicies?.length && healthcareInsurancePolicies?.length > 0) && (
        <Stack sx={sx.contentWrapper}>
          {healthcareInsurancePolicies.map((insurance) => (
            <WBox key={insurance.id} sx={sx.whiteBox}>
              {ready && (
                <Stack sx={sx.content}>
                  <Stack sx={sx.insuranceWrapper}>
                    <Stack sx={sx.listHeaderWrapper}>
                      <Typography variant={mobile ? '22_26_500' : '24_34_500'}>
                        {insurance.primary ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                      </Typography>
                      <Stack sx={sx.wrapperEditButton}>
                        {insurance.primary && !activeInsurance?.find((insurance) => insurance.primary)
                          ? null
                          : (
                            <IconButton
                              sx={sx.icon}
                              onClick={() => goToInsuranceEdit(insurance.id)}
                              disableRipple
                            >
                              <Edit />
                            </IconButton>
                          )
                        }
                        <IconButton
                          sx={sx.icon}
                          onClick={() => setId(insurance.id)}
                          disableRipple
                        >
                          <Trash />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <ListWrapper padding={mobile ? '12px' : '16px'} noLast={{xl: 0, lg: 0, xs: 0}}>
                      <RowInformation
                        name={t('CARRIER')}
                        value={insurance.insurancePlan?.carrier?.name}
                        noMargin
                        tooltip
                      />
                      <RowInformation
                        name={t('PLAN')}
                        value={insurance.insurancePlan?.name}
                        noMargin
                        tooltip
                      />
                      <RowInformation name={t('POLICY_ID')} value={insurance.number} noMargin />
                      <RowInformation name={t('END_DATE')} value={dateFormatted(insurance.endDate)} noMargin />
                    </ListWrapper>
                  </Stack>
                  <Stack sx={sx.guarantorWrapper}>
                    <Typography variant="18_24_500">{t('GUARANTOR')}</Typography>
                    <Stack sx={sx.guarantorContentWrapper}>
                      <Stack flexGrow={1}>
                        <ListWrapper
                          padding={mobile ? '12px' : '16px'}
                          noLast={{xl: 0, lg: 0, xs: 0}}
                        >
                          <RowInformation
                            value={t(insurance.guarantor?.relationType)}
                            name={t('RELATIONSHIP')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.firstName}
                            name={t('FIRST_NAME')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.lastName}
                            name={t('LAST_NAME')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.middleName}
                            name={t('MIDDLE_NAME')}
                            noMargin
                          />
                          <RowInformation
                            value={dateFormatted(insurance.guarantor?.birthDate ?? NO_VALUE)}
                            name={t('DATE_OF_BIRTH')}
                            noMargin
                          />
                          <RowInformation
                            value={t(insurance.guarantor?.birthSex)}
                            name={t('BIRTH_SEX')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.ssnTail}
                            name={t('SSN_LAST_4')}
                            noMargin
                          />
                        </ListWrapper>
                      </Stack>
                      <Stack flexGrow={1}>
                        <ListWrapper padding={mobile || tablet ? '12px' : '16px'}>
                          <RowInformation
                            value={t(insurance.guarantor?.state as TKeys<'common'>)}
                            name={t('STATE')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.city}
                            name={t('CITY')}
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.main}
                            name={t('ADDRESS_1')}
                            noWrap
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.additional}
                            name={t('ADDRESS_2')}
                            noWrap
                            noMargin
                          />
                          <RowInformation
                            value={insurance.guarantor?.postalCode}
                            name={t('ZIP_CODE')}
                            noMargin
                          />
                          <RowInformation
                            value={formatPhoneNumber(insurance.guarantor?.phone)}
                            name={t('PHONE')}
                            noMargin
                          />
                        </ListWrapper>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </WBox>
          ))}
        </Stack>
      )}
      {!healthcareInsurancePolicies?.length &&
        (
          <WBox sx={sx.whiteBox}>
            <>
              <Stack gap={24}>
                <Typography variant={mobile ? '22_26_500' : '24_34_500'}>
                  {t('PRIMARY_INSURANCE')}
                </Typography>
                <Typography
                  variant={tablet || mobile ? '12_18_500' : '16_24_400'}
                  component="p"
                  sx={sx.noInsuranceDescription}
                >
                  {t('NO_INSURANCE_PLANS_WERE_FOUND')}
                </Typography>
              </Stack>
              {mobile && (healthcareInsurancePolicies?.length ?? 0) < COUNT_INSURANCE && (
                <WBox sx={sx.addInsuranceButtonOnMobile}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                  >
                    {t('ADD_INSURANCE')}
                  </Button>
                </WBox>
              )}
            </>
          </WBox>
        )
      }
    </>
  );
};
