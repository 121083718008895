import {Typography} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {useMQuery} from '@src/shared/hooks';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {ErrorModalProps} from './types';

export const ErrorModal = ({open, close}: ErrorModalProps) => {
  const {t} = useTranslate('connections');

  const {mobile: isMobile} = useMQuery();

  const modalSize = () => {
    if (isMobile) return 'xs';
    return '482px';
  };

  return (
    <Dialog
      sx={sx.dialog}
      open={open}
      onClose={close}
      size={modalSize()}>
      <DialogTitle
        width='50px'
        onClose={close}
        noContent={false}
        sx={sx.dialogTitile}>
        {<Error />}
        {t('ERROR_EXCLAMATION')}
      </DialogTitle>
      <DialogContent
        sx={sx.dialogContent}
      >
        <Typography variant="14_18_500">
          {t('SOMETHING_WENT_WRONG')}
        </Typography>
      </DialogContent>
      <DialogActions
        color="red"
        sx={sx.dialogActions}
        onClose={close}
      >
        <Button
          fullWidth={isMobile}
          sx={sx.bthOk}
          variant="contained"
          color="inherit"
          onClick={close}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
