import {
  Biomarker,
  BiomarkerUnit,
  FetchBiomarkersParams,
  FetchBiomarkersResponse,
  FetchStorageFileThumbnailsBody,
  StorageBiomarker,
  StorageFile,
  StorageFileCreateProps,
  StorageFileData,
  StorageFileDetails,
  StorageFileThumbnail,
  StorageFileUpdateProps,
} from '@src/api';
import {apiClient} from '@src/client/ApiClient';

import {FetchStorageFilesRequestParams, FetchStorageFilesResponse} from './models';

const STORAGE_URL = '/api/storage';

export const getStorageFiles = async (params: FetchStorageFilesRequestParams) => {
  const response = await apiClient.get<FetchStorageFilesResponse>(`${STORAGE_URL}/search`, {
    params,
  });
  return response.data;
};

export const updateStorageFile = async ({id, file, ...data}: StorageFileUpdateProps) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  if (file) {
    formData.append('file', file);
  }

  const response = await apiClient.post<StorageFile>(`${STORAGE_URL}/update/${id}`, formData);
  return response.data;
};

export const deleteStorageFile = async (id: StorageFile['id']) => {
  const response = await apiClient.post(`${STORAGE_URL}/delete/${id}`);
  return response.status;
};

export const getStorageFileData = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileData>(`${STORAGE_URL}/${id}`);
  return response.data;
};

export const getStorageFileDetails = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileDetails>(`${STORAGE_URL}/${id}/details`);
  return response.data;
};

export const getStorageFileThumbnails = async (data: FetchStorageFileThumbnailsBody) => {
  const response = await apiClient.post<StorageFileThumbnail[]>(`${STORAGE_URL}/fn/get-all-thumbnails`, data);
  return response.data;
};

export const getBiomarkers = async (params: FetchBiomarkersParams) => {
  const response = await apiClient.get<FetchBiomarkersResponse>('/api/biomarkers', {
    params,
  });
  return response.data;
};

export const getBiomarkersUnits = async (data: { requests: Array<Biomarker['id']> }) => {
  const response = await apiClient.post<BiomarkerUnit[]>(
    '/api/biomarkers/fn/get-biomarker-units-by-biomarker-ids',
    data,
  );
  return response.data;
};

export const createStorageFile = async ({file, ...data}: StorageFileCreateProps) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('file', file);

  const response = await apiClient.post<StorageFile>(`${STORAGE_URL}/create`, formData);
  return response.data;
};

export const shareStorageFiles = async (storageIds: Array<StorageFile['id']>) => {
  const response = await apiClient.post<{url: string}>(`${STORAGE_URL}/share`, {storageIds});
  return response.data;
};

export const deleteStorageBiomarker = async (id: StorageBiomarker['id']) => {
  await apiClient.delete(`${STORAGE_URL}/biomarkers/${id}`);
  return true;
};

export * as storageApi from './';
export * from './models';
