import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 24,
    alignItems: 'center',
  },
  title: {
    typography: {xs: '22_26_500', sm: '24_34_500'},
  },
  boxContainer: (t) => ({
    display: 'flex',
    alignItems: 'center',
    gap: {xs: 4, sm: 10},
    color: t.palette.grey[500],
    svg: {
      width: 18,
      height: 18,
      path: {
        fill: 'currentcolor',
      },
    },
  }),
};
