import {Typography, Stack, Box, Avatar} from '@mui/material';
import {RelationContactSimilarErrorType} from '@src/api/relations';
import {RowInformation} from '@src/components';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Attention} from '@src/shared/assets/icons/attention.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getUserInitials} from '@src/shared/utils';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getName} from '@src/shared/utils/getName';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {relationsActions} from '@src/store/relations/slice';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'ui-kit';

import {AddRelationTableAction} from '../../../AddRelationTable/AddRelationTableAction';

import {useModalContentMap} from './hooks';
import {calculateMaxHeight, calculatePaddings} from './styled';
import {sx} from './styles';
import {IFailureModal} from './types';

export const FailureModal = ({
  failureCreatedContact,
  relationType,
  setTouched,
  handleCreateRelationContact,
}: IFailureModal) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  const dispatch = useDispatch();

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState<boolean>(true);

  const {results, type} = failureCreatedContact;

  const closeFailureModal = () => {
    setIsSuccessModalOpened(false);
    setTouched({});
    dispatch(relationsActions.setFailureCreatedContact({failureCreatedContact: null}));
  };

  const {modalContentMap} = useModalContentMap();

  const modalSize = () => {
    if (isMobile) return 'xs';
    if (isTablet) return 'md';
    return 'lg';
  };

  useEffect(() => {
    setIsSuccessModalOpened(true);
  }, [failureCreatedContact]);

  const textInfo = modalContentMap[type as RelationContactSimilarErrorType];
  const foundPatient = results;

  const rowInfoNameTypography = isMobile ? '12_16_700' : '14_18_700';
  const rowInfoValueTypography = isMobile ? '12_16_500' : '14_18_500';

  const isUniqueConfirmedPhoneError = type === RelationContactSimilarErrorType.UNIQUE_CONFIRMED_PHONE;
  const isUniqueUnconfirmedPhoneError = type === RelationContactSimilarErrorType.UNIQUE_UNCONFIRMED_PHONE;

  return (
    <Dialog
      sx={{'.MuiPaper-root': {maxHeight: !isUniqueConfirmedPhoneError ? {xs: 536, sm: 784} : {xs: 546, sm: 806}}}}
      border={theme => `1px solid ${theme.palette.secondary._14 ?? ''}`}
      open={isSuccessModalOpened}
      onClose={closeFailureModal}
      size={modalSize()}>
      <DialogTitle width='62px' onClose={closeFailureModal} noContent={false}>
        <Typography variant="18_24_700">{t('DUPLICATE_FOUND')}</Typography>
      </DialogTitle>
      <DialogContent
        sx={sx.dialogContent}
      >
        <Stack>
          {isUniqueConfirmedPhoneError
            ? (
              <Box sx={sx.boxContainer}>
                <Box sx={sx.attentionInfoContainer}>
                  <Attention />
                  <Typography variant={isMobile ? '12_16_700' : '14_18_700'} >{textInfo}</Typography>
                </Box>
              </Box>
            )
            : (
              <Box p={{xs: '24px 18px', sm: 24}}>
                <Typography variant="16_20_700" >{textInfo}</Typography>
              </Box>
            )}
          <Stack
            sx={[
              ...spreadSx(sx.infoWrapper),
              {
                p: calculatePaddings(isUniqueConfirmedPhoneError, foundPatient),
                maxHeight: calculateMaxHeight(isUniqueConfirmedPhoneError, foundPatient),
                gap: {sm: foundPatient.length > 2 ? 25 : 24},
              },
            ]}>
            {foundPatient.map((patient, index) => (
              <Box key={index} sx={sx.boxInfoContainer}>
                <Stack sx={sx.infoContainer}>
                  <Avatar sx={sx.avatar} src={patient?.accountAvatarUrl}>
                    <Typography variant="16_20_700">
                      {getUserInitials(patient)}
                    </Typography>
                  </Avatar>
                  <Stack mt={3} minWidth='10%'>
                    <EllipsisTextTooltip variant='14_18_700' ellipsisText={getName(patient, {short: true})}/>
                    <EllipsisTextTooltip variant='12_16_500' ellipsisText={patient?.birthDateS}/>
                  </Stack>
                </Stack>
                <Stack p={{xs: '0 18px', sm: '0 24px'}}>
                  <ListWrapper padding='8px' noLast={{xs: 0}}>
                    <RowInformation
                      spaceBetween={8}
                      tooltip
                      name={t('PHONE')}
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      value={formatPhoneNumber(patient?.phone)}
                      noMargin/>
                    <RowInformation
                      spaceBetween={isMobile ? 8 : 12}
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      tooltip
                      name={t('EMAIL')}
                      value={patient?.email}
                      noMargin/>
                    <RowInformation
                      spaceBetween={isMobile ? 8 : 12}
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      tooltip
                      name={t('SSN_LAST_4')}
                      value={patient?.ssnTail}
                      noMargin/>
                    <RowInformation
                      spaceBetween={isMobile ? 8 : 12}
                      variant={rowInfoNameTypography}
                      valueTypographyVariant={rowInfoValueTypography}
                      tooltip
                      name={t('POLICY_ID')}
                      value={patient?.policyNumber}
                      noMargin/>
                  </ListWrapper>
                </Stack>
                <Stack sx={sx.flexContainer}>
                  <AddRelationTableAction
                    isModal
                    variant='text'
                    id={patient?.id}
                    relationError={patient?.relationError}
                    newContactBirthDate={patient.birthDateS}
                    relationshipType={relationType || null}
                    closeModal={closeFailureModal}
                  />
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      {isUniqueUnconfirmedPhoneError
        ? (
          <DialogActions
            sx={sx.dialogActions}
          >
            <Button
              fullWidth={isMobile}
              variant="outlined"
              color="secondary"
              sx={sx.bthCancel}
              onClick={() => {
                closeFailureModal();
              }}
            >
              {t('CANCEL')}
            </Button>
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="secondary"
              sx={sx.bthCreatePatient}
              onClick={() => {
                handleCreateRelationContact();
                closeFailureModal();
              }}
            >
              {t('CREATE_A_NEW_PATIENT')}
            </Button>
          </DialogActions>
        )
        : (
          <DialogActions
            sx={sx.dialogActionsFailure}
          >
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="secondary"
              onClick={() => {
                closeFailureModal();
              }}
            >
              {t('BACK')}
            </Button>
          </DialogActions>
        )}

    </Dialog>
  );
};
