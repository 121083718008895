import {
  storageApi,
  StorageFile,
  StorageFileData,
  StorageFileDetails,
  StorageFileThumbnail, StorageFileUpdateBody,
  StorageThumbnailType,
} from '@api';
import {getBase64DisplayLink} from '@src/pages/StorageDocument/helpers';
import {encodeImageFileAsURL, removeDataTypePrefix} from '@src/shared/utils';
import {updateStorageFile} from '@src/store/storage/slice';
import {useAppDispatch} from '@store';
import {useEffect, useState} from 'react';

export const useFileData = (id?: StorageFile['id']) => {
  const [fileDetails, setFileDetails] = useState<StorageFileDetails>();
  const [fileData, setFileData] = useState<StorageFileData>();
  const dispatch = useAppDispatch();

  const handleFetchFileDetails = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await storageApi.getStorageFileDetails(id);
      setFileDetails(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFetchFileData = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await storageApi.getStorageFileData(id);
      setFileData(response);
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadFile = async () => {
    let downloadedData = fileData;
    if (!downloadedData) {
      downloadedData = await handleFetchFileData();
    }
    if (!downloadedData) {
      return;
    }
    const a = document.createElement('a');
    a.href = getBase64DisplayLink(downloadedData.contentType, downloadedData.data);
    a.download = fileDetails?.name || 'file';
    a.click();
    a.remove();
  };

  const handleToggleFavorite = () => {
    if (!fileDetails) {
      return;
    }
    void dispatch(updateStorageFile({...fileDetails, favorite: !fileDetails.favorite})).unwrap().then((file) => {
      setFileDetails({...fileDetails, favorite: file.favorite});
    });
  };

  const handleUpdateFile = async (updateBody: StorageFileUpdateBody, file?: File) => {
    if (!fileDetails) {
      return;
    }

    try {
      const deletedBiomarkers = fileDetails.biomarkers.filter(biomarker => !updateBody.editBiomarkers?.find(editBiomarker => biomarker.id === editBiomarker.id));
      const deleteBiomarkerPromises = deletedBiomarkers.map(biomarker => storageApi.deleteStorageBiomarker(biomarker.id));
      await Promise.all(deleteBiomarkerPromises);
    } catch (e) {
      console.error(e);
    }

    await dispatch(updateStorageFile({...updateBody, id: fileDetails.id, file})).unwrap().then(() => {
      if (file) {
        void encodeImageFileAsURL(file).then((base64) => {
          const base64WithoutType = removeDataTypePrefix(base64);
          setFileData({
            contentType: file.type,
            data: base64WithoutType,
          });
        });
      }
    });
    await handleFetchFileDetails();
  };

  useEffect(() => {
    void handleFetchFileDetails();
  }, []);

  return {fileDetails, handleDownloadFile, handleToggleFavorite, handleUpdateFile, handleFetchFileData, fileData};
};

export const useFileFirstThumbnail = ({
  id,
  type,
}: {
  id?: StorageFileDetails['id']
  type: StorageThumbnailType
}) => {
  const [fileThumbnail, setFileThumbnail] = useState<StorageFileThumbnail>();
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchThumbnails = async () => {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await storageApi.getStorageFileThumbnails({
        fileIds: [id],
        types: [type],
      });
      if (response.length) {
        setFileThumbnail(response[0]);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    void handleFetchThumbnails();
  }, [id]);

  return {fileThumbnail, isLoading, handleFetchThumbnails};
};
