import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: theme => ({
    background: theme.colors.grayBackground,
    border: '1px solid',
    borderColor: theme.colors.blueCardBorder,
    transition: '0.2s',
    justifyContent: 'space-between',
    '@keyframes colorChange': {
      '0%': {
        backgroundColor: theme.colors.grayBackground,
      },
      '50%': {
        backgroundColor: theme.colors.blueCardBorder,
      },
      '100%': {
        backgroundColor: theme.colors.grayBackground,
      },
    },
  }),
  mainInfo: {
    padding: {
      xs: '6px 9px 9px 18px',
      sm: '6px 12px 24px 18px',
    },
    gap: 6,
  },
  name: {
    pt: 6,
    whiteSpace: 'wrap',
    wordBreak: 'break-all',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  buttonsContainer: theme => ({
    borderTop: '1px solid',
    borderColor: theme.colors.blueCardBorder,
    padding: {
      xs: '3px 6px 3px 9px',
      sm: '12px 18px',
    },
  }),
  actionButton: {
    padding: 0,
    typography: '16_20_600',
    display: 'inline-flex',
    minWidth: 'unset',
    height: 20,
    '.MuiButton-startIcon': {
      width: 18,
      height: 18,
    },
  },
  iconButton: {
    svg: {
      width: 24,
      height: 24,
    },
  },
};
