import { useEffect, useState } from "react";

export const useStreetNumber = (address: string) => {
  const [streetNumber, setStreetNumber] = useState<string | null>(null);

  useEffect(() => {
    if (!address) {
      setStreetNumber(null);
      return;
    }

    const streetNumberMatch = address.match(/\b\d+\b/);

    if (streetNumberMatch) {
      setStreetNumber(streetNumberMatch[0]);
    } else {
      setStreetNumber(null);
    }
  }, [address]);

  return streetNumber;
};
