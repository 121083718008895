import {Stack, Typography, debounce, Box, ListItemText} from '@mui/material';
import {InsurancePlansList} from '@src/api/insurances';
import {RelationshipType} from '@src/api/relations';
import Grid from '@src/components/Grid';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH, touchedData} from '@src/pages/Connections/constants';
import {SearchSelect} from '@src/pages/Connections/fragments/Relations/helpers/SearchSelect';
import {sortInsurances} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {setGuarantorFieldsBySelf} from '@src/pages/Connections/fragments/Relations/helpers/setGuarantorFieldsBySelf';
import {ROUTERS_PATH} from '@src/routers';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_INPUT_LENGTH, MAX_POLICY_ID_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {insuranceInitialValue} from '@src/shared/constants/insuranceInitialValue';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {getInsurancePlanError} from '@src/shared/utils/getInsurancePlanError';
import {getPolicyIdError} from '@src/shared/utils/getPolicyIdError';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {validateValue} from '@src/shared/utils/validateValue';
import {useAppSelector} from '@src/store';
import {
  useInsuranceCarriers,
  useInsurancePlans,
} from '@src/store/insurance/hooks';
import {insuranceActions} from '@src/store/insurance/slice';
import {useContactRelations, useHealthPoliciesForm, useRelation} from '@src/store/relations/hooks';
import {RelationshipTypes} from '@src/types';
import {Formik, FormikHelpers} from 'formik';
import {isEqual} from 'lodash-es';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {PhoneInputControl, Button, SelectControl, Option, InputControl} from 'ui-kit';

import {
  birthSexSelectOptions,
  relationshipTypeSelectOptions,
  statesSelectOptions,
} from '../../RelationEditLayouts/constants';

import {getInsuranceCarrierError} from './helpers';
import {StyledForm, sx} from './styles';
import {saveData} from './utils/saveData';
import {useValidationSchema} from './utils/useValidationSchema';

// eslint-disable-next-line

export const RelationHealthPoliciesAdd = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const {edit} = useHealthPoliciesForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {getInsuranceCarriers} = useInsuranceCarriers();
  const insuranceCarriers = useAppSelector((state) => state.insurance.insuranceCarriers);
  const validationSchema = useValidationSchema({relation});
  const [isSubmit, setSubmit] = useState(false);
  const [searchPrimaryText, setSearchPrimaryText] = useState<string>('');
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlansList[] | null>([]);
  const [insuranceCarrierId, setInsuranceCarrierId] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState<string>('');

  const insurance = sortInsurances(relation?.healthcareInsurancePolicies);
  const activeInsurance = insurance?.filter((insurance) => compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null);
  const isPrimaryInsurance = activeInsurance?.length && !!activeInsurance.find((insurance) => insurance.primary);

  const columnSpacingValue = isDesktop ? 48 : 36;
  const spacingValue = isMobile ? 18 : 24;

  const healthPoliciesRoute = generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES, {id: id || ''});

  const handlePrimaryTextChange = (value: string) => {
    setSearchPrimaryText(value);
  };

  const handleClickBtnCancel = (resetForm: FormikHelpers<typeof insuranceInitialValue>['resetForm']) => {
    navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES, {id: id || ''}));
    resetForm();
  };

  const handleClickBtnSave = (
    setTouched: FormikHelpers<typeof insuranceInitialValue>['setTouched'],
    handleSubmit: VoidFunction,
  ) => {
    setTouched(touchedData);
    handleSubmit();
    setSubmit(true);
  };

  const handleChangeRelationship = (
    e: { target: { value: string } },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    handleSetFieldsValues: (event: { target: { value: string } }) => void,
  ) => {
    setFieldValue('relationship', e.target.value, true);
    handleSetFieldsValues(e);
    if (e.target.value === RelationshipTypes.SELF) {
      setGuarantorFieldsBySelf(setFieldValue, relation);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPrimaryChangeHandler = useCallback(debounce(handlePrimaryTextChange, 300), []);
  const {insurancePlans: plans} = useInsurancePlans(insuranceCarrierId);

  const memoInsuranceCarriersOptions = useMemo(() => {
    return insuranceCarriers?.map((option) => option.name);
  }, [insuranceCarriers]);

  const setCarrier = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setSelectedCarrier(value);
    setFieldValue('insuranceCarrier', value);
    if (setFieldValue && value !== selectedCarrier) setFieldValue('insurancePlan', null);
  };

  const handleClearOptions = () => {
    dispatch(insuranceActions.clearInsuranceCarriers());
  };

  useEffect(() => {
    if (selectedCarrier) {
      setInsurancePlans(plans);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  useEffect(() => {
    const selectedInsuranceCarrier = insuranceCarriers?.find(
      (carrier) => carrier.name === selectedCarrier,
    );
    if (selectedInsuranceCarrier) setInsuranceCarrierId(selectedInsuranceCarrier?.id);
  }, [selectedCarrier, insuranceCarriers]);

  useEffect(() => {
    if (searchPrimaryText) {
      getInsuranceCarriers(searchPrimaryText);
      setInsurancePlans(null);
      setInsuranceCarrierId('');
    }
  }, [getInsuranceCarriers, searchPrimaryText]);

  const gapMobile = isMobile ? 14 : 0;

  return (
    <Formik<typeof insuranceInitialValue>
      enableReinitialize
      onSubmit={(values) => {
        if (!isEqual(insuranceInitialValue, values)) {
          const normalizedValues = {
            ...values,
            firstName: normalizeString(values.firstName),
            lastName: normalizeString(values.lastName),
            middleName: normalizeString(values.middleName),
            city: normalizeString(values.city),
            address1: normalizeString(values.address1),
            address2: normalizeString(values.address2),
            policyId: normalizeString(values.policyId),
            relationship: values.relationship !== RelationshipTypes.SELF ? values.relationship : null,
          };

          saveData({
            ...normalizedValues,
            insurancePlanId:
              insurancePlans?.find((plan) => plan.name === values.insurancePlan)?.id || null,
            edit,
            isPrimaryInsurance,
            relationId,
          });
          navigate(healthPoliciesRoute);
        }
      }}
      initialValues={insuranceInitialValue}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
        resetForm,
        setTouched,
      }) => {
        const handleSetFieldsValues = (event: { target: { value: string } }) => {
          const selectedValue = event.target.value;

          if (selectedValue === RelationshipType.Self) {
            setFieldValue('firstName', values.firstName || '', true);
            setFieldValue('lastName', values.lastName || '', true);
            setFieldValue('middleName', values.middleName || '', true);
            setFieldValue('birthDate', values.birthDate || '', true);
            setFieldValue('birthSex', values.birthSex || '', true);
            setFieldValue('state', values.state || '', true);
            setFieldValue('city', values.city || '', true);
            setFieldValue('address1', values.address1 || '', true);
            setFieldValue('address2', values.address2 || '', true);
            setFieldValue('postalCode', values.postalCode || '', true);
            setFieldValue('phone', values.phone || '', true);
          } else {
            setFieldValue('firstName', '', true);
            setFieldValue('lastName', '', true);
            setFieldValue('middleName', '', true);
            setFieldValue('birthDate', '', true);
            setFieldValue('birthSex', '', true);
            setFieldValue('state', '', true);
            setFieldValue('city', '', true);
            setFieldValue('address1', '', true);
            setFieldValue('address2', '', true);
            setFieldValue('postalCode', '', true);
            setFieldValue('phone', '', true);
          }
        };

        const isLongError = (errors?.birthDate === t('ENTER_A_VALID_DATE_OF_BIRTH')) && isMobile;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (values.relationship) {
            handleSetFieldsValues(
              {target: {value: values.relationship}},
            );
          }
        }, [values.relationship]);

        return (
          <StyledForm>
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.mainContainer}>
                <Stack sx={sx.flexContainer}>
                  <Typography sx={sx.insuranceTitle}>
                    {isPrimaryInsurance ? t('SECONDARY_INSURANCE') : t('PRIMARY_INSURANCE')}
                  </Typography>
                  <Grid
                    container
                    spacing={spacingValue}
                    columnSpacing={columnSpacingValue}
                    gap={gapMobile}
                  >
                    <Grid
                      xl={4}
                      lg={6}
                      sm={6}
                      xs={12}>
                      <SearchSelect
                        value={values.insuranceCarrier || ''}
                        isLabelTop
                        placeholder={t('SELECT')}
                        label={t('INSURANCE_CARRIER')}
                        debouncedChangeHandler={debouncedPrimaryChangeHandler}
                        memoOptions={memoInsuranceCarriersOptions}
                        searchText={searchPrimaryText}
                        setSelectedCarrier={(value: string) => setCarrier(value, setFieldValue)}
                        clearOptions={handleClearOptions}
                        error={getInsuranceCarrierError({touched, values, errors})}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        sx={sx.plan}
                        placeholder={t('SELECT')}
                        disabled={!insurancePlans?.length}
                        name="insurancePlan"
                        value={t(values.insurancePlan) || ''}
                        error={getInsurancePlanError({touched, values, errors, t})}
                        onChange={handleChange}
                        label={t('INSURANCE_PLAN')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {insurancePlans?.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      sm={6}
                      xs={12}>
                      <InputControl
                        name="policyId"
                        label={t('POLICY_ID')}
                        value={values.policyId || ''}
                        error={getPolicyIdError({touched, values, errors, t})}
                        onChange={handleChange}
                        maxlength={MAX_POLICY_ID_LENGTH}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      sm={6}
                      xs={12}>
                      <DateControl
                        placeholder='mm/dd/yyyy'
                        labelTop
                        reverseYears
                        disabledPast
                        onlyFutureYears
                        disableCurrentDay
                        name="endDate"
                        helperText={t('OPTIONAL')}
                        value={dateToFormat('P', values.endDate) || ''}
                        onChange={handleChange}
                        onTouched={setFieldTouched}
                        label={t('END_DATE')}
                        error={touched.endDate ? errors.endDate : ''}
                        optional
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack gap={{xs: 18, sm: 24, lg: 36}}>
                  <Stack flexGrow={1} gap={{xs: 16, lg: 36}}>
                    <Box sx={{display: 'flex', gap: 24}}>
                      <Typography component="h4" variant="18_24_500" sx={sx.title}>
                        {t('GUARANTOR')}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={spacingValue}
                      columnSpacing={columnSpacingValue}
                      sx={{paddingBottom: `${gapMobile}`}}
                    >
                      <Grid
                        xl={4}
                        lg={6}
                        sm={6}
                        xs={12}>
                        <SelectControl
                          optional
                          placeholder={t('SELECT')}
                          name="relationship"
                          value={t(values.relationship) || ''}
                          error={touched?.relationship && !values.relationship ? errors.relationship : ''}
                          onChange={(e: any) => handleChangeRelationship(e, setFieldValue, handleSetFieldsValues)}
                          label={t('RELATIONSHIP')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {relationshipTypeSelectOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack gap={spacingValue}>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid
                        container
                        spacing={spacingValue}
                        columnSpacing={columnSpacingValue}
                        gap={gapMobile}
                      >
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="firstName"
                            label={t('FIRST_NAME')}
                            value={values.firstName || ''}
                            error={touched?.firstName ? errors.firstName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="lastName"
                            label={t('LAST_NAME')}
                            value={values.lastName || ''}
                            error={touched?.lastName ? errors.lastName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            optional
                            name="middleName"
                            label={t('MIDDLE_NAME')}
                            value={values.middleName || ''}
                            error={touched?.middleName ? errors.middleName : ''}
                            onChange={handleChange}
                            maxlength={MAX_INPUT_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}
                          pb={isLongError ? 16 : 0}
                        >
                          <DateControl
                            labelTop
                            placeholder='mm/dd/yyyy'
                            name="birthDate"
                            disabledFuture
                            value={dateToFormat('P', values.birthDate) || ''}
                            onChange={handleChange}
                            onTouched={setFieldTouched}
                            label={t('DATE_OF_BIRTH')}
                            hideTip={false}
                            errorTextStyles={{
                              whiteSpace: 'break-spaces',
                            }}
                            error={isSubmit && errors?.birthDate ? errors.birthDate : ''}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <SelectControl
                            placeholder={t('SELECT')}
                            name="birthSex"
                            value={t(values.birthSex || ('' as TKeys<'connections'>)) || ''}
                            error={touched?.birthSex && !values.birthSex ? errors.birthSex : ''}
                            onChange={handleChange}
                            label={t('BIRTH_SEX')}
                            renderValue={(value: any) => t(value as TKeys<'common'>)}
                          >
                            {birthSexSelectOptions.map(option => (
                              <Option key={option.name} value={option.name}>
                                <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                              </Option>
                            ))}
                          </SelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}
                          pb={isMobile ? 16 : 0}
                        >
                          <InputControl
                            type='number'
                            optional
                            name="ssnTail"
                            label={t('SSN_LAST_4')}
                            error={touched?.ssnTail ? errors.ssnTail : ''}
                            value={values.ssnTail || ''}
                            onChange={handleChange}
                            maxlength={MAX_SSN_LENGTH}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid
                        container
                        spacing={spacingValue}
                        columnSpacing={columnSpacingValue}
                        gap={gapMobile}
                      >
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <SelectControl
                            placeholder={t('SELECT')}
                            name="state"
                            value={t(values.state) || ''}
                            error={touched?.state && !values.state ? errors.state : ''}
                            onChange={handleChange}
                            label={t('STATE')}
                            renderValue={(value: any) => t(value as TKeys<'common'>)}
                          >
                            {statesSelectOptions.map(option => (
                              <Option key={option.name} value={option.name}>
                                <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                              </Option>
                            ))}
                          </SelectControl>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="city"
                            label={t('CITY')}
                            value={values.city || ''}
                            error={touched?.city ? errors.city : ''}
                            onChange={handleChange}
                            maxlength={MAX_CITY_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            simpleTextInput
                            name="postalCode"
                            label={t('ZIP_CODE')}
                            value={validateValue(values.postalCode || '')}
                            error={touched?.postalCode ? errors.postalCode : ''}
                            onChange={handleChange}
                            maxlength={POSTAL_CODE_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            name="address1"
                            label={t('ADDRESS_1')}
                            error={touched?.address1 ? errors.address1 : ''}
                            value={values.address1 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <InputControl
                            optional
                            name="address2"
                            label={t('ADDRESS_2')}
                            error={touched?.address2 ? errors.address2 : ''}
                            value={values.address2 || ''}
                            onChange={handleChange}
                            maxlength={MAX_ADDRESS_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={6}
                          sm={6}
                          xs={12}>
                          <PhoneInputControl
                            optional
                            name="phone"
                            label={t('PHONE')}
                            error={touched?.phone ? errors.phone : ''}
                            placeholder={t('ENTER_A_PHONE')}
                            value={values.phone || ''}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={sx.buttonsWrapper}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClickBtnCancel(resetForm)}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(insuranceInitialValue, values)}
                  sx={sx.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickBtnSave(setTouched, handleSubmit)}
                >
                  {t('SAVE')}
                </Button>
              </Stack>
            </WBox>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
