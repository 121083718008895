export interface StorageFile {
  date: string
  size: number
  name: string
  description: string
  id: string
  category: StorageFileCategory
  recognize: boolean
  type: StorageFileType
  favorite: boolean
  isNew?: boolean
}

export interface FetchStorageFilesResponse {
  hasNext: boolean
  items: StorageFile[]
}

export enum MenuTypes {
  ALL = 'ALL',
  RECENT = 'RECENT',
  FAVORITE = 'FAVORITE',
}

export enum StorageFileCategory {
  LABORATORY_REPORT = 'LABORATORY_REPORT',
  MEDICAL_RECORD = 'MEDICAL_RECORD',
  IMAGING_REPORT = 'IMAGING_REPORT',
  MEDICAL_IMAGE = 'MEDICAL_IMAGE',
  PRESCRIPTION = 'PRESCRIPTION',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export interface FetchStorageFilesRequestParams {
  phrase?: string
  category?: StorageFileCategory
  menu?: MenuTypes
  from?: string
  to?: string
  startPage?: number
  perPage: number
}

export interface StorageFileData {
  contentType: string
  data: string
}

export interface StorageBiomarker {
  unit: string | null
  biomarkerObj: { name: string, id: string }
  id: string
  biomarker: string
  biomarkerUnit?: { name: string, id: string }
  value: string
}

export enum StorageFileType {
  JPG = 'JPG',
  PNG = 'PNG',
  PDF = 'PDF',
}

export interface StorageFileDetails {
  date: string
  size: number
  biomarkers: StorageBiomarker[]
  name: string
  description: string
  id: string
  category: StorageFileCategory
  recognize: boolean
  type: StorageFileType
  favorite: boolean
}

export enum StorageThumbnailType {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface FetchStorageFileThumbnailsBody {
  fileIds: string[]
  types: StorageThumbnailType[]
}

export interface StorageFileThumbnail {
  data: StorageFileData
  id: string
  type: StorageThumbnailType
}

export interface EditBiomarker {
  biomarkerUnitId?: string | null
  id: string
  biomarkerId: string
  value: string
}
export interface CreateBiomarker {
  biomarkerUnitId?: string | null
  biomarkerId: string
  value: string
}

export interface StorageFileUpdateBody {
  date: string
  name: string
  description: string
  createBiomarkers?: CreateBiomarker[]
  editBiomarkers?: EditBiomarker[]
  category: string
  recognize: boolean
  favorite: boolean
}

export interface StorageFileUpdateProps extends StorageFileUpdateBody {
  id: StorageFile['id']
  file?: File
  errorText?: string
}

export interface StorageFileCreateBody extends Omit<StorageFileUpdateBody, 'editBiomarkers'> {}

export interface StorageFileCreateProps extends StorageFileCreateBody {
  file: File
}

export interface FetchBiomarkersParams {
  phrase?: string
  startPage?: number
  perPage: number
}

export interface Biomarker {
  id: string
  loinc?: string
  name: string
}

export interface FetchBiomarkersResponse {
  hasNext: boolean
  items: Biomarker[]
}

export interface BiomarkerUnit {
  id: string
  biomarkerId: string
  name: string
}
