import {List, ListItem, Typography, Box, InputAdornment, IconButton, TextField, Theme, FormHelperText} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ClearIcon} from '@src/shared/assets/icons/close-icon.svg';
import {getBoldText} from '@src/shared/utils/boldText';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';

import {sx} from './styles';
import {ComboBoxInputProps} from './types';

export const ComboBoxInput = ({
  value,
  label,
  error,
  placeholder,
  options = [],
  debouncedChangeHandler,
  setSelectedCarrier,
}: ComboBoxInputProps) => {
  const {t, ready} = useTranslate('common');

  const [inputValue, setInputValue] = useState(value || '');
  const [showError, setShowError] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const shouldShowDropdown = isDropdownOpen && inputValue;

  const checkIfValidOption = useCallback((value: string) => {
    return options.some(option => option.name.toLowerCase() === value.toLowerCase());
  }, [options]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    debouncedChangeHandler(newValue);
    setShowError(false);
    setIsDropdownOpen(true);

    if (!checkIfValidOption(newValue)) {
      setSelectedCarrier('');
    }
  };

  const handleOptionClick = (option: string) => {
    setSelectedCarrier(option);
    setInputValue(option);
    setIsDropdownOpen(false);
    setShowError(false);
  };

  const handleBlur = () => {
    if (isClearing) return;
    if (inputValue.trim() === '') {
      setShowError(false);
      return;
    }
    if (checkIfValidOption(inputValue)) {
      setShowError(false);
    } else {
      setShowError(true);
    }
    setIsDropdownOpen(false);
  };

  const handleClear = () => {
    setIsClearing(true);
    setInputValue('');
    setSelectedCarrier('');
    setShowError(false);
    setIsDropdownOpen(false);

    setTimeout(() => {
      setIsClearing(false);
    }, 100);
  };

  useEffect(() => {
    if (checkIfValidOption(inputValue)) {
      setSelectedCarrier(inputValue);
      setShowError(false);
      setIsDropdownOpen(false);
    }
  }, [inputValue, checkIfValidOption]);

  if (!ready) return null;

  return (
    <Box position={'relative'}>
      {label && <Typography sx={sx.label}>{label}</Typography>}
      <TextField
        fullWidth
        placeholder={placeholder || ''}
        value={inputValue}
        error={showError || !!error}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          sx: sx.searchInput,
          endAdornment: (
            <InputAdornment position="end">
              {inputValue && (
                <IconButton
                  sx={sx.clearIcon}
                  onClick={handleClear}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText error={showError || !!error} sx={sx.errorText}>
        {showError ? t('ENTER_A_VALID_CARRIER') : error || ''}
      </FormHelperText>
      {shouldShowDropdown && (
        <List sx={sx.container} onBlur={handleBlur}>
          {options.length > 0 && options.map((option, index) => (
            <ListItem
              key={index}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => handleOptionClick(option.name)}
              sx={(t: Theme) => {
                const isOptionSelected = inputValue.toLowerCase() === option.name.toLowerCase();
                return {
                  cursor: 'pointer',
                  backgroundColor: isOptionSelected
                    ? t.palette.grey[100]
                    : t.palette.common.white,
                  p: 12,
                  '&:hover': {
                    backgroundColor: t.palette.secondary[200],
                  },
                  '&:active': {
                    backgroundColor: t.palette.secondary[300],
                  },
                };
              }}
            >
              <Typography
                sx={sx.optionText}
                typography="14_18_500"
                dangerouslySetInnerHTML={{
                  __html: getBoldText(option.name, inputValue),
                }}
              />
            </ListItem>
          ))}
          {options.length === 0 && inputValue.length >= 2 && (
            <ListItem sx={sx.emptyText}>
              <Typography typography="14_18_500">
                {t('NO_RESULTS')}
              </Typography>
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};
