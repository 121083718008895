import {Address, BirthSex, Email, Ethnicity, Gender, HealthcareInsurancePolicy, PhoneTypeEnum, Race, RelationElement, SexualOrientation, State} from '@api';
import {
  NillableRelationType,
  NillableStateType,
} from '@src/api/insurances';
import {ISODate, RelationshipTypes, UUID} from '@src/types';
import {Nullable} from '@src/types/NullableModel';

export interface RelationProfile {
  accesses?: RelationAccountAccess[]
  actualAddresses: Nullable<Address>
  avatarUrl?: string
  contact: Nullable<ContactObject>
  emails?: Email[]
  healthcareInsurancePolicies?: HealthcareInsurancePolicy[]
  phones?: BasePhone[]
  relations?: RelationElement[]
}

export interface BasePhone {
  allowResultsDelivery?: boolean
  main?: boolean
  ownershipConfirmed?: boolean
  id: string
  phone?: string
  type?: PhoneTypeEnum
}

export interface Guarantor {
  additional?: string
  birthDate: ISODate
  birthSex?: BirthSex
  city?: string
  firstName: string
  id: string
  lastName: string
  main: string
  middleName?: string
  phone?: string
  postalCode?: string
  relationType?: RelationshipType
  ssnTail: string
  state?: State
}

export interface EditRelationContactData {
  birthDate: Record<string, string> | null
  birthSex: Record<string, string> | null
  ethnicity: Record<string, string> | null
  firstName: Record<string, string> | null
  gender: Record<string, string> | null
  lastName: Record<string, string> | null
  middleName: Record<string, string> | null
  race: Record<string, string> | null
  sexualOrientation: Record<string, string> | null
  ssnTail: Record<string, string> | null
};

export interface EditRelationAddressData {
  state: string | null
  city: string | null
  postalCode: string | null
  address1: string | null
  address2: string | null
};

export interface EditRelationHealthPoliciesData {
  deletedGuarantorId: string | null
  deletedInsuranceIds: string[] | null
  guarantorCreate: GuarantorCreate | null
  guarantorEdit: GuarantorEdit | null
  insurancesCreate: InsurancesCreate[] | null
};

export interface InsurancesCreate {
  endDate: Record<string, string> | null
  insurancePlanId: string | null
  number: string | null
  owner: boolean | null
  primary: boolean | null
}

interface GuarantorCreate {
  address1: Record<string, string | null> | null
  address2: Record<string, string | null> | null
  birthDate: string
  birthSex: string
  city: Record<string, string | null> | null
  state: Record<string, string | null> | null
  firstName: string
  lastName: string
  middleName: Record<string, string | null> | null
  phone: Record<string, string | null> | null
  postalCode: Record<string, string | null> | null
  relationType: string
  ssnTail: Record<string, string | null> | null
}

interface GuarantorEdit {
  address1: Record<string, string> | null
  address2: Record<string, string> | null
  birthDate: Record<string, string> | null
  birthSex: Record<string, string> | null
  city: Record<string, string> | null
  state: Record<string, string> | null
  firstName: Record<string, string> | null
  lastName: Record<string, string> | null
  middleName: Record<string, string> | null
  phone: Record<string, string> | null
  postalCode: Record<string, string> | null
  relationType: Record<string, string> | null
  ssnTail: Record<string, string> | null
}

export interface ContactObject {
  // NOTE @deprecated birthDate should not using. Use birthDateS
  birthDate: ISODate
  birthDateS?: string
  birthSex?: BirthSex
  ethnicity?: Ethnicity
  firstName: string
  gender?: Gender
  guarantorId: string
  id: string
  lastName: string
  middleName?: string
  race?: Race
  sexualOrientation?: SexualOrientation
  ssnTail: string
}

export interface RelationAccountAccess {
  access?: AccountAccessTypeEnum
  beginDate?: Date
  endDate?: Date
  id?: string
}

export interface Result {
  collected?: ISODate
  companyId: string
  flag?: FlagType
  id: UUID
  physician?: OrderingProvider
  reported?: ISODate
}

export interface OrderingProvider {
  firstName: string
  id: string
  lastName: string
  middleName: string
}

export interface RelationRequests {
  incoming: RelationRequest[]
  outgoing: RelationRequest[]
}

export interface RelationRequest {
  avatarUrl?: string
  contact?: RelationRequestContact
  createdDate?: string
  id?: string
  type?: RelationshipType
}

export interface RelationRequestContact {
  firstName?: string
  lastName?: string
  middleName?: string
}

export interface RelationContactSearch {
  filter: string
  perPage: number
  policyNumber?: string
  recaptcha?: string
  relationType: string
  startPage?: number
}

export interface RelationContactSearchResult {
  accountAvatarUrl?: string
  accountStatus: AccountStatus
  birthDate?: string
  birthDateS?: string
  email?: string
  firstName?: string
  id: string
  lastName?: string
  middleName?: string
  phone?: string
  policyNumber?: string
  relationError: BaseRelationError
  ssnTail?: string
}

export interface RelationRequestCreateRequest {
  contactId: string
  recaptcha?: string
  relationType: RelationshipType | RequestRelationType
}

export interface Page {
  hasNext?: boolean
  items?: RelationContactSearchResult[]
}

export type RelationRequestDuplicateError = BaseBaseRelationError & {
  createdDate?: string
};

interface BaseBaseRelationError {
  type: RelationErrorType
}

export type RelationDuplicateError = BaseBaseRelationError & {
  relationId?: string
};

type BaseBaseRelationErrorTypeMapping<Key, Type> = {
  type: Key
} & Type;

export type BaseRelationError = BaseBaseRelationError &
(
  BaseBaseRelationErrorTypeMapping<
  'ACCOUNT_STATUS_ERROR' |
  'BIRTH_DATE_ERROR' |
  'BIRTH_SEX_ERROR' |
  'GREATER_EIGHTEEN_ERROR' |
  'LESS_EIGHTEEN_ERROR' |
  'PARENT_AGE_ERROR' |
  'RELATION_CONTRADICT_ERROR',
  BaseBaseRelationError>
  | BaseBaseRelationErrorTypeMapping<'RELATION_DUPLICATE_ERROR', RelationDuplicateError>
  | BaseBaseRelationErrorTypeMapping<'RELATION_REQUEST_DUPLICATE_ERROR', RelationRequestDuplicateError>
);

export interface RelationContactBatchCreateRequest {
  address?: AddressChangeRequest
  birthDate: string
  birthSex: BirthSex | null
  email?: string
  ethnicity?: Ethnicity
  firstName: string
  gender: GenderType | null
  insurance?: RelationContactInsuranceCreateRequest
  insuranceId?: string
  lastName: string
  middleName?: string
  phone?: string
  race?: RaceType | null
  recaptcha?: string
  relationType: RequestRelationType | null
  sexualOrientation?: SexualOrientation
}

export interface AddressChangeRequest {
  address1: string
  address2?: string
  city: string
  location?: AddressLocation
  postalCode: string
  state: StateType | string
}

export interface AddressLocation {
  googlePlaceId: string
  latitude: number
  longitude: number
}

export interface RelationContactInsuranceCreateRequest {
  endDate: NullableLocalDate
  guarantor: Nullable<GuarantorCreateRequest>
  insurancePlanId: string
  number: string
  primary: boolean
}

export interface GuarantorCreateRequest {
  address1: string
  address2?: NillableString
  birthDate: string
  birthSex: BirthSex
  city: string
  firstName: string
  lastName: string
  middleName?: NillableString
  phone?: NillablePhoneWrapper
  postalCode: string
  relationType?: RelationshipType
  ssnTail?: NillableString
  state: StateType
}

export interface NillablePhoneWrapper {
  value?: string
}
export interface NillableString {
  value?: string
}

export interface NullableLocalDate {
  value?: string
}

interface BaseBaseSentRelationRequestResultDto {
  firstName?: string
  lastName?: string
  middleName?: string
  type: RelationRequestSendType
}

type BaseBaseSentRelationRequestResultDtoTypeMapping<Key, Type> = {
  type: Key
} & Type;

export type BaseSentRelationRequestResultDTO = BaseBaseSentRelationRequestResultDto &
(
  | BaseBaseSentRelationRequestResultDtoTypeMapping<'EMAIL', EmailSentRelationRequestResult>
  | BaseBaseSentRelationRequestResultDtoTypeMapping<'PHONE', PhoneSentRelationRequestResult>
  | BaseBaseSentRelationRequestResultDtoTypeMapping<'UNSENT', UnsentRelationRequestResult>
);

export type EmailSentRelationRequestResult = BaseBaseSentRelationRequestResultDto & {
  email?: string
};

export type PhoneSentRelationRequestResult = BaseBaseSentRelationRequestResultDto & {
  phone?: string
};

export type UnsentRelationRequestResult = BaseBaseSentRelationRequestResultDto;

export interface RelationContactSimilarError {
  results: RelationContactSearchResult[]
  type?: RelationContactSimilarErrorType
}

export interface SendResultsByEmailRequestI {
  relationId: UUID
  resultId: UUID
  data: { email: string }
}

export interface RelationHealthcareInsurancePolicyChangeRequest {
  deletedHealthcareInsurancePolicyIds?: string[]
  healthcareInsurancePoliciesCreate?: Nullable<RelationHealthcareInsurancePolicyCreateRequest[]>
  healthcareInsurancePoliciesEdit?: Nullable<RelationHealthcareInsurancePolicyEditRequest[]>
}

export interface RelationHealthcareInsurancePolicyCreateRequest {
  endDate?: NullableValue<string> | null
  guarantor?: RelationGuarantorCreateRequest
  insurancePlanId: string
  number: string
  primary: boolean
}

export interface RelationHealthcareInsurancePolicyEditRequest {
  endDate: NillableString | null
  guarantor?: NullableValue<RelationGuarantorEditRequest>
  id: string
}

export interface NullableValue<T> {
  value: T | null
}

export interface RelationGuarantorCreateRequest {
  address1: string
  address2?: NullableValue<string>
  birthDate: string
  birthSex: BirthSex | null
  city: string
  firstName: string
  lastName: string
  middleName?: NullableValue<string>
  phone?: NullableValue<string>
  postalCode: string
  relationType: RelationshipTypes | null
  ssnTail?: NullableValue<string>
  state: StateType | null
}

export interface RelationGuarantorEditRequest {
  address1?: NullableValue<string>
  address2?: NullableValue<string>
  birthDate?: NullableValue<string>
  birthSex?: NullableValue<BirthSex>
  city?: NullableValue<string>
  firstName?: NullableValue<string>
  lastName?: NullableValue<string>
  middleName?: NullableValue<string>
  phone?: NullableValue<string>
  postalCode?: NullableValue<string>
  relationType?: NillableRelationType
  ssnTail?: NullableValue<string>
  state?: NillableStateType
}

export enum StateType {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  ZZ = 'ZZ',
}

export enum RelationContactSimilarErrorType {
  CONTACT_SIMILAR_STRATEGY = 'CONTACT_SIMILAR_STRATEGY',
  UNIQUE_UNCONFIRMED_PHONE = 'UNIQUE_UNCONFIRMED_PHONE',
  UNIQUE_CONFIRMED_PHONE = 'UNIQUE_CONFIRMED_PHONE',
}

export enum RaceType {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  OTHER = 'OTHER',
  ASKU = 'ASKU',
  UNKNOWN = 'UNKNOWN',
}

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  FEMALE_TO_MALE = 'FEMALE_TO_MALE',
  MALE_TO_FEMALE = 'MALE_TO_FEMALE',
  GENDERQUEER = 'GENDERQUEER',
  OTH = 'OTH',
  ASKU = 'ASKU',
}

export enum RelationRequestSendType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  UNSENT = 'UNSENT',
}

export enum AccountStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INVITE = 'INVITE',
  BLOCKED = 'BLOCKED',
}

export enum RequestRelationType {
  CHD = 'CHD',
  DEP = 'DEP',
  FCH = 'FCH',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
}

export enum RelationErrorType {
  PARENT_AGE_ERROR = 'PARENT_AGE_ERROR',
  LESS_EIGHTEEN_ERROR = 'LESS_EIGHTEEN_ERROR',
  GREATER_EIGHTEEN_ERROR = 'GREATER_EIGHTEEN_ERROR',
  BIRTH_DATE_ERROR = 'BIRTH_DATE_ERROR',
  BIRTH_SEX_ERROR = 'BIRTH_SEX_ERROR',
  ACCOUNT_STATUS_ERROR = 'ACCOUNT_STATUS_ERROR',
  RELATION_CONTRADICT_ERROR = 'RELATION_CONTRADICT_ERROR',
  RELATION_DUPLICATE_ERROR = 'RELATION_DUPLICATE_ERROR',
  RELATION_REQUEST_DUPLICATE_ERROR = 'RELATION_REQUEST_DUPLICATE_ERROR',
}

export enum RelationEmailError {
  CONTACT_EMAIL_UNIQUE = 'CONTACT_EMAIL_UNIQUE'
}

export type FlagType = 'NORMAL' | 'ABNORMAL' | 'CRITICAL';

export enum RelationshipType {
  Self = 'SELF',
  Chd = 'CHD',
  Emc = 'EMC',
  Fth = 'FTH',
  Gch = 'GCH',
  Grp = 'GRP',
  Mth = 'MTH',
  Oth = 'OTH',
  Spo = 'SPO',
  Husb = 'HUSB',
  Wife = 'WIFE',
  Wrd = 'WRD',
}

export enum AccountAccessTypeEnum {
  ContactProxy = 'CONTACT_PROXY',
  ContactRepresent = 'CONTACT_REPRESENT',
  CreatorAccount = 'CREATOR_ACCOUNT',
  CreatorContact = 'CREATOR_CONTACT',
}

export interface PrevAddRelationData {
  relationType: RequestRelationType | null
  policyId: string | null
  searchBy: string | null
  isSearched: boolean
}
