import {styled, Switch as MuiSwitch} from '@mui/material';

export const SwitchControl = () => {
  return (
    <></>
  );
};

export const Switch = styled(MuiSwitch)`
padding: 14px 12px;
width: 62px;
.MuiSwitch {
    &-thumb {
        width: 22px;
        height: 22px;
    }
    &-switchBase {
        padding: 8px;
    }
}
.Mui-checked {
    transform: translateX(22px);
}
`;

Switch.defaultProps = {
  color: 'secondary',
};
