import {MenuTypes, StorageFileCreateBody} from '@api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PageHeader as PageTitle,
} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {EditDocumentDialog} from '@src/components/EditDocumentDialog';
import {PulseLoader} from '@src/components/PulseLoader';
import {ShareDocumentsDialog} from '@src/components/ShareDocumentsDialog';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as Error} from '@src/shared/assets/icons/error.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {addStorageFile, fetchStorageFiles, storageActions} from '@src/store/storage/slice';
import {Steps} from '@src/types';
import {useAppDispatch, useAppSelector} from '@store';
import {useEffect, useState} from 'react';
import {Button, IconButton} from 'ui-kit';

import {DocumentCard} from './components/DocumentCard';
import {FavoritesDialog} from './components/FavoritesDialog';
import {Filters} from './components/Filters';
import {DEFAULT_PER_PAGE} from './constants';
import {useStorageTranslate} from './hooks';
import {MainContainer, MainWrapper, sx} from './styles';

export const Storage = () => {
  const {mobile} = useMQuery();
  const translate = useStorageTranslate();
  const {isSidebarOpen} = useSidebarClassObserver();
  const dispatch = useAppDispatch();
  const {files, isLoading, step} = useAppSelector((state) => state.storage);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  const handleToggleShareDialogOpen = () => {
    setIsShareDialogOpen(!isShareDialogOpen);
  };
  const [isAddDocumentDialogOpen, setIsAddDocumentDialogOpen] = useState(false);
  const [isAddDocumentErrorDialogOpen, setIsAddDocumentErrorDialogOpen] = useState(false);

  const handleToggleAddDocumentDialogOpen = () => {
    setIsAddDocumentDialogOpen(!isAddDocumentDialogOpen);
  };

  const handleToggleAddDocumentErrorDialogOpen = () => {
    setIsAddDocumentErrorDialogOpen(!isAddDocumentErrorDialogOpen);
  };

  const handleScrollListener = (container: HTMLElement) => {
    const scrollTop = container.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  const isShowErrorModal = step === Steps.ERROR;

  const handleFetch = (isNext: boolean) => {
    if (isNext && !files.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = files.filters;
    void dispatch(
      fetchStorageFiles({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? files.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }),
    );
  };

  const {phrase, category, from, to, menu} = files.filters;

  const isRecentOrFavorite = menu === MenuTypes.RECENT || menu === MenuTypes.FAVORITE;

  const isFieldsEmpty = !phrase && !category && !from && !to && !isRecentOrFavorite;

  const displayedMessage = isFieldsEmpty
    ? translate('YOU_DONT_HAVE_DOCS')
    : translate('NO_SUITABLE_RESULTS');

  useEffect(() => {
    handleFetch(false);
  }, [files.filters]);

  const handleAddNewFile = async (document: StorageFileCreateBody, file?: File) => {
    if (!file) {
      return;
    }
    void dispatch(
      addStorageFile({
        ...document,
        file,
      }),
    )
      .unwrap()
      .then(() => {
        setTimeout(() => {
          dispatch(storageActions.clearIsNew());
        }, 2000);
      })
      .catch(() => {
        handleToggleAddDocumentErrorDialogOpen();
      });
  };

  return (
    <>
      <PageTitle
        height={'auto'}
        itemAction={
          mobile
            ? (
              <IconButton
                variant={'text'}
                color={'secondary'}
                sx={sx.iconButton}
                onClick={handleToggleShareDialogOpen}
                disabled={!files.items.length}
              >
                <ShareIcon />
              </IconButton>
            )
            : (
              <Stack gap={12} flexDirection={'row'}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleToggleAddDocumentDialogOpen}
                >
                  {translate('ADD_DOCUMENT')}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ShareIcon />}
                  onClick={handleToggleShareDialogOpen}
                  disabled={!files.items.length}
                >
                  {translate('SHARE_DOCUMENTS')}
                </Button>
              </Stack>
            )
        }
      >
        {translate('STORAGE')}
      </PageTitle>
      <Stack sx={sx.generalContainer}>
        <Filters isSidebarOpen={isSidebarOpen} isScrolled={isScrolled} />
        <MainWrapper
          isScrolled={isScrolled}
          isSidebarOpen={isSidebarOpen}
          onScroll={handleScrollListener}
          onLoad={() => handleFetch(true)}
          threshold={500}
        >
          {(!isLoading || !!files.items.length) && (
            <MainContainer isSidebarOpen={isSidebarOpen}>
              {files.items.map((file) => (
                <DocumentCard key={file.id} file={file}/>
              ))}
            </MainContainer>
          )}
          {!isLoading && !files.items.length && (
            <Typography variant={'14_18_500'} color={(theme) => theme.colors.alertColor}>
              {displayedMessage}
            </Typography>
          )}
          {isLoading && (
            <Box display={'flex'} justifyContent={'center'} p={36}>
              <PulseLoader loading={true} />
            </Box>
          )}
        </MainWrapper>
      </Stack>
      {mobile && (
        <Box sx={sx.fixedButtonContainer}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleToggleAddDocumentDialogOpen}
          >
            {translate('ADD_DOCUMENT')}
          </Button>
        </Box>
      )}
      <ShareDocumentsDialog isOpen={isShareDialogOpen} onClose={handleToggleShareDialogOpen}/>
      <EditDocumentDialog
        isOpen={isAddDocumentDialogOpen}
        onClose={handleToggleAddDocumentDialogOpen}
        onSave={handleAddNewFile}
      />
      <Dialog open={isAddDocumentErrorDialogOpen} sx={sx.dialog} size={'sm'}>
        <DialogTitle sx={sx.dialogTitle} onClose={handleToggleAddDocumentErrorDialogOpen}>
          {<Error />}
          {translate('ERROR')}
        </DialogTitle>
        <DialogContent sx={sx.dialogContent}>
          <Typography variant={'16_20_500'}>{translate('DOCUMENT_NOT_SAVED')}</Typography>
        </DialogContent>
        <DialogActions sx={sx.dialogActions}>
          <Button color={'white'} onClick={handleToggleAddDocumentErrorDialogOpen}>
            {translate('OK')}
          </Button>
        </DialogActions>
      </Dialog>
      <FavoritesDialog open={isShowErrorModal}/>
    </>
  );
};
