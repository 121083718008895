import {Theme} from '@mui/material';

export const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    [t.breakpoints.down('lg')]: {
      gap: 24,
    },
    [t.breakpoints.down('sm')]: {
      paddingBottom: '120px',
    },
    flexGrow: 1,
  }),
  buttonsContainer: (t: Theme) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    boxShadow: '0px -2px 12px 0px #0000001A',
    background: t.colors.background,
    gap: 12,
    display: 'grid',
  }),
  boxPaddings: {
    padding: {
      xs: '18px 16px',
      md: '24px',
      lg: '24px 36px',
    },
  },
  wBoxContainer: {
    flexGrow: 1,
    pb: {lg: 24},
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxOrderDetails: {
    height: {lg: 226, xxl: 186},
    padding: {
      xs: '6px 12px 6px 16px',
      md: 24,
      lg: '18px 24px',
      xxl: 36,
    },
  },
  wBoxPatientDetails: {
    height: {lg: 226, xxl: 186},
    flexGrow: 1,
    padding: {
      xs: '6px 12px 6px 16px',
      md: 24,
      lg: '18px 24px',
      xxl: 36,
    },
  },
  headerSx: {
    mb: {xs: 24, lg: 36},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
  }),
  rowInformation: {
    mb: {lg: 12},
  },
  boxHeader: {
    height: {lg: 42, xxl: 0},
    mb: 24,
  },
};
