import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: {xs: 24, sm: 24, md: 36},
    flexGrow: 1,
  },
  addInsuranceButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};
