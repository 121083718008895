import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  boxContainer: {
    padding: '24',
    flexWrap: 'unset',
  },
  patientNotFound: {
    color: (t) => t.palette.grey[500],
    paddingTop: 14,
  },
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonBaseSx: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: 60,
    alignItems: 'center',
    padding: '0 24px',
  },
};
