import {SxProps, Theme} from '@mui/material';

export const sx: Record<string, SxProps<Theme>> = ({
  buttonGroupsMobile: (t) => ({
    p: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderColor: t.palette.secondary._14,
  }),
  emptyAccessesText: (t) => ({
    typography: {xs: '12_16_500'},
    color: t.palette.grey[500],
    width: 'fit-content',
    p: '18px',
  }),
  boxContainer: (t) => ({
    background: t.palette.common.white,
    height: 'calc(100vh - 144px)',
    border: '1px solid',
    borderColor: t.palette.secondary._14,
  }),
  text: (t) => ({
    typography: '14_18_600',
    color: t.palette.grey[400],
  }),
  link: {
    typography: '14_18_600',
  },
});
