import {Stack, SvgIcon, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {Product} from '@src/api';
import {Checkbox} from '@src/components/form/CheckboxControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ArrowIcon} from '@src/shared/assets/icons/arrow-down.svg';
import {Table as NewTable} from '@src/shared/ui/Table/Table';
import {getCriticalNameReference} from '@src/shared/utils/getCriticalNameReference';
import {getNameReference} from '@src/shared/utils/getNameReference';
import {getReference} from '@src/shared/utils/getReference';
import {paletteColors} from '@src/theme';
import {ColumnDef, Table as TanstackTable} from '@tanstack/react-table';
import {ChangeEvent, FC, useMemo} from 'react';

interface ResultDetailsTableProps {
  data: Product[]
  handleToggleAllRowsSelection: (table: TanstackTable<Product>, checked: boolean) => void
  handleToggleRowSelection: (row: Product, checked: boolean) => void
}

export const ResultDetailsTable: FC<ResultDetailsTableProps> = ({data, handleToggleAllRowsSelection, handleToggleRowSelection}) => {
  const {t} = useTranslate('details');

  const columns = useMemo<Array<ColumnDef<any>>>(
    () => [
      {
        accessorKey: 'name',
        minSize: 300,
        header: ({table}) => {
          return (
            <>
              <Checkbox
                sx={{mr: 0}}
                {...{
                  checked: table.getIsAllRowsSelected(),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    handleToggleAllRowsSelection(table, e.target.checked),
                }}
              />{' '}
              {t('TEST_PANEL')}
            </>
          );
        },
        cell: ({row, getValue}) => {
          return (
            <Stack
              style={{
                paddingLeft: `${row.depth * 2}rem`,
                justifyContent: 'center',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {row.getCanExpand()
                  ? (
                    <SvgIcon
                      sx={{
                        transform: row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(-90deg)',
                        fontSize: '20px',
                        marginRight: '11px',
                        color: '#C7CDD6',
                      }}
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: {cursor: 'pointer'},
                      }}
                    >
                      {row.getIsExpanded() ? <ArrowIcon /> : <ArrowIcon />}
                    </SvgIcon>
                  )
                  : (
                    ''
                  )}{' '}
                <Checkbox
                  sx={{mr: 0}}
                  {...{
                    checked: row.getIsSelected(),
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      row.getToggleSelectedHandler()(e);
                      handleToggleRowSelection(row.original, e.target.checked);
                    },
                  }}
                />{' '}
                {getValue<boolean>()}
              </Stack>
            </Stack>
          );
        },
        enableSorting: false,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'value',
        id: 'Results',
        cell: (info) => {
          const {row} = info;
          const isCritical = row.original.criticalReference?.isOutOfRef;
          const isReference = row.original.reference?.isOutOfRef;
          const referenceColor = isReference ? '#FAB41C' : undefined;
          const criticalColor = isCritical ? '#EA3D5C' : undefined;
          const colorValue = criticalColor || referenceColor;
          const value = row.original.value;
          const qualityReference = row.original.qualityReference;

          const valueNode = (
            <>
              <Box
                style={{
                  color: colorValue,
                  fontWeight: isCritical || isReference ? 700 : 'initial',
                  paddingTop: '5px',
                }}
              >
                {value || '--'}
              </Box>
              {qualityReference?.value
                ? (
                  <Box color={paletteColors.grey[700]}>
                    {t('QUALITY_REFERENCE')}: {qualityReference.value}
                  </Box>
                )
                : null}
            </>
          );

          return (
            <Typography variant="14_18_500">{value && valueNode}</Typography>
          );
        },
        header: () => <Box>{t('RESULTS')}</Box>,
        footer: (props) => props.column.id,
        minSize: 550,
        enableSorting: false,
      },
      {
        accessorKey: 'units',
        id: 'units',
        minSize: 150,
        cell: (info) => {
          const {row} = info;
          return <Typography variant="14_18_500" pt={5}>{row.original.units}</Typography>;
        },
        header: () => <Box>{t('UNITS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'comment',
        id: 'comment',
        minSize: 250,
        cell: (info) => {
          const {row} = info;
          return <Typography variant="14_18_500" pt={5}>{row.original.comment || t('NO_COMMENTS')}</Typography>;
        },
        header: () => <Box>{t('COMMENTS')}</Box>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: 'reference',
        id: 'reference',
        minSize: 200,
        enableSorting: false,
        header: () => <Box>{t('REFERENCE_INTERVAL')}</Box>,
        cell: (info) => {
          const {row} = info;
          const criticalReference = row.original.criticalReference;
          const reference = row.original.reference;
          const units = row.original.units;

          return (
            <Box
              sx={{
                flexDirection: !info.getValue() && !criticalReference ? 'row' : 'column',
                mb: '0px !important',
                pt: 5,
              }}
            >
              <Box>
                <Typography variant="14_18_500">
                  {(() => {
                    const reference = getReference(row.original);
                    return reference ? `${t('OPTIMAL')}: ${reference}` : '';
                  })()}</Typography>
                {reference
                  ? (
                    <Box>
                      <Typography variant="14_18_500" color={paletteColors.grey[700]}>
                        {t('ABNORMAL')}: {getNameReference(reference, units)}
                      </Typography>
                    </Box>
                  )
                  : null}
                {criticalReference
                  ? (
                    <Box>
                      <Typography variant="14_18_500" color={paletteColors.grey[700]}>
                        {t('CRITICAL')}:{' '}
                        {getCriticalNameReference(criticalReference, units)}
                      </Typography>
                    </Box>
                  )
                  : null}
              </Box>
            </Box>
          );
        },
      },
    ],
    [],
  );

  const isError = (row: any) => {
    return row.criticalReference?.isOutOfRef;
  };

  return (
    <NewTable
      columns={columns}
      data={data}
      heightColumns='auto'
      isError={isError}
    />
  );
};
