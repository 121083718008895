import {SxProps, Theme} from '@mui/material';
import {EBackgroundFlagColor} from '@src/pages/Results/Results.constant';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainBlock: (t: Theme) => ({
    margin: {
      xs: '18px',
      sm: '24px auto',
    },
    marginTop: {
      xs: '-54px',
      sm: '-10px',
    },
    [t.breakpoints.only('xs')]: {
      padding: '24px',
    },
    [t.breakpoints.between('sm', 'md')]: {
      padding: '36px',
    },
    [t.breakpoints.up('md')]: {
      padding: '40px 54px',
      width: 600,
      margin: '10px auto',
    },
    [t.breakpoints.up('lg')]: {
      padding: '40px 54px',
      width: 600,
      margin: '70px auto',
    },
    [t.breakpoints.down('md')]: {
      width: 552,
    },
    maxHeight: {
      xs: 'calc(100vh - 100px)',
      sm: 880,
      md: 900,
    },
    minHeight: 624,
  }),
  title: {
    typography: {
      xs: '22_26_700',
      sm: '32_36_700',
    },
    marginBottom: {
      xs: 48,
      sm: 66,
    },
  },
  text: {
    typography: {
      xs: '12_16_500',
      sm: '14_22_500',
    },
    marginBottom: {
      xs: 52,
      sm: 66,
    },
  },
  subtitle: {
    typography: {
      xs: '18_24_500',
      sm: '22_26_500',
    },
    marginBottom: 12,
  },
  wrapper: {
    marginTop: 0,
  },
  input: {
    marginBottom: {
      xs: 42,
      sm: 48,
    },
  },
  recaptcha: {
    fontWeight: 500,
    lineHeight: '14px',
    fontSize: '10px',
    color: 'grey.600',
    a: {
      color: 'secondary.main',
      textDecoration: 'none',
      '&:hover': {
        color: 'secondary.light',
      },
      '&:active': {
        color: 'secondary.dark',
      },
    },
  },
  recaptchaWrapper: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  errorTimeWrapper: {
    alignItems: 'center',
    marginTop: '16px',
  },
  errorBox: {
    flexDirection: 'row',
    backgroundColor: EBackgroundFlagColor.CRITICAL,
    padding: {
      xs: 9,
      sm: 12,
    },
    svg: {
      width: {
        xs: 18,
        sm: 24,
      },
      height: {
        xs: 18,
        sm: 24,
      },
    },
  },
  errorWrapper: {
    marginTop: {
      xs: 32,
      sm: 48,
    },
    height: {
      xs: 50,
      sm: 52,
    },
  },
  errMessage: {
    alignSelf: 'center',
    marginLeft: {
      xs: 6,
      sm: 12,
    },
    typography: {
      xs: '12_16_700',
      sm: '14_18_700',
    },
  },
};
